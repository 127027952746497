import React from "react";

import * as SystemIcons from "../../assets/icons/systems";

// eslint-disable-next-line react/prop-types
function EventIcon({ iconName, className, defaultIcon }) {
  const formattedIconName = iconName?.toString().split(" ").join("");

  const renderEventIcon = (item) => {
    const Icons = {
      Hub: SystemIcons.HubIcon,
      FloodDetector: SystemIcons.FloodDetectorIcon,
      RemoteWaterMeterReader: SystemIcons.RemoteWaterMeterReaderIcon,
      // 3: SystemIcons.Icon3,
      // 4: SystemIcons.Icon4,
      HeimanCarbonMonoxideSensor: SystemIcons.HeimanCarbonMonoxideSensorIcon,
      // 6: SystemIcons.Icon6,
      HeimanMotionSensor: SystemIcons.HeimanMotionSensorIcon,
      HeimanMagneticSensor: SystemIcons.HeimanMagneticSensorIcon,
      CLMLink: SystemIcons.CLMLinkIcon,
      // SprucePS-SPRZMS-SLP3: SystemIcons.SprucePS-SPRZMS-SLP3Icon,
      // 11: SystemIcons.Icon11,
      HeimanCombustibleGasSensor: SystemIcons.HeimanCombustibleGasSensorIcon,
      // 13: SystemIcons.Icon13,
      // 14: SystemIcons.Icon14,
      HeimanSmartMeteringPlug: SystemIcons.HeimanSmartMeteringPlugIcon,
      WaterShutoff: SystemIcons.WaterShutoffIcon,
      FlowSensor: SystemIcons.FlowSensorIcon,
      Repeater: SystemIcons.RepeaterIcon,
      IrrigationController: SystemIcons.IrrigationControllerIcon,
    };

    // TODO: default icon?
    return Icons[item]
      ? React.createElement(Icons[item], { className }, item)
      : defaultIcon;
  };

  const eventIcon = renderEventIcon(formattedIconName);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{eventIcon}</>;
}

export default EventIcon;
