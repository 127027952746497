function FloorManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="256"
      viewBox="0 0 208 256"
    >
      <path
        id="FLOOR"
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M169.908,284.413h76.416V255.7H169.908v28.718Zm9.111-19.609h58.194v10.5H179.019V264.8Zm-9.111-15.754h76.416V220.332H169.908V249.05Zm9.111-19.609h58.194v10.5H179.019v-10.5Zm-9.111-44.472v28.718h76.416V184.969H169.908Zm67.305,19.609H179.019v-10.5h58.194v10.5Z"
      />
    </svg>
  );
}
export default FloorManagementIcon;
