const predefinedStructure = {
  Dashboard: [
    "Dashboard Default View",
    "View all Events (Redirect)",
    "Virtual View",
  ],
  "Property Overview": ["Property Overview Default View", "Send Commands"],
  Statistics: ["Graph View", "Table View"],
  Reports: ["Generate Report"],
  Events: ["Events Default View", "Export Events"],
  Settings: [
    "Flow Configuration Default View",
    "Schedule Default View",
    "Create New Schedule",
    "Edit Schedule",
    "Create New FC",
    "Edit FC",
  ],
};

const userStaticData = {
  UserLevelName: "User",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 31,
      FeatureName: "Virtual View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
  ],
};

const operatorStaticData = {
  UserLevelId: 4,
  UserLevelName: "Operator",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 4,
      FeatureName: "Graph View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 5,
      FeatureName: "Generate Report",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
    {
      FeatureId: 7,
      FeatureName: "Flow Configuration Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 8,
      FeatureName: "Schedule Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 19,
      FeatureName: "Table View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 24,
      FeatureName: "Create New Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 25,
      FeatureName: "Edit Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 26,
      FeatureName: "Create New FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 27,
      FeatureName: "Edit FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 29,
      FeatureName: "Send Commands",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 31,
      FeatureName: "Virtual View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
  ],
};

export { predefinedStructure, userStaticData, operatorStaticData };
