// eslint-disable-next-line import/prefer-default-export
export const sortObjArrayByKey = (array = [], key = "") => {
  return array.sort((obj1, obj2) => {
    const value1 = obj1[key]?.toUpperCase(); // ignore upper and lowercase
    const value2 = obj2[key]?.toUpperCase(); // ignore upper and lowercase

    if (value1 < value2) {
      return -1; // value1 comes first
    }

    if (value1 > value2) {
      return 1; // value2 comes first
    }

    return 0; // values must be equal
  });
};
