function EventsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 503.6 478.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <rect
          x="177.5"
          y="332.9"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <rect
          x="177.5"
          y="248.8"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <rect
          x="177.5"
          y="164.8"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <rect
          x="286"
          y="332.9"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <rect
          x="286"
          y="248.8"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <rect
          x="286"
          y="164.8"
          fill="#ffffff"
          width="41.6"
          height="41.6"
          className="blue-fill-color"
        />
        <path
          fill="#ffffff"
          className="blue-fill-color"
          d="M334,67.4v-4.1c0-13.4-10.9-24.3-24.3-24.3H195.3C181.9,39.1,171,50,171,63.3v4.1h-62.8v380.2h288.5V67.4H334    z M197.5,65.5h110.1v29.1H197.5V65.5z M370.3,421.2H134.7V93.8H171v2.9c0,13.4,10.9,24.3,24.3,24.3h114.4    c13.4,0,24.3-10.9,24.3-24.3v-2.9h36.3V421.2z"
        />
      </g>
    </svg>
  );
}

export default EventsIcon;
