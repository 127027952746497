import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";

import logoTriple from "../../assets/img/wasens-logo-tm.svg";
import useAuth from "../../hooks/useAuth";
import useWindowSize from "../../hooks/useWindowSize";
import DateAndTime from "./DateAndTime/DateAndTime";
import UserDetails from "./UserDetails/UserDetails";

import "./Header.scss";

const propTypes = {
  withNavigation: PropTypes.bool,
  isMobileNavActive: PropTypes.bool,
  setIsMobileNavActive: PropTypes.func,
};

const defaultProps = {
  withNavigation: false,
  isMobileNavActive: false,
  setIsMobileNavActive: () => {},
};

const HEADER_TITLES = [
  { path: "/", title: "Dashboard" },
  { path: "/alarm", title: "Dashboard" },
  { path: "/event", title: "Dashboard" },
  { path: "/analytics", title: "Analytics" },
  { path: "/events", title: "Events Log" },
  { path: "/settings/flow-configuration", title: "Flow Configuration" },
  { path: "/property-overview", title: "Property Overview" },
  { path: "/profile", title: "Profile" },
  { path: "/settings/schedule", title: "Schedule" },
  { path: "/system-preferences", title: "System Preferences" },
];

function Header({ isMobileNavActive, setIsMobileNavActive, withNavigation }) {
  const window = useWindowSize();
  const location = useLocation();
  const params = useParams();
  const { Header } = Layout;
  const { auth } = useAuth();

  let pageTitle = "";

  if (!Object.keys(params).length) {
    pageTitle = HEADER_TITLES.find(
      (el) => el.path === location.pathname,
    )?.title;
  } else {
    const basePath = location.pathname.slice(
      location.pathname.indexOf("/"),
      location.pathname.lastIndexOf("/"),
    );
    if (basePath) {
      pageTitle = HEADER_TITLES.find((el) => el.path === basePath)?.title;
    }
  }

  const navIcon = (
    <>
      {isMobileNavActive && (
        <CloseOutlined
          className="header-icon "
          onClick={() => {
            setIsMobileNavActive(false);
          }}
        />
      )}
      {!isMobileNavActive && (
        <MenuOutlined
          className="header-icon"
          onClick={() => {
            setIsMobileNavActive(true);
          }}
        />
      )}
    </>
  );

  return (
    <Header>
      <div className="header">
        {window?.width <= 992 && withNavigation && (
          <div className="header-title">
            {navIcon}
            <h1 className="page-title-mobile">{pageTitle}</h1>
          </div>
        )}
        <div className="h-14 my-2">
          <img
            src={logoTriple}
            alt="Triple+ logo"
            className={`${
              withNavigation && "ml-auto"
            } md:ml-0 w-24 sm:w-36 h-full object-contain`}
          />
        </div>

        {window?.width > 992 && (
          <div className="flex items-center pr-9">
            <DateAndTime />
            {auth.isAuthenticated && <UserDetails />}
          </div>
        )}
      </div>
    </Header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
