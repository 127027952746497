import { Button, Radio, TimePicker } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import AlarmIcon from "../../../assets/icons/AlarmIcon";
import HighFlowRateIcon from "../../../assets/icons/HighFlowRateIcon";
import LowFlowRateIcon from "../../../assets/icons/LowFlowRateIcon";
import MediumFlowRateIcon from "../../../assets/icons/MediumFlowRateIcon";
import ShutOffIcon from "../../../assets/icons/ShutOffIcon";

function EditFdsScheduleContent({
  flowRateStates,
  flowRateActions,
  handleOnChange,
  handleClose,
  handleSave,
  handleOnChangeTimePicker,
  handleOnChangeFlowRateAction,
  isLoading,
}) {
  const duration = flowRateStates?.find(
    (state) => state.selected,
  ).durationInMinutes;
  const hours = Math.floor(duration / 60);
  const mins = duration % 60;
  const durationDate = new Date();
  durationDate.setHours(hours);
  durationDate.setMinutes(mins);
  const timePickerValue = dayjs(durationDate);

  const flowRateIconDivClassName = "flex flex-col items-center";
  const flowRateSpanClassName = "xxs:hidden xs:hidden md:inline-block";
  const renderFlowRateIcon = (state) => {
    switch (state.id) {
      case "low":
        return (
          <div className={flowRateIconDivClassName}>
            <LowFlowRateIcon className={state.selected ? "active" : ""} />
            <span className={flowRateSpanClassName}>Low</span>
          </div>
        );
      case "medium":
        return (
          <div className={flowRateIconDivClassName}>
            <MediumFlowRateIcon className={state.selected ? "active" : ""} />
            <span className={flowRateSpanClassName}>Medium</span>
          </div>
        );
      case "high":
        return (
          <div className={flowRateIconDivClassName}>
            <HighFlowRateIcon className={state.selected ? "active" : ""} />
            <span className={flowRateSpanClassName}>High</span>
          </div>
        );
      default:
        return state.id;
    }
  };

  const flowRateActionDivClassName = "flex flex-col items-center";
  const flowRateActionSpanClassName = "xxs:hidden xs:hidden md:inline-block";
  const renderFlowRateAction = (flowRateAction) => {
    switch (flowRateAction.Name) {
      case "Alarm Only":
        return (
          <div className={flowRateActionDivClassName}>
            <span className={flowRateActionSpanClassName}>Alarm Only</span>
            <AlarmIcon
              className={
                flowRateStates?.find((state) => state.selected).close === 0
                  ? "active"
                  : ""
              }
            />
          </div>
        );
      case "Shutoff water":
        return (
          <div className={flowRateActionDivClassName}>
            <span className={flowRateActionSpanClassName}>Shut off</span>
            <ShutOffIcon
              className={
                flowRateStates?.find((state) => state.selected).close === 1
                  ? "active"
                  : ""
              }
            />
          </div>
        );
      default:
        return flowRateAction.Name;
    }
  };

  return (
    <div className="fds">
      <div className="inline-flex items-start">
        <TimePicker
          showSecond={false}
          showNow={false}
          format="HH:mm"
          inputReadOnly
          className="mx-1 mt-2"
          size="large"
          value={timePickerValue}
          onChange={handleOnChangeTimePicker}
        />

        <Radio.Group
          className="mx-1 mt-2"
          size="small"
          value={flowRateStates?.find((state) => state.selected).id}
          onChange={handleOnChange}
        >
          {flowRateStates?.map((state) => (
            <Radio.Button
              key={state.id}
              value={state.id}
              className="xxs:w-full xs:w-full md:w-1/3"
            >
              {renderFlowRateIcon(state)}
            </Radio.Button>
          ))}
        </Radio.Group>
        <div className="">
          <Radio.Group
            size="small"
            onChange={handleOnChangeFlowRateAction}
            value={flowRateStates?.find((state) => state.selected).close}
          >
            {flowRateActions?.data
              ?.sort((firstElement, secondElement) =>
                firstElement.Name.localeCompare(secondElement.Name),
              )
              .map((flowRateAction) => {
                return (
                  <Radio.Button
                    key={`${flowRateAction.Name}_${flowRateAction.Value}`}
                    value={flowRateAction.Value}
                  >
                    {renderFlowRateAction(flowRateAction)}
                  </Radio.Button>
                );
              })}
          </Radio.Group>
        </div>
      </div>

      <div className="my-1 mt-8 flex justify-center items-center">
        <Button
          type="primary"
          className="mx-1"
          onClick={handleClose}
          size="small"
        >
          Close
        </Button>
        <Button
          type="primary"
          className="mx-1"
          size="small"
          loading={isLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

EditFdsScheduleContent.propTypes = {
  flowRateStates: PropTypes.array,
  flowRateActions: PropTypes.object,
  handleOnChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleOnChangeTimePicker: PropTypes.func,
  handleOnChangeFlowRateAction: PropTypes.func,
  isLoading: PropTypes.bool,
};

EditFdsScheduleContent.defaultProps = {
  flowRateStates: [],
  flowRateActions: {},
  handleOnChange: () => {},
  handleClose: () => {},
  handleSave: () => {},
  handleOnChangeTimePicker: () => {},
  handleOnChangeFlowRateAction: () => {},
  isLoading: false,
};
export default EditFdsScheduleContent;
