function generateSkeletonStatisticsAnnualChartData(year) {
  return {
    Year: year,
    StatisticsData: [
      {
        Month: "Jan",
        MonthAsInt: 1,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Feb",
        MonthAsInt: 2,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Mar",
        MonthAsInt: 3,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Apr",
        MonthAsInt: 4,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "May",
        MonthAsInt: 5,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Jun",
        MonthAsInt: 6,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Jul",
        MonthAsInt: 7,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Aug",
        MonthAsInt: 8,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Sep",
        MonthAsInt: 9,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Oct",
        MonthAsInt: 10,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Nov",
        MonthAsInt: 11,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
      {
        Month: "Dec",
        MonthAsInt: 12,
        WaterConsumption: 0,
        Offline: [],
        Check: [],
        Leak: [],
        HasOffline: false,
        HasCheck: false,
        HasLeak: false,
      },
    ],
    TotalWaterConsumption: 0,
  };
}

export default generateSkeletonStatisticsAnnualChartData;
