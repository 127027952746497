import { useState, useRef, useLayoutEffect } from "react";

import { Skeleton, Table } from "antd";
import PropTypes, { object } from "prop-types";

const propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(object),
  dataSource: PropTypes.arrayOf(object),
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  rowKey: PropTypes.func,
};

const defaultProps = {
  className: "",
  columns: [],
  dataSource: [],
  size: "middle",
  isLoading: false,
  rowKey: () => {},
};

function ScrollableTable({
  columns,
  dataSource,
  className,
  size,
  isLoading,
  rowKey,
}) {
  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef(null);

  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node.getBoundingClientRect();

    // normally TABLE_HEADER_HEIGHT would be 55.
    setTableHeight(window.innerHeight - top - 100);
  }, [ref]);

  // columns for skeleton table (loading state)
  const ghostTableColumns = columns.map((column) => {
    return {
      ...column,
      render: function renderPlaceholder() {
        return (
          <Skeleton
            key={column.key}
            active
            paragraph={false}
            className="px-4"
          />
        );
      },
    };
  });

  // data for skeleton table (loading state)
  const ghostTableData = [...Array(23)].map((_, index) => ({
    key: `key${index}`,
  }));

  return (
    <Table
      ref={ref}
      bordered
      className={className}
      columns={isLoading ? ghostTableColumns : columns}
      dataSource={isLoading ? ghostTableData : dataSource}
      pagination={false}
      size={size}
      scroll={{ y: tableHeight, x: "100%" }}
      tableLayout="fixed"
      rowKey={rowKey}
    />
  );
}

ScrollableTable.propTypes = propTypes;
ScrollableTable.defaultProps = defaultProps;

export default ScrollableTable;
