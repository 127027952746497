import { Card } from "antd";

import {
  PropertyManagementIcon,
  UsersManagementIcon,
} from "../../assets/icons/preferences-tiles-icons";
import ReportsIcon from "../../assets/icons/ReportsIcon";
import useWindowSize from "../../hooks/useWindowSize";
import MenuTile from "../Shared/MenuTile";

function PreferencesMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mt-0 mb-0 text-center sm:text-left !text-triple-blue">
          Preferences
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
          Preferences
        </h1>
      )}
      <Card className="triple-plus-content mt-4 rounded-2xl">
        <div className="mt-2 flex md:flex-row flex-col md:items-start items-center gap-5">
          <MenuTile
            title="Property Management"
            icon={<PropertyManagementIcon />}
            navigateTo="property-management"
          />
          <MenuTile
            title="Users Management"
            icon={<UsersManagementIcon />}
            navigateTo="/preferences/users"
          />
          <MenuTile
            title="Reports Management"
            icon={<ReportsIcon />}
            navigateTo="/preferences/reports"
          />
        </div>
      </Card>
    </>
  );
}

export default PreferencesMenuPage;
