import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useCustomerReport(data, options, key = "Customer") {
  const axiosPrivate = useAxiosPrivate();

  const getCustomerReport = async () => {
    const events = await axiosPrivate({
      method: "POST",
      url: "/Reports/Customer",
      data,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, data], () => getCustomerReport(data), {
    ...options,
  });
}

export default useCustomerReport;
