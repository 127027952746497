import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

import useLoggedUser from "../api/hooks/customHooks/useGetLoggedUser";
import Loader from "../components/Loader/Loader";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = { children: null };
function RequireAdmin({ children }) {
  const location = useLocation();
  const { user, isLoading } = useLoggedUser();

  if (isLoading) return <Loader className="full-flex-center" />;

  if (user?.Roles?.includes("MonitorStationAdmin")) {
    return children;
  }

  return <Navigate to="/forbidden" state={{ from: location }} replace />;
}

RequireAdmin.propTypes = propTypes;
RequireAdmin.defaultProps = defaultProps;

export default RequireAdmin;
