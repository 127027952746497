import dayjs from "dayjs";

function generateSkeletonStatisticsDailyChartData(year, month, day) {
  const statisticsData = [];
  for (let i = 0; i < 24; i += 1) {
    const date = dayjs(new Date(year, month - 1, day, i, 0, 0)).format(
      "YYYY-MM-DDTHH:mm:ss",
    );
    statisticsData.push({
      Date: date,
      Hour: i,
      WaterConsumption: 0,
    });
  }

  return {
    Year: year,
    Month: month,
    Day: day,
    TotalWaterConsumption: 0,
    StatisticsData: statisticsData,
  };
}

export default generateSkeletonStatisticsDailyChartData;
