import { QuestionCircleOutlined, DollarOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";

import "./PropertyWaterSavingsChart.scss";

const propTypes = { className: PropTypes.string, percent: PropTypes.number };
const defaultProps = { className: "", percent: null };

function PropertyWaterSavingsChart({ className, percent }) {
  const tooltipText =
    "This is a comparison between this month  and the last one.";

  return (
    <div className={`property-water-savings ${className}`}>
      <h3 className="property-charts-title mb-2">
        <DollarOutlined className="hidden md:inline-block" />
        <span className="inline-block mx-1">Water Savings</span>
        <Tooltip
          placement="top"
          title={tooltipText}
          color="#303343"
          className="hidden lg:inline-block"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </h3>
      <div className="property-water-savings-chart">
        <span className="text-10">0</span>
        <div className="graph-holder">
          <span
            className="graph graph-base"
            // --rotate-graph-base-before -> negative chart values
            // --rotate-graph-base-after -> positive chart values
            // rotating to 90deg as both the positive and negative
            // parts of the chart are 1/4ths of a circle
            // multiply by 0.9 as we have 100 percents in total but only 90 degrees to represent them
            // adding or subtracting 90 as these are the total degrees
            style={
              percent < 0
                ? {
                    // the positive remainder
                    "--rotate-graph-base-before": `rotate(${
                      percent * 0.9 + 90
                    }deg)`,
                    "--rotate-graph-base-after": "rotate(-90deg)",
                  }
                : {
                    // the negative remainder
                    "--rotate-graph-base-after": `rotate(${
                      percent * 0.9 - 90
                    }deg)`,
                    "--rotate-graph-base-before": "rotate(90deg)",
                  }
            }
          />
          <span className="graph graph-values" />
        </div>

        <span className="graph-tooltip">{percent || "0"}%</span>
      </div>
    </div>
  );
}

PropertyWaterSavingsChart.propTypes = propTypes;
PropertyWaterSavingsChart.defaultProps = defaultProps;

export default PropertyWaterSavingsChart;
