import { Button, Form } from "antd";
import PropTypes from "prop-types";

function SaveChanges({
  className,
  size,
  type,
  htmlType,
  text,
  isLoading,
  disableSaveChanges,
}) {
  return (
    <Form.Item className={className}>
      <Button
        type={type}
        htmlType={htmlType}
        size={size}
        className={className}
        loading={isLoading}
        disabled={disableSaveChanges}
      >
        {text}
      </Button>
    </Form.Item>
  );
}

SaveChanges.defaultProps = {
  className: "xxs:w-full",
  size: "large",
  type: "primary",
  htmlType: "submit",
  text: "Save Changes",
  isLoading: false,
  disableSaveChanges: true,
};

SaveChanges.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  disableSaveChanges: PropTypes.bool,
};

export default SaveChanges;
