import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useFloorsByPropertyCount = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getFloorsByPropertyCount = async () => {
    const propertiesCount = await axiosPrivate({
      method: "GET",
      url: "/PropertyFloor/Overview/Count",
      params,
    }).then((res) => res.data);
    return propertiesCount;
  };

  return useQuery(
    ["getFloorsByPropertyCount", params],
    () => getFloorsByPropertyCount(params),
    {
      enabled: true,
    },
  );
};

export default useFloorsByPropertyCount;
