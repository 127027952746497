import PropTypes from "prop-types";

function HighFlowRateIcon({ className }) {
  return (
    <svg
      id="LOW-MEDIUM-HIGH"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="50"
      height="50"
      className={className}
    >
      <path
        fill={className === "active" ? "#06a5d3" : "#303136"}
        strokeWidth="0px"
        d="M100.06,2.75C46.25,2.75,2.63,46.38,2.63,100.18s43.62,97.43,97.43,97.43,97.43-43.62,97.43-97.43S153.87,2.75,100.06,2.75ZM100.06,185.61c-47.11,0-85.43-38.32-85.43-85.42S52.95,14.76,100.06,14.76s85.43,38.32,85.43,85.42-38.32,85.42-85.43,85.42Z"
      />
      <g id="Shape_1040_copy_3" data-name="Shape 1040 copy 3">
        <path
          fill={className === "active" ? "#06a5d3" : "#303136"}
          strokeWidth="0px"
          d="M50.28,78.83c-6.48,7.76-18.18,23.34-18.18,30.94,0,9.87,8.16,17.91,18.18,17.91s18.18-8.03,18.18-17.91c0-7.61-11.7-23.18-18.18-30.94Z"
        />
      </g>
      <g id="Shape_1040_copy_3-2" data-name="Shape 1040 copy 3">
        <path
          fill={className === "active" ? "#06a5d3" : "#303136"}
          strokeWidth="0px"
          d="M100.38,78.83c-6.48,7.76-18.18,23.34-18.18,30.94,0,9.87,8.16,17.91,18.18,17.91s18.18-8.03,18.18-17.91c0-7.61-11.7-23.18-18.18-30.94Z"
        />
      </g>
      <g id="Shape_1040_copy_4" data-name="Shape 1040 copy 4">
        <path
          fill={className === "active" ? "#06a5d3" : "#303136"}
          strokeWidth="0px"
          d="M149.9,78.83c-6.48,7.76-18.18,23.34-18.18,30.94,0,9.87,8.16,17.91,18.18,17.91s18.18-8.03,18.18-17.91c0-7.61-11.7-23.18-18.18-30.94Z"
        />
      </g>
    </svg>
  );
}

HighFlowRateIcon.propTypes = {
  className: PropTypes.string,
};

HighFlowRateIcon.defaultProps = {
  className: "",
};

export default HighFlowRateIcon;
