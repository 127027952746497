import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePurchaseOptions(options, key = "getPurchaseOptions") {
  const axiosPrivate = useAxiosPrivate();

  const getPurchaseOptions = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "/LicensePlanPurchases/GetAdminPlanPurchases",
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key], () => getPurchaseOptions(), {
    ...options,
  });
}

export default usePurchaseOptions;
