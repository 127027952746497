import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function FlowSensorIcon({ className }) {
  return (
    <svg
      width="1.75em"
      height="1.75em"
      viewBox="0 0 800 800"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M751.5,296.9h-22.8V241c0-15.9-12.9-28.8-28.8-28.8H594.6v-6.6c0-29.3-23.8-53.1-53.1-53.1H110.7     c-24.5,0-32.8,26.6-36.8,39.4c-3.7,11.9-20,92.4-22,105.1H47c-15.9,0-28.8,12.9-28.8,28.8v5.1v49.1v3.1     c0,15.9,12.9,28.8,28.8,28.8h21.5V614c0,29.3,23.8,53.1,53.1,53.1h160.4c29.3,0,53.1-23.8,53.1-53.1V411.8h7.8v36.8     c0,5.5,4.5,9.9,9.9,9.9h88.4c5.5,0,9.9-4.5,9.9-9.9v-36.8h11.2v143c0,29.3,23.8,53.1,53.1,53.1h160.4c29.3,0,53.1-23.8,53.1-53.1     v-143h22.8c15.9,0,28.8-12.9,28.8-28.8v-3.1v-49.1v-5.1C780.4,309.8,767.4,296.9,751.5,296.9z M110.7,179h430.8     c14.6,0,26.5,11.9,26.5,26.5v33.3l131.8-0.1c1.3,0,2.3,1,2.3,2.3v58.4H78.3C83.7,266.2,98.9,179,110.7,179z M308.4,614     c0,14.6-11.9,26.5-26.5,26.5H121.5c-14.6,0-26.5-11.9-26.5-26.5V411.3h213.4V614z M431.1,438.7h-68.5v-26.9h68.5V438.7z      M702.2,554.8c0,14.6-11.9,26.5-26.5,26.5H515.3c-14.6,0-26.5-11.9-26.5-26.5V411.3h213.4V554.8z M753.8,379.9     c0,2.7-2.2,4.9-4.9,4.9H451v-23.6c0-5.5-4.5-9.9-9.9-9.9s-9.9,4.5-9.9,9.9v23.6h-68.5v-23.6c0-5.5-4.5-9.9-9.9-9.9     s-9.9,4.5-9.9,9.9v23.6H49.6c-2.7,0-4.9-2.2-4.9-4.9v-49.1c0-2.7,2.2-4.9,4.9-4.9h699.3c2.7,0,4.9,2.2,4.9,4.9V379.9z"
      />
    </svg>
  );
}

FlowSensorIcon.propTypes = {
  className: PropTypes.string,
};

FlowSensorIcon.defaultProps = {
  className: "",
};
