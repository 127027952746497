import {
  Select,
  Space,
  Row,
  Col,
  Input,
  Switch,
  Divider,
  Form,
  Modal,
  Button,
} from "antd";
import PropTypes from "prop-types";

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeAggregationLevel: PropTypes.func,
  selectedAggregationLevel: PropTypes.string,
  onChangeBucketType: PropTypes.func,
  selectedBucketType: PropTypes.string,
  onChangeDateFormat: PropTypes.func,
  selectedDateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.array,
  selectedIncludePreviousYear: PropTypes.bool,
  onChangeIncludePreviousYear: PropTypes.func,
  propertyNameField: PropTypes.string,
  setPropertyNameField: PropTypes.func,
  propertyAddressField: PropTypes.string,
  setPropertyAddressField: PropTypes.func,
  floorNameField: PropTypes.string,
  setFloorNameField: PropTypes.func,
  systemNameField: PropTypes.string,
  setSystemNameField: PropTypes.func,
  deviceNameField: PropTypes.string,
  setDeviceNameField: PropTypes.func,
  deviceSerialNumberField: PropTypes.string,
  setDeviceSerialNumberField: PropTypes.func,
  deviceLocationField: PropTypes.string,
  setDeviceLocationField: PropTypes.func,
  deviceTypeField: PropTypes.string,
  setDeviceTypeField: PropTypes.func,
};

const defaultProps = {
  open: false,
  onClose: () => {},
  onChangeAggregationLevel: () => {},
  selectedAggregationLevel: "Device",
  onChangeBucketType: () => {},
  selectedBucketType: "year",
  onChangeDateFormat: () => {},
  selectedDateFormat: "",
  dateFormatOptions: [],
  selectedIncludePreviousYear: false,
  onChangeIncludePreviousYear: () => {},
  propertyNameField: "Property",
  setPropertyNameField: () => {},
  propertyAddressField: "Property Address",
  setPropertyAddressField: () => {},
  floorNameField: "Floor",
  setFloorNameField: () => {},
  systemNameField: "System",
  setSystemNameField: () => {},
  deviceNameField: "Device",
  setDeviceNameField: () => {},
  deviceSerialNumberField: "Device S/N",
  setDeviceSerialNumberField: () => {},
  deviceLocationField: "Device Location",
  setDeviceLocationField: () => {},
  deviceTypeField: "Device Type",
  setDeviceTypeField: () => {},
};

function FilterReportDataModal({
  open,
  onClose,
  onChangeAggregationLevel,
  selectedAggregationLevel,
  onChangeBucketType,
  selectedBucketType,
  onChangeDateFormat,
  selectedDateFormat,
  dateFormatOptions,
  selectedIncludePreviousYear,
  onChangeIncludePreviousYear,
  propertyNameField,
  setPropertyNameField,
  propertyAddressField,
  setPropertyAddressField,
  floorNameField,
  setFloorNameField,
  systemNameField,
  setSystemNameField,
  deviceNameField,
  setDeviceNameField,
  deviceSerialNumberField,
  setDeviceSerialNumberField,
  deviceLocationField,
  setDeviceLocationField,
  deviceTypeField,
  setDeviceTypeField,
}) {
  const aggregationLevelOptions = [
    { value: "Device", label: "Device" },
    { value: "System", label: "System" },
    { value: "Floor", label: "Floor" },
    { value: "Property", label: "Property" },
  ];

  const bucketTypeOptions = [
    { value: "year", label: "Year" },
    { value: "month", label: "Month" },
    { value: "day", label: "Day" },
  ];

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      className="report-data-modal"
      maskClosable={false}
      open={open}
      footer={null}
      width={680}
      onCancel={onClose}
    >
      <h2 className="uppercase tracking-widest font-light">
        Configure Report Data
      </h2>
      <div>
        <Divider orientation="left" orientationMargin={0}>
          Choose proper timeframe, aggregation level & date format
        </Divider>
        <Space direction="horizontal">
          <Form.Item
            label="Aggregation Level"
            name="aggregationLevel"
            rules={[
              {
                required: true,
                message: "Aggregation Level field is required.",
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "130px" }}
              placeholder="Aggregation Level"
              onChange={onChangeAggregationLevel}
              filterOption={filterSelect}
              className="aggregation-level-selector"
              value={selectedAggregationLevel}
              options={aggregationLevelOptions}
            />
          </Form.Item>
          <Form.Item
            label="Timeframe"
            name="bucketType"
            rules={[
              { required: true, message: "Bucket Type field is required." },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "130px" }}
              placeholder="Timeframe"
              onChange={onChangeBucketType}
              filterOption={filterSelect}
              className="bucket-type-selector"
              value={selectedBucketType}
              options={bucketTypeOptions}
            />
          </Form.Item>
          <Form.Item
            label="Date Format"
            name="dateFormat"
            rules={[
              { required: true, message: "Date Format field is required." },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "28rem" }}
              placeholder="Date Format"
              onChange={onChangeDateFormat}
              filterOption={filterSelect}
              className="date-format-selector"
              value={selectedDateFormat}
              options={dateFormatOptions}
            />
          </Form.Item>
        </Space>
        <Space direction="horizontal">
          <Form.Item
            label="Include Previous Year"
            name="includePreviousYear"
            valuePropName="checked"
          >
            <Switch
              className="include-previous-year-switch"
              defaultChecked={selectedIncludePreviousYear}
              onChange={onChangeIncludePreviousYear}
            />
          </Form.Item>
        </Space>
        <Divider orientation="left" orientationMargin={0}>
          Add custom field namings
        </Divider>
        <Row gutter={8} className="mb-2">
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System" ||
            selectedAggregationLevel === "Floor" ||
            selectedAggregationLevel === "Property") && (
            <>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="PropertyName"
                  allowClear
                  value={propertyNameField}
                  onChange={(e) => setPropertyNameField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="PropertyAddress"
                  allowClear
                  value={propertyAddressField}
                  onChange={(e) => setPropertyAddressField(e.target.value)}
                />
              </Col>
            </>
          )}
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System" ||
            selectedAggregationLevel === "Floor") && (
            <Col className="gutter-row" span={6}>
              <Input
                placeholder="FloorName"
                allowClear
                value={floorNameField}
                onChange={(e) => setFloorNameField(e.target.value)}
              />
            </Col>
          )}
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System") && (
            <Col className="gutter-row" span={6}>
              <Input
                placeholder="SystemName"
                allowClear
                value={systemNameField}
                onChange={(e) => setSystemNameField(e.target.value)}
              />
            </Col>
          )}
        </Row>
        <Row gutter={8} className="mb-4">
          {selectedAggregationLevel === "Device" && (
            <>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="DeviceName"
                  allowClear
                  value={deviceNameField}
                  onChange={(e) => setDeviceNameField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="DeviceSerialNumber"
                  allowClear
                  value={deviceSerialNumberField}
                  onChange={(e) => setDeviceSerialNumberField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="DeviceLocation"
                  allowClear
                  value={deviceLocationField}
                  onChange={(e) => setDeviceLocationField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder="DeviceType"
                  allowClear
                  value={deviceTypeField}
                  onChange={(e) => setDeviceTypeField(e.target.value)}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
      <Button
        type="primary"
        size="large"
        className="flex ml-auto"
        onClick={() => onClose(true)}
      >
        Done
      </Button>
    </Modal>
  );
}

FilterReportDataModal.propTypes = propTypes;
FilterReportDataModal.defaultProps = defaultProps;

export default FilterReportDataModal;
