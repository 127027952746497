const removeCommandFromStorage = (deviceId) => {
  const currentActiveComands =
    JSON.parse(localStorage.getItem("commands")) ?? [];

  localStorage.setItem(
    "commands",
    JSON.stringify(
      currentActiveComands?.filter((command) => command.deviceId !== deviceId),
    ),
  );
};

export default removeCommandFromStorage;
