import { useState } from "react";

import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import "./DurationTimePicker.scss";

function DurationTimePicker({
  id,
  handleOnClickCallback,
  handleOnChangeCallback,
  rangeValid,
}) {
  const [time, setTime] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);

  const handleTimeChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9:]/g, "");
    const isValidTime = /^([0-9]{1,2}:([0-5][0-9]|59))?$/.test(sanitizedValue);
    const clampedValue = isValidTime
      ? sanitizedValue
      : `${sanitizedValue.substring(0, 3)}5${sanitizedValue.substring(4)}`;
    setTime(clampedValue);
    handleOnChangeCallback(clampedValue);

    if (!firstLoad) {
      setFirstLoad(true);
    }
  };

  return (
    <InputMask
      className={`ant-btn ant-btn-primary ant-btn-lg duration-input ${
        firstLoad && !rangeValid ? "invalid-range" : ""
      }`}
      mask="99:99"
      maskChar="0"
      value={time}
      onChange={handleTimeChange}
      onClick={() => handleOnClickCallback(id)}
      placeholder="hh:mm"
    />
  );
}

DurationTimePicker.defaultProps = {
  id: "",
  handleOnClickCallback: () => {},
  handleOnChangeCallback: () => {},
  rangeValid: true,
};

DurationTimePicker.propTypes = {
  id: PropTypes.string,
  handleOnClickCallback: PropTypes.func,
  handleOnChangeCallback: PropTypes.func,
  rangeValid: PropTypes.bool,
};

export default DurationTimePicker;
