import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useScheduleDelete() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: (programId) => {
      return axiosPrivate({
        method: "DELETE",
        url: `Schedule/Program/${programId}`,
      });
    },
  });
}

export default useScheduleDelete;
