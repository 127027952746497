import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useFdsFilters(params, options, key = "getFdsFilters") {
  const axiosPrivate = useAxiosPrivate();

  const getFdsFilters = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "Schedule/Fds/Filters",
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getFdsFilters(params), {
    ...options,
  });
}

export default useFdsFilters;
