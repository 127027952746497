import { createContext, useState, useMemo } from "react";

import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

const UserSettingsContext = createContext({
  userSettings: {
    waterUnit: null,
    temperatureUnut: null,
    clockType: null,
  },
});

// the global context for user settings
export function UserSettingsProvider({ children }) {
  const [userSettings, setUserSettings] = useState({
    waterUnit: null,
    temperatureUnut: null,
    clockType: null,
  });
  const value = useMemo(() => {
    return { userSettings, setUserSettings };
  }, [userSettings]);
  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  );
}

UserSettingsProvider.propTypes = propTypes;
UserSettingsProvider.defaultProps = defaultProps;

export default UserSettingsContext;
