import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import PropTypes from "prop-types";

import { HubIcon, SystemIcon } from "../../../assets/icons/systems";
import Loader from "../../Loader/Loader";

const propTypes = {
  className: PropTypes.string,
  floor: PropTypes.object.isRequired,
  floorOverviewStatus: PropTypes.object,
  floorOverviewStatusesIsLoading: PropTypes.bool,
};

const defaultProps = {
  className: "",
  floorOverviewStatus: {},
  floorOverviewStatusesIsLoading: false,
};

function FloorDetails({
  className,
  floor,
  floorOverviewStatus,
  floorOverviewStatusesIsLoading,
}) {
  return (
    <div className={`property-details ${className}`}>
      <h3 className="property-details-title">{floor?.Name}</h3>

      <div className="property-details-stats">
        <div className="property-details-systems">
          <div className="font-light inline-flex">
            <SystemIcon className="align-sub" />
            <span className="text-lg mx-1">{floor?.SystemsCount}</span>
          </div>
        </div>

        <Divider type="vertical" className="xl:mx-2" />

        <div className="property-details-devices">
          <div className="font-light inline-flex">
            <HubIcon className="align-sub" />
            <span className="text-lg mx-1">{floor?.DevicesCount}</span>
          </div>
        </div>

        <Divider type="vertical" className="xl:mx-2" />

        <div className="property-details-alerts">
          {floorOverviewStatusesIsLoading && (
            <Loader className="justify-center" />
          )}
          {floorOverviewStatus &&
            floorOverviewStatus.SystemStatuses?.flatMap(
              (x) => x.DeviceStatuses,
            ) && (
              <>
                <ExclamationCircleFilled className="text-triple-red text-md xl:text-xl" />
                <div className="font-light">
                  <span className="text-lg mx-1">
                    {
                      floorOverviewStatus?.SystemStatuses?.flatMap(
                        (x) => x.DeviceStatuses,
                      ).filter((d) => d.Status === "Alert")?.length
                    }
                  </span>
                  <span className="hidden xxl:inline-flex items-center">
                    Alerts
                  </span>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

FloorDetails.propTypes = propTypes;
FloorDetails.defaultProps = defaultProps;

export default FloorDetails;
