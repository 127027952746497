import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";

import Loader from "../components/Loader/Loader";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

// Silent authentication on refresh
function PersistLogin({ children }) {
  const refreshToken = useRefreshToken();
  const { auth } = useAuth();

  const { refetch: refreshAccessToken } = useQuery(
    ["refreshAccessToken"],
    refreshToken,
    {
      // don't fetch automatically
      enabled: false,
      // don't fetch on mount
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      refreshAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!auth?.isAuthenticated ? (
        <Loader className="full-flex-center" />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </>
  );
}

PersistLogin.propTypes = propTypes;
PersistLogin.defaultProps = defaultProps;

export default PersistLogin;
