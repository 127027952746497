import { useState } from "react";

import { Row, Col, Divider, InputNumber, DatePicker } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import ImageUploader from "../../ImageUploader/ImageUploader";
import "./Header.scss";

function Header({
  property,
  totalCharges,
  costOfWaterPerUOM,
  setCostOfWaterPerUOM,
}) {
  const now = new Date();
  const lastDayOfCurrentMonth = dayjs(
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  );

  const [fileListArr, setFileListArr] = useState([]);
  const [billingDate, setBillingDate] = useState(dayjs());
  const [dueDate, setDueDate] = useState(lastDayOfCurrentMonth);

  const onImageChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const imageRequest = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess("ok");
      }
    }, 1000);
  };

  const onChangeCostOfWaterPerUOM = (value) => {
    setCostOfWaterPerUOM(value);
  };

  const onBillingDateChange = (_, dateString) => {
    if (dateString) {
      setBillingDate(dayjs(dateString));
    }
  };

  const onDueDateChange = (_, dateString) => {
    if (dateString) {
      setDueDate(dayjs(dateString));
    }
  };

  return (
    <Row className="w-full justify-between items-center content-center text-triple-header px-1 report-customer-header max-h-max">
      <Col span={17} className="bg-triple-white rounded-md -ml-1 h-full">
        <Row className="p-4 w-full justify-between">
          <Row className="xxxl:w-2/3">
            <Col className="h-24">
              <ImageUploader
                fileListArr={fileListArr}
                onRemoveImg={onRemoveImg}
                onChange={onImageChange}
                request={imageRequest}
                autoUpload={false}
              />
            </Col>
            <Col className="w-[60%] px-4">
              <Row>Cost of water per unit of measurement</Row>
              <Divider className="my-2 border-2 opacity-25" />
              <Row>
                <InputNumber
                  className="w-full"
                  placeholder="Cost of water per unit of measurement"
                  size="small"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  defaultValue={costOfWaterPerUOM}
                  onChange={onChangeCostOfWaterPerUOM}
                />
              </Row>
            </Col>
          </Row>
          <Col className="xxxl:w-1/3 mt-4 xxxl:mt-0 flex gap-4 xxxl:flex-col xxxl:gap-0 xxxl:px-0 justify-between w-full px-8">
            <Col className="mb-2">
              <Row className="uppercase">Property unit</Row>
              <Row>{property?.Name}</Row>
            </Col>
            <Col>
              <Row className="uppercase">Account number</Row>
              <Row>0885-826990</Row>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col span={7} className="bg-triple-white rounded-md -mr-1 h-full">
        <Row className="p-3">
          <Col className="w-full">
            <Row className="w-full justify-between">
              <Col className="uppercase text-xs">Billing date</Col>
              <Col className="text-xs">
                <DatePicker
                  className="h-5"
                  inputReadOnly
                  placeholder="Select billing date"
                  value={billingDate}
                  onChange={(_, dateString) =>
                    onBillingDateChange(_, dateString)
                  }
                  picker="day"
                />
              </Col>
            </Row>
            <Row className="my-2 w-full justify-between">
              <Col className="uppercase text-xs">Due date</Col>
              <Col className="text-xs">
                <DatePicker
                  className="h-5"
                  inputReadOnly
                  placeholder="Select billing date"
                  value={dueDate}
                  onChange={(_, dateString) => onDueDateChange(_, dateString)}
                  picker="day"
                />
              </Col>
            </Row>
            <Row className="w-full justify-between">
              <Row className="uppercase">Amount due</Row>
              <Row className="text-triple-red font-medium pr-2">
                {totalCharges}
              </Row>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Header.defaultProps = {
  property: {},
  totalCharges: 0,
  costOfWaterPerUOM: 0,
  setCostOfWaterPerUOM: () => {},
};
Header.propTypes = {
  property: PropTypes.object,
  totalCharges: PropTypes.number,
  costOfWaterPerUOM: PropTypes.number,
  setCostOfWaterPerUOM: PropTypes.func,
};

export default Header;
