import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsMonthlyWaterConsumption(
  year,
  month,
  params,
  options,
  key = "getMonthlyWaterConsumptionData",
) {
  const axiosPrivate = useAxiosPrivate();

  const getMonthlyWaterConsumptionData = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/${year}/${month}`,
      params,
    });

    return data;
  };

  return useQuery(
    [key],
    () => getMonthlyWaterConsumptionData(year, month, params),
    {
      ...options,
    },
  );
}

export default useStatisticsMonthlyWaterConsumption;
