const buildEventTitle = (eventTitle) => {
  const wordMap = {
    ChangedFrom: "changed from",
    ChangedTo: "to",
    SentBy: "sent by",
  };

  if (eventTitle?.length > 0) {
    const newArr = [];
    for (let i = 0; i < eventTitle.length; i += 1) {
      let keyWord = eventTitle[i];

      if (keyWord === "Configuration") {
        keyWord = "Add/Remove devices";
      }

      if (wordMap[keyWord]?.length > 1) {
        newArr.push(wordMap[keyWord]);
      } else {
        newArr.push(
          keyWord?.replace(/([A-Z][a-z])/g, " $1").replace("/ ", "/"),
        );
      }
    }

    return newArr?.join("")?.replace(/ /g, " ");
  }

  return "";
};

export default buildEventTitle;
