function PropertyOverviewIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="blue-fill-color purple-stroke-color"
        stroke="#E1E0E3"
        rx="20px"
        ry="20px"
        strokeLinejoin="round"
        d="M 7.301 7.109 L 7.301 10.008 L 4.543 10.008 L 4.543 20.285 L 18.543 20.285 L 18.543 4.211 L 7.301 4.211 L 7.301 7.109 Z M 4.573 20.263 L 18.514 20.256 L 11.882 20.228 L 11.94 10.037 L 4.545 10.037 L 4.573 20.263 Z"
      />
      <path
        className="purple-both-colors"
        fill="#E1E0E3"
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 9.550781 6.671875 C 9.546875 6.671875 9.546875 7.140625 9.546875 7.710938 L 9.546875 8.746094 L 11.625 8.746094 L 11.625 6.667969 L 10.589844 6.667969 C 10.023438 6.667969 9.554688 6.667969 9.550781 6.671875 Z M 14.203125 6.671875 C 14.199219 6.671875 14.195312 7.140625 14.195312 7.710938 L 14.195312 8.746094 L 16.277344 8.746094 L 16.277344 7.707031 L 16.273438 6.671875 L 15.238281 6.667969 C 14.671875 6.667969 14.203125 6.667969 14.203125 6.671875 Z M 14.195312 13.40625 L 14.195312 14.441406 L 16.277344 14.441406 L 16.277344 12.367188 L 14.195312 12.367188 Z M 14.203125 15.851562 C 14.199219 15.851562 14.195312 16.320312 14.195312 16.890625 L 14.195312 17.925781 L 16.277344 17.925781 L 16.277344 16.886719 L 16.273438 15.847656 L 15.238281 15.847656 C 14.671875 15.847656 14.203125 15.847656 14.203125 15.851562 Z M 7.675781 13.40625 L 7.675781 14.441406 L 9.753906 14.441406 L 9.753906 12.367188 L 7.675781 12.367188 Z M 7.679688 15.851562 C 7.675781 15.851562 7.675781 16.320312 7.675781 16.890625 L 7.675781 17.925781 L 9.753906 17.925781 L 9.753906 16.886719 L 9.75 15.847656 L 8.71875 15.847656 C 8.148438 15.847656 7.679688 15.847656 7.679688 15.851562 Z M 7.679688 15.851562 "
      />
    </svg>
  );
}

export default PropertyOverviewIcon;
