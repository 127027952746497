import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useFlowRateActions = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getFlowRateActions = async () => {
    const actions = await axiosPrivate({
      method: "GET",
      url: "/Schedule/Fds/FlowRateActions",
      params,
    }).then((res) => res.data);
    return actions;
  };

  return useQuery(
    ["getFlowRateActions", params],
    () => getFlowRateActions(params),
    {
      enabled: true,
    },
  );
};

export default useFlowRateActions;
