import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyFloorDelete() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (floorId) => {
      return axiosPrivate({
        method: "DELETE",
        url: `PropertyFloor/${floorId}`,
      });
    },
  });
}

export default usePropertyFloorDelete;
