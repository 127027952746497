import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsBiennialDailyWaterConsumption(
  year,
  month,
  day,
  params,
  options,
  key = "getBiennialDailyWaterConsumptionData",
) {
  const axiosPrivate = useAxiosPrivate();

  const getBiennialDailyWaterConsumptionData = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/Biennial/${year}/${month}/${day}`,
      params,
    });

    return data;
  };

  return useQuery(
    [key],
    () => getBiennialDailyWaterConsumptionData(year, month, params),
    {
      ...options,
    },
  );
}

export default useStatisticsBiennialDailyWaterConsumption;
