import { useQuery } from "@tanstack/react-query";

import useServiceProvider from "../useServiceProvider";

const useGetServiceProvider = (serviceProviderId) => {
  const { refetch: getServiceProvider } = useServiceProvider(
    serviceProviderId,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
    },
    `${serviceProviderId}-getServiceProvider`,
  );
  const { data: response, isLoading } = useQuery({
    queryKey: [`${serviceProviderId}-getServiceProvider`],
    queryFn: getServiceProvider,
    staleTime: 1000 * 60 * 10, // data is fresh for 10 mins
    cacheTime: 1000 * 60 * 10, // remove from cache after 10 mins
  });
  const serviceProvider = response?.data || response;

  return { serviceProvider, isLoading };
};

export default useGetServiceProvider;
