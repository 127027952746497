import PropTypes from "prop-types";

function LowFlowRateIcon({ className }) {
  return (
    <svg
      id="LOW-MEDIUM-HIGH"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width="50"
      height="50"
      className={className}
    >
      <path
        fill={className === "active" ? "#06a5d3" : "#303136"}
        strokeWidth="0px"
        d="M100.06,2.75C46.25,2.75,2.63,46.38,2.63,100.18s43.62,97.43,97.43,97.43,97.43-43.62,97.43-97.43S153.87,2.75,100.06,2.75ZM100.06,185.61c-47.11,0-85.43-38.32-85.43-85.42S52.95,14.76,100.06,14.76s85.43,38.32,85.43,85.42-38.32,85.42-85.43,85.42Z"
      />
      <path
        fill={className === "active" ? "#06a5d3" : "#303136"}
        strokeWidth="0px"
        d="M81.63,106.96c0,9.88,8.16,17.92,18.18,17.92s18.18-8.04,18.18-17.92c0-7.61-11.7-23.2-18.18-30.96-6.48,7.76-18.18,23.35-18.18,30.96Z"
      />
    </svg>
  );
}
LowFlowRateIcon.propTypes = {
  className: PropTypes.string,
};

LowFlowRateIcon.defaultProps = {
  className: "",
};
export default LowFlowRateIcon;
