import PropTypes from "prop-types";

import convertToF from "../../helpers/convertToF";
import useProfileSettings from "../../hooks/useProfileSettings";

const propTypes = {
  value: PropTypes.number,
  showUnitOfMeasure: PropTypes.bool,
};

const defaultProps = {
  value: 0,
  showUnitOfMeasure: true,
};

function Temperature({ value, showUnitOfMeasure = true }) {
  const { userSettings } = useProfileSettings();

  switch (userSettings.temperatureUnut) {
    case "Fahrenheit": {
      const fahrenheit = convertToF(value);
      return showUnitOfMeasure ? `${fahrenheit} °F` : `${fahrenheit}`;
    }
    case "Celsius":
    default:
      return showUnitOfMeasure ? `${value} °C` : `${value}`;
  }
}

Temperature.propTypes = propTypes;
Temperature.defaultProps = defaultProps;

export default Temperature;
