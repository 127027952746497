import { Form, Radio } from "antd";
import PropTypes from "prop-types";

function Toggle({ fieldLabel, fieldName, fieldValues, className, size }) {
  return (
    <Form.Item className={className} label={fieldLabel} name={fieldName}>
      <Radio.Group buttonStyle="solid" className="w-full" size={size}>
        {fieldValues.map((field) => (
          <Radio.Button
            value={field.value}
            className="w-1/2"
            key={`${fieldName}_${field.value}`}
          >
            <span className="text-triple-white">{field.label}</span>
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
}

Toggle.defaultProps = {
  fieldLabel: "",
  fieldName: "",
  fieldValues: [
    {
      value: "",
      label: "",
    },
  ],
  className: "",
  size: "large",
};

Toggle.propTypes = {
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValues: PropTypes.array,
  className: PropTypes.string,
  size: PropTypes.string,
};
export default Toggle;
