import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";

const propTypes = {
  fileListArr: PropTypes.array,
  maxAllowedFiles: PropTypes.number,
  disabled: PropTypes.bool,
  autoUpload: PropTypes.bool,
  onRemoveImg: PropTypes.func,
  onChange: PropTypes.func,
  request: PropTypes.func,
  placeholder: PropTypes.string,
};

const defaultPropTypes = {
  fileListArr: [],
  maxAllowedFiles: 1,
  disabled: false,
  autoUpload: false,
  onRemoveImg: () => {},
  onChange: () => {},
  request: () => {},
  placeholder: "+ Upload",
};

function ImageUploader({
  fileListArr,
  maxAllowedFiles,
  disabled,
  autoUpload,
  onRemoveImg,
  onChange,
  request,
  placeholder,
}) {
  return (
    <ImgCrop
      modalProps={{
        okButtonProps: { size: "large" },
        cancelButtonProps: { size: "large" },
      }}
      beforeCrop={(file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 4) {
          message.error("Image File size must be smaller than 4MB.");
          return false;
        }

        return true;
      }}
    >
      <Upload
        listType="picture-card"
        fileList={fileListArr}
        onChange={onChange}
        onRemove={onRemoveImg}
        disabled={disabled}
        autoUpload={autoUpload}
        customRequest={request}
      >
        {fileListArr.length < maxAllowedFiles && placeholder}
      </Upload>
    </ImgCrop>
  );
}

ImageUploader.propTypes = propTypes;
ImageUploader.defaultProps = defaultPropTypes;

export default ImageUploader;
