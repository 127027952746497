import { useEffect, useState } from "react";

import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";
import PropTypes from "prop-types";

import usePropertyFloorDelete from "../../../../api/hooks/usePropertyFloorDelete";
import PropertyFloorInput from "./PropertyFloorInput";

function PropertyFloors({ formRef, initialFormValues }) {
  const [floorCount, setFloorCount] = useState();
  const floorsLength = formRef.getFieldValue("Floors")?.length;
  const { mutate: deletePropertyFloor } = usePropertyFloorDelete();

  const handleRemove = (index) => {
    const currentValues = formRef.getFieldValue("Floors");
    const floorToRemove = currentValues[index];
    // Make the API call to delete the object then sync the UI by removing the Input field.
    deletePropertyFloor(floorToRemove.Id, {
      onSuccess: () => {
        formRef.setFieldsValue({
          Floors: currentValues.filter((_, i) => i !== index),
        });
        // eslint-disable-next-line no-param-reassign
        initialFormValues.current.Floors = currentValues.filter(
          (_, i) => i !== index,
        );
        const floorCount = currentValues.length;
        setFloorCount(floorCount);
      },
    });
  };

  useEffect(() => {
    setFloorCount(floorsLength);
  }, [floorsLength]);
  return (
    <>
      <FormItemLabel label="Floors" />
      <Divider className="my-1" />
      <FormItemLabel label="Total Floors" />
      <Input
        size="middle"
        className="mb-4"
        disabled
        value={floorCount}
        title="Total Floors"
        placeholder="Total Floors Count"
      />
      <Form.List name="Floors">
        {(fields) => (
          <>
            {fields.map(({ key, name }) => {
              const floorField = formRef.getFieldValue("Floors")[key];
              return (
                <PropertyFloorInput
                  name={name}
                  key={key}
                  floorField={floorField}
                  onRemove={(name) => {
                    handleRemove(name);
                  }}
                />
              );
            })}
          </>
        )}
      </Form.List>
      <Form.List name="AddPropertyFloors">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name }) => (
                <Form.Item
                  key={key}
                  name={[name, "Name"]}
                  rules={[
                    {
                      required: true,
                      message: "Floor Name is required.",
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <Button
                        type="primary"
                        shape="default"
                        size="small"
                        onClick={() => remove(name)}
                        icon={<MinusOutlined />}
                      />
                    }
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Floor
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
}

PropertyFloors.propTypes = {
  formRef: PropTypes.object,
  initialFormValues: PropTypes.object,
};

PropertyFloors.defaultProps = {
  formRef: {},
  initialFormValues: {},
};

export default PropertyFloors;
