import dayjs from "dayjs";

function generateSKeletonStatisticsMonthlyChartData(year, month) {
  const days = dayjs(`${year}-${month}`).daysInMonth();
  const statisticsData = [];
  for (let i = 1; i <= days; i += 1) {
    statisticsData.push({
      Day: i,
      WaterConsumption: 0,
    });
  }

  return {
    Year: year,
    Month: month,
    StatisticsData: statisticsData,
    TotalWaterConsumption: 0,
  };
}

export default generateSKeletonStatisticsMonthlyChartData;
