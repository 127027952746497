function AnalyticsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="blue-fill-color"
        stroke="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 15.176 3.961 L 15.176 20.035 L 19.805 20.035 L 19.805 3.961 L 15.176 3.961 Z M 9.688 9.645 C 9.684 9.648 9.684 20.035 9.684 20.035 L 14.313 20.035 L 14.309 9.645 C 14.309 9.645 9.691 9.641 9.688 9.645 Z M 4.195 14.648 L 4.195 20.035 L 8.82 20.035 L 8.82 14.648 L 4.195 14.648 Z"
      />
    </svg>
  );
}

export default AnalyticsIcon;
