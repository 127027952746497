import { createContext, useState, useMemo } from "react";

import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
});

// the global context for user authentication
export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
  });
  const value = useMemo(() => {
    return { auth, setAuth };
  }, [auth]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = propTypes;
AuthProvider.defaultProps = defaultProps;

export default AuthContext;
