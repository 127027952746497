import { useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row } from "antd";
import PropTypes from "prop-types";

import { HubIcon, SystemIcon } from "../../../../../assets/icons/systems";
import useWindowSize from "../../../../../hooks/useWindowSize";
import UnsavedChangesWarning from "../../../../Shared/UnsavedChangesWarning";
import FloorEdit from "./FloorEdit";

import "../scss/FloorCard.scss";

function FloorCard({ objectFloor, refetchPropertyFloors }) {
  const window = useWindowSize();
  const floorDevicesCount = objectFloor?.Systems?.flatMap(
    (system) => system.Devices,
  ).length;
  const floorSystemsCount = objectFloor?.SystemsCount;
  const [isEditFloorModalOpen, setIsEditFloorModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editFormFloorFormReference, setEditFloorFormReference] = useState();

  const onEditButtonClick = () => {
    setIsEditFloorModalOpen(true);
  };

  const onCancelButtonClick = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsEditFloorModalOpen(false);
      editFormFloorFormReference.resetFields();
      refetchPropertyFloors();
    }
  };

  return (
    <div
      role="none"
      className="floor-management-card border-triple-blue h-full"
    >
      <Row className="h-full">
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <div className="floor-management-card-details">
            <h3 className="floor-management-card-details-title">
              {objectFloor?.Name}
            </h3>
          </div>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 5 }}>
          <div className="flex items-center justify-between h-full">
            <div className="text-triple-white flex items-center">
              <span className="mr-2">{floorSystemsCount}</span>
              <SystemIcon />
            </div>
            <div className="text-triple-white flex items-center">
              <span className="mr-2">{floorDevicesCount}</span>
              <HubIcon />
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 9 }}
          className="flex justify-end items-center"
        >
          <Button
            type="primary"
            onClick={onEditButtonClick}
            size="large"
            icon={<EditOutlined />}
            className="mr-2 my-2"
          >
            EDIT
          </Button>
        </Col>
      </Row>
      <Modal
        className="property-management-floor-edit-modal"
        maskClosable={false}
        footer={null}
        open={isEditFloorModalOpen}
        keyboard={false}
        onCancel={onCancelButtonClick}
        destroyOnClose
        width={1800}
        bodyStyle={{ height: "75vh", overflowY: "auto", marginTop: "1.5rem" }}
      >
        <FloorEdit
          floorId={objectFloor?.Id}
          setIsFormDirty={setIsFormDirty}
          setEditFloorFormReference={setEditFloorFormReference}
        />
      </Modal>
      <UnsavedChangesWarning
        onSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          editFormFloorFormReference.submit();
        }}
        onDontSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          setIsEditFloorModalOpen(false);
          setIsFormDirty(false);
          editFormFloorFormReference.resetFields();
          refetchPropertyFloors();
        }}
        onCancelClick={() => {
          setAlertUnsavedChangesOpen(false);
        }}
        isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
      />
    </div>
  );
}

FloorCard.propTypes = {
  objectFloor: PropTypes.object,
  refetchPropertyFloors: PropTypes.func,
};

FloorCard.defaultProps = {
  objectFloor: {},
  refetchPropertyFloors: () => {},
};

export default FloorCard;
