import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function RepeaterIcon({ className }) {
  return (
    <svg
      width="1.6em"
      height="1.6em"
      viewBox="0 0 90 90"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M44.8,0.9C35.5,0.9,28,8.5,28,17.7v55.1c0,9.2,7.5,16.8,16.8,16.8c9.2,0,16.8-7.5,16.8-16.8V17.7       C61.5,8.5,54,0.9,44.8,0.9z M57.7,72.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V17.7c0-7.1,5.8-12.9,12.9-12.9       c7.1,0,12.9,5.8,12.9,12.9V72.8z" />
        <path d="M44.8,70.8c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9C46.7,71.6,45.8,70.8,44.8,70.8z" />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M73.6,25.2c-0.7-0.6-1.9-0.5-2.5,0.2c-0.6,0.7-0.6,1.7,0.2,2.3c5.6,4.4,8.8,10.8,8.8,17.5c0,6.8-3.3,13.3-9,17.7       c-0.4,0.3-0.6,0.8-0.6,1.2c0,0.4,0.1,0.7,0.4,1c0.6,0.7,1.7,0.8,2.5,0.2c6.5-5,10.3-12.4,10.3-20.1       C83.7,37.5,80,30.2,73.6,25.2z M68.7,30.6c-0.8-0.6-1.9-0.5-2.5,0.2c-0.3,0.3-0.4,0.7-0.4,1c0,0.5,0.2,0.9,0.6,1.2       c4,3,6.2,7.4,6.2,12.1c0,4.7-2.3,9.1-6.2,12.1c-0.7,0.6-0.8,1.6-0.2,2.3c0.6,0.7,1.7,0.8,2.5,0.2c4.7-3.6,7.5-8.9,7.5-14.5       C76.2,39.5,73.4,34.2,68.7,30.6z"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M18.5,62.8c-5.7-4.4-9-10.9-9-17.7c0-6.7,3.2-13.1,8.8-17.5c0.7-0.6,0.8-1.6,0.2-2.3c-0.6-0.7-1.8-0.7-2.5-0.2       c-6.4,5-10.1,12.3-10.1,20c0,7.8,3.7,15.1,10.3,20.1c0.7,0.6,1.9,0.5,2.5-0.2c0.3-0.3,0.4-0.7,0.4-1       C19.1,63.6,18.9,63.2,18.5,62.8z M23.1,57.2c-3.9-3-6.2-7.4-6.2-12.1c0-4.7,2.3-9.1,6.2-12.1c0.4-0.3,0.6-0.8,0.6-1.2       c0-0.4-0.1-0.7-0.4-1c-0.6-0.7-1.7-0.8-2.5-0.2c-4.8,3.6-7.5,8.9-7.5,14.6c0,5.6,2.7,10.9,7.5,14.5c0.7,0.6,1.9,0.5,2.5-0.2       S23.8,57.8,23.1,57.2z"
        />
      </g>
    </svg>
  );
}

RepeaterIcon.propTypes = {
  className: PropTypes.string,
};

RepeaterIcon.defaultProps = {
  className: "",
};
