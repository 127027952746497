import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Button } from "antd";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

const defaultProps = {
  className: "",
  disabled: false,
  placeholder: "Search",
  value: null,
  onChange: () => {},
  onSearch: () => {},
};

function SearchInput({
  className,
  disabled,
  value,
  placeholder,
  onChange,
  onSearch,
}) {
  return (
    <Space.Compact className={className}>
      <Input
        allowClear
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <Button
        type="primary"
        className="px-4"
        disabled={disabled}
        onClick={onSearch}
      >
        <SearchOutlined />
      </Button>
    </Space.Compact>
  );
}

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;

export default SearchInput;
