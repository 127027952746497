import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import PropTypes from "prop-types";

const GoogleRecaptcha = forwardRef(({ siteKey, action }, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoaded = useCallback(() => {
    window.grecaptcha.ready(() => {
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!window.grecaptcha) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);
      return () => {
        script.removeEventListener("load", handleLoaded);
        document.body.removeChild(script);
      };
    }

    handleLoaded();
    return undefined;
  }, [siteKey, handleLoaded]);

  const executeReCaptcha = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (isLoaded && window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(siteKey, { action })
            .then(resolve)
            .catch(reject);
        });
      } else {
        reject(new Error("reCAPTCHA not loaded."));
      }
    });
  }, [isLoaded, siteKey, action]);

  useImperativeHandle(
    ref,
    () => ({
      execute: executeReCaptcha,
    }),
    [executeReCaptcha],
  );

  return null;
});

GoogleRecaptcha.propTypes = {
  siteKey: PropTypes.string,
  action: PropTypes.string,
};

GoogleRecaptcha.defaultProps = {
  siteKey: "",
  action: "",
};
export default GoogleRecaptcha;
