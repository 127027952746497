import { MenuFoldOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

const propTypes = {
  columns: PropTypes.array,
  columnsToDisplay: PropTypes.array,
  onSidebarFold: PropTypes.func,
  onColumnsToDisplayChange: PropTypes.func,
};

const defaultProps = {
  columns: [],
  columnsToDisplay: [],
  onSidebarFold: () => {},
  onColumnsToDisplayChange: () => {},
};

function CustomTableSidebar({
  columns,
  columnsToDisplay,
  onSidebarFold,
  onColumnsToDisplayChange,
}) {
  const onColumnsToDisplayChangeHandler = (e) => {
    const { value, checked } = e.target;
    let finalColumnsToDisplay;

    if (checked) {
      finalColumnsToDisplay = [...columnsToDisplay, value];
    } else {
      const index = columnsToDisplay?.indexOf(value);
      if (index !== -1) {
        finalColumnsToDisplay = [...columnsToDisplay].filter((column) => {
          return column !== value;
        });
      }
    }

    onColumnsToDisplayChange(finalColumnsToDisplay);
  };

  return (
    <div className="px-2">
      <div className="mb-8 flex items-center text-triple-white font-medium">
        <MenuFoldOutlined className="mr-3" onClick={onSidebarFold} />
        <span>Columns View</span>
      </div>
      <div className="columns-to-display">
        {columns.map((column) => {
          return (
            <Checkbox
              className="column-to-display"
              defaultChecked
              key={column.key}
              value={column.key}
              checked={columnsToDisplay?.includes(column.key)}
              onChange={onColumnsToDisplayChangeHandler}
            >
              {column.title}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
}

CustomTableSidebar.propTypes = propTypes;
CustomTableSidebar.defaultProps = defaultProps;

export default CustomTableSidebar;
