import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useForgotPassword() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (requestBody) => {
      return axiosPrivate({
        method: "POST",
        url: "/Users/ResetPasswordV2",
        data: {
          username: requestBody.username,
          recaptcha_token: requestBody.recaptcha_token,
        },
      });
    },
  });
}

export default useForgotPassword;
