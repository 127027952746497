import { useEffect, useState } from "react";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { Outlet, useRoutes } from "react-router-dom";

import useUser from "./api/hooks/useUser";
import useUserBasicsV2 from "./api/hooks/useUserBasicsV2";
import PersistLogin from "./auth/PersistLogin";
import RequireAdmin from "./auth/RequireAdmin";
import RequireAuth from "./auth/RequireAuth";
import RequireLicense from "./auth/RequireLicense";
import AlarmDetails from "./components/Dashboard/Alarms/AlarmDetails/AlarmDetails";
import AlarmModal from "./components/Dashboard/Alarms/AlarmModal/AlarmModal";
import Dashboard from "./components/Dashboard/Dashboard";
import Forbidden from "./components/ErrorPages/Forbidden";
import PageNotFound from "./components/ErrorPages/PageNotFound";
import Unauthorized from "./components/ErrorPages/Unauthorized";
import EventDetails from "./components/Events/EventDetails/EventDetails";
import Events from "./components/Events/Events";
import EventsFilter from "./components/Events/EventsFilter/EventsFilter";
import CreateFlowConfiguration from "./components/FdsSchedulesList/CreateFlowConfiguration/CreateFlowConfiguration";
import FDSSchedulesList from "./components/FdsSchedulesList/FdsSchedulesList";
import CmsFooter from "./components/Footer/CmsFooter";
import Header from "./components/Header/Header";
import ForgotPassword from "./components/LoginPge/ForgotPassword";
import LoginPage from "./components/LoginPge/LoginPage";
import PreferencesMenuPage from "./components/Preferences/PreferencesMenuPage";
import PropertyManagement from "./components/Preferences/PropertyManagement/Property/PropertyManagement";
import FloorManagementFloorList from "./components/Preferences/PropertyManagement/PropertyFloorManagement/Floor/FloorList";
import FloorManagementPropertyList from "./components/Preferences/PropertyManagement/PropertyFloorManagement/Property/FloorManagementPropertyList";
import PropertyManagementMenuPage from "./components/Preferences/PropertyManagement/PropertyManagementMenuPage";
import UsersList from "./components/Preferences/Users/UsersList";
import Profile from "./components/Profile/Profile";
import FloorList from "./components/PropertyOverview/FloorList/FloorList";
import FloorSystemDeviceDetails from "./components/PropertyOverview/FloorSystemDeviceDetails/FloorSystemDeviceDetails";
import FloorSystemsTableMobile from "./components/PropertyOverview/FloorSystemsTable/FloorSystemsTableMobile";
import PropertyList from "./components/PropertyOverview/PropertyList/PropertyList";
import CustomerReport from "./components/Reports/CustomerReport";
import ConsumptionReportsMenuPage from "./components/Reports/MenuPages/Main/ConsumptionReportsMenuPage";
import ReportsMenu from "./components/Reports/MenuPages/Main/Menu";
import PreferencesConsumptionReportsMenuPage from "./components/Reports/MenuPages/Preferences/ConsumptionReportsMenuPage";
import PreferencesReportsMenu from "./components/Reports/MenuPages/Preferences/Menu";
import StatusReport from "./components/Reports/StatusReport";
import WaterConsumptionReport from "./components/Reports/WaterConsumptionReport";
import CreateNewSchedule from "./components/Schedule/CreateNewSchedule/CreateNewSchedule";
import Schedule from "./components/Schedule/Schedule";
import ScheduleCalendar from "./components/Schedule/ScheduleCalendar/ScheduleCalendar";
import SettingsMenuPage from "./components/Settings/SettingsMenuPage";
import Sidebar from "./components/Sidebar/Sidebar";
import Charts from "./components/Statistics/Charts/Charts";
import { FdsFiltersValuesProvider } from "./context/FdsFiltersValuesProvider";
import { ScheduleSystemsProvider } from "./context/ScheduleSystemsProvider";
import useAuth from "./hooks/useAuth";
import useProfileSettings from "./hooks/useProfileSettings";
import useWindowSize from "./hooks/useWindowSize";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import "./App.scss";

function App() {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const { auth, setAuth } = useAuth();
  const { setUserSettings } = useProfileSettings();
  const { refetch: getLoggedUser } = useUser();
  const { refetch: getUserBasics } = useUserBasicsV2();

  const { i18n } = useTranslation();
  const window = useWindowSize();

  const { Sider, Content } = Layout;

  // get the logged user and add it to the auth context
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      getLoggedUser().then((res) => {
        setAuth((prevState) => ({ ...prevState, user: res.data }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // get the logged user's settings add them to the user settings context
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      getUserBasics().then((res) => {
        setUserSettings((prevState) => ({
          ...prevState,
          waterUnit: res.data.WaterUnit,
          temperatureUnut: res.data.TemperatureUnit,
          clockType: res.data.ClockType,
        }));
        i18n.changeLanguage(res.data.Language);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // remove scrollbar when the mobile nav is active
  useEffect(() => {
    if (isMobileNavActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [window, isMobileNavActive]);

  // app routing
  const routes = [
    {
      path: "/",
      element: (
        <PersistLogin>
          <RequireAuth>
            <>
              <Header
                withNavigation
                isMobileNavActive={isMobileNavActive}
                setIsMobileNavActive={setIsMobileNavActive}
              />

              <div className="flex min-h-0 h-full">
                {/* Desktop sidebar */}
                {window?.width > 992 && (
                  <Sider className="sidebar-wrapper">
                    <Sidebar className="sidebar" />
                  </Sider>
                )}

                {/* Mobile nav */}
                {window?.width <= 992 && isMobileNavActive && (
                  <div
                    role="none"
                    onClick={(e) =>
                      e.target.classList.contains("nav-link") &&
                      setIsMobileNavActive(false)
                    }
                    className="mobile-nav"
                  >
                    <Sidebar
                      className={`sidebar--mobile ${
                        window?.isLandscape ? "overflow-y-scroll" : ""
                      }`}
                    />
                  </div>
                )}

                {/* Main content */}
                <Content className="main-content">
                  {/* Outlet to display the router pages */}
                  <Outlet />
                </Content>
              </div>
              <CmsFooter />
            </>
          </RequireAuth>
        </PersistLogin>
      ),
      children: [
        {
          path: "",
          element: (
            <RequireLicense featureName="Dashboard Default View">
              <div className="content-wrapper no-scrollbar">
                <Dashboard />
                <AlarmModal />
                <Outlet />
              </div>
            </RequireLicense>
          ),
          children: [
            {
              path: "alarm/:alarmId",
              element: <AlarmDetails />,
            },
            {
              path: "event/:eventId",
              element: <EventDetails />,
            },
          ],
        },
        {
          path: "property-overview",
          children: [
            {
              path: "",
              element: (
                <RequireLicense featureName="Property Overview Default View">
                  <div className="content-wrapper overflow-hidden">
                    <PropertyList />
                  </div>
                </RequireLicense>
              ),
            },
            {
              path: "property/:propertyId",
              element: (
                <div className="content-wrapper overflow-hidden">
                  <FloorList />
                  <Outlet />
                </div>
              ),
              children: [
                {
                  // mobile view for floor systems
                  path: "floor/:floorId",
                  element: <FloorSystemsTableMobile />,
                },
                {
                  path: "floor/device-details/:deviceId",
                  element: <FloorSystemDeviceDetails />,
                },
                // mobile view for system device details
                {
                  path: "floor/:floorId/device-details/:deviceId",
                  element: <FloorSystemDeviceDetails />,
                },
              ],
            },
          ],
        },
        {
          path: "statistics",
          children: [
            {
              path: "",
              element: (
                <RequireLicense featureName="Graph View">
                  <div className="content-wrapper text-triple-white">
                    <Charts />
                  </div>
                </RequireLicense>
              ),
            },
          ],
        },
        {
          path: "reports",
          children: [
            {
              path: "",
              element: (
                <div className="content-wrapper text-triple-white">
                  <RequireLicense featureName="Generate Report">
                    <RequireAdmin>
                      <ReportsMenu />
                    </RequireAdmin>
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "consumption",
              children: [
                {
                  path: "",
                  element: (
                    <RequireLicense featureName="Generate Report">
                      <div className="content-wrapper text-triple-white">
                        <ConsumptionReportsMenuPage />
                      </div>
                    </RequireLicense>
                  ),
                },
                {
                  path: "water",
                  element: (
                    <RequireLicense featureName="Generate Report">
                      <div className="content-wrapper text-triple-white">
                        <WaterConsumptionReport />
                      </div>
                    </RequireLicense>
                  ),
                },
              ],
            },
            {
              path: "status",
              element: (
                <RequireLicense featureName="Generate Report">
                  <div className="content-wrapper text-triple-white">
                    <StatusReport />
                  </div>
                </RequireLicense>
              ),
            },
          ],
        },
        {
          path: "events",
          children: [
            {
              path: "",
              element: (
                <RequireLicense featureName="Events Default View">
                  <div className="content-wrapper no-scrollbar">
                    <Events />
                    <Outlet />
                  </div>
                </RequireLicense>
              ),
              children: [
                {
                  path: ":eventId",
                  element: <EventDetails />,
                },
                {
                  path: "filters",
                  element: <EventsFilter />,
                },
              ],
            },
          ],
        },
        {
          path: "",
          children: [
            {
              path: "settings",
              element: (
                <div className="content-wrapper text-triple-white">
                  <SettingsMenuPage />
                </div>
              ),
            },
            {
              path: "/settings/flow-configuration",
              element: (
                <div className="content-wrapper">
                  <RequireLicense featureName="Flow Configuration Default View">
                    <FDSSchedulesList />
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/settings/flow-configuration/create-flow-configuration",
              element: (
                <div className="content-wrapper overflow-hidden">
                  <RequireLicense featureName="Create New FC">
                    <FdsFiltersValuesProvider>
                      <CreateFlowConfiguration />
                    </FdsFiltersValuesProvider>
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/settings/schedule",
              element: (
                <div className="content-wrapper overflow-auto">
                  <RequireLicense featureName="Schedule Default View">
                    <Schedule />
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/settings/schedule/create-schedule",
              element: (
                <div className="content-wrapper">
                  <RequireLicense featureName="Create New Schedule">
                    <ScheduleSystemsProvider>
                      <CreateNewSchedule />
                    </ScheduleSystemsProvider>
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/settings/schedule/manage-schedule-calendar",
              element: (
                <div className="content-wrapper">
                  <RequireLicense featureName="Edit Schedule">
                    <ScheduleSystemsProvider>
                      <ScheduleCalendar />
                    </ScheduleSystemsProvider>
                  </RequireLicense>
                </div>
              ),
            },
          ],
        },
        {
          path: "preferences",
          children: [
            {
              path: "",
              element: (
                <div className="content-wrapper text-triple-white">
                  <RequireLicense featureName="Preferences Default View">
                    <RequireAdmin>
                      <PreferencesMenuPage />
                    </RequireAdmin>
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/preferences/property-management",
              element: (
                <div className="content-wrapper text-triple-white">
                  <RequireLicense featureName="Property Management Default View">
                    <RequireAdmin>
                      <PropertyManagementMenuPage />
                    </RequireAdmin>
                  </RequireLicense>
                </div>
              ),
            },
            {
              path: "/preferences/property-management/properties",
              element: (
                <RequireLicense featureName="Property Management Default View">
                  <RequireAdmin>
                    <PropertyManagement />
                  </RequireAdmin>
                </RequireLicense>
              ),
            },
            {
              path: "/preferences/property-management/floor-management/properties",
              element: (
                <RequireLicense featureName="Property Management Default View">
                  <RequireAdmin>
                    <FloorManagementPropertyList />
                  </RequireAdmin>
                </RequireLicense>
              ),
            },
            {
              path: "/preferences/property-management/floor-management/property/:propertyId",
              element: (
                <RequireLicense featureName="Property Management Default View">
                  <RequireAdmin>
                    <FloorManagementFloorList />
                  </RequireAdmin>
                </RequireLicense>
              ),
            },
            {
              path: "/preferences/users",
              element: (
                <RequireLicense featureName="Users List Default View">
                  <RequireAdmin>
                    <UsersList />
                  </RequireAdmin>
                </RequireLicense>
              ),
            },
            {
              path: "/preferences/reports",
              children: [
                {
                  path: "",
                  children: [
                    {
                      path: "",
                      element: (
                        <div className="content-wrapper text-triple-white">
                          <RequireLicense featureName="Generate Report">
                            <RequireAdmin>
                              <PreferencesReportsMenu />
                            </RequireAdmin>
                          </RequireLicense>
                        </div>
                      ),
                    },
                    {
                      path: "consumption",
                      children: [
                        {
                          path: "",
                          element: (
                            <RequireLicense featureName="Generate Report">
                              <div className="content-wrapper text-triple-white">
                                <PreferencesConsumptionReportsMenuPage />
                              </div>
                            </RequireLicense>
                          ),
                        },
                        {
                          path: "water",
                          element: (
                            <RequireLicense featureName="Generate Report">
                              <div className="content-wrapper text-triple-white">
                                <WaterConsumptionReport />
                              </div>
                            </RequireLicense>
                          ),
                        },
                        {
                          path: "customer",
                          element: (
                            <RequireLicense featureName="Generate Report">
                              <div className="content-wrapper text-triple-white">
                                <CustomerReport />
                              </div>
                            </RequireLicense>
                          ),
                        },
                      ],
                    },
                    {
                      path: "status",
                      element: (
                        <RequireLicense featureName="Generate Report">
                          <div className="content-wrapper text-triple-white">
                            <StatusReport />
                          </div>
                        </RequireLicense>
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <Profile />,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      element: (
        <>
          <Header />
          <LoginPage />
        </>
      ),
    },
    {
      path: "/forbidden",
      element: <Forbidden />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/reset-password",
      element: (
        <>
          <Header />
          <ForgotPassword />
        </>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const RoutesElement = useRoutes(routes);

  return (
    <ThemeConfigProvider>
      <div className="app">
        {RoutesElement}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </div>
    </ThemeConfigProvider>
  );
}

export default App;
