import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function HeimanCombustibleGasSensorIcon({ className }) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5372 7.08312C14.6481 7.58173 13.5317 7.24955 13.0437 6.34117C12.5556 5.4328 12.8808 4.29218 13.7698 3.79355C14.6589 3.29492 15.7753 3.6271 16.2633 4.53548C16.7514 5.44386 16.4262 6.58447 15.5372 7.08312ZM15.6057 10.9575C16.4729 11.4949 16.7495 12.6488 16.2236 13.5348C15.6976 14.4208 14.5682 14.7034 13.701 14.1661C12.8338 13.6287 12.5572 12.4748 13.0832 11.5888C13.6091 10.7028 14.7385 10.4202 15.6057 10.9575V10.9575ZM8.61521 13.5312C6.99452 13.5312 5.68065 12.1892 5.68065 10.5339C5.68065 8.87846 8.61521 4.46883 8.61521 4.46883C8.61521 4.46883 11.5499 8.87846 11.5499 10.5339C11.5499 12.1892 10.236 13.5312 8.61521 13.5312ZM8.57534 3.75215C7.5614 3.72978 6.75714 2.87178 6.77902 1.8358C6.80096 0.799794 7.6407 -0.0218604 8.65464 0.000443545C9.66865 0.022848 10.4729 0.880835 10.451 1.91679C10.4291 2.95282 9.58939 3.77453 8.57534 3.75215V3.75215ZM3.46071 14.2068C2.57161 14.7054 1.45528 14.3733 0.96723 13.4649C0.479167 12.5565 0.804316 11.4159 1.69342 10.9172C2.5825 10.4186 3.69885 10.7508 4.18691 11.6592C4.67489 12.5676 4.34979 13.7082 3.46071 14.2068V14.2068ZM1.6246 7.04239C0.757411 6.50497 0.480772 5.35113 1.00672 4.4651C1.53269 3.57904 2.66207 3.29643 3.52928 3.8338C4.39645 4.37122 4.67307 5.5251 4.14712 6.41109C3.62114 7.29712 2.49176 7.57973 1.6246 7.04239V7.04239ZM8.65464 14.2479C9.66865 14.2703 10.4729 15.1282 10.451 16.1642C10.4291 17.2003 9.58939 18.022 8.57534 17.9996C7.5614 17.9772 6.75714 17.1192 6.77902 16.0832C6.80096 15.0472 7.6407 14.2255 8.65464 14.2479Z"
        fill="white"
      />
    </svg>
  );
}

HeimanCombustibleGasSensorIcon.propTypes = {
  className: PropTypes.string,
};

HeimanCombustibleGasSensorIcon.defaultProps = {
  className: "",
};
