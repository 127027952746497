import { FrownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import "./ErrorPages.scss";

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="text-center">
        <FrownOutlined className="page-icon" />
        <h3 className="page-title">We have bad news</h3>
        <h2 className="page-subtitle">
          The page you are looking for does not exist or has been deleted. Or
          maybe... just maybe is temporarily unavailable because we broke
          something.
        </h2>
        <Button size="large" className="mt-10" onClick={() => navigate("/")}>
          Go back
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;
