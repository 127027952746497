import { createContext, useState, useMemo } from "react";

import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

export const defaultScheduleSystems = {
  scheduleName: "",
  selectedPropertyId: null,
  selectedProperty: null,
  selectedFloorId: null,
  selectedFloor: null,
  selectedDeviceType: null,
  properties: [],
  systemsWithSelectedDevice: [],
  selectedSystemsWithSelectedDevice: [],
  confirmedSystemsWithSelectedDevice: [],
  selectedDevicesUniqueIds: [],
  scheduleIntervals: [],
  checkedSystemsKeys: [],
  checkedDevicesKeys: [],
};

const ScheduleSystemsContext = createContext(defaultScheduleSystems);

export function ScheduleSystemsProvider({ children }) {
  const [scheduleSystems, setScheduleSystems] = useState(
    defaultScheduleSystems,
  );

  const value = useMemo(() => {
    return { scheduleSystems, setScheduleSystems };
  }, [scheduleSystems]);

  return (
    <ScheduleSystemsContext.Provider value={value}>
      {children}
    </ScheduleSystemsContext.Provider>
  );
}

ScheduleSystemsProvider.propTypes = propTypes;
ScheduleSystemsProvider.defaultProps = defaultProps;

export default ScheduleSystemsContext;
