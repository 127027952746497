import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";
import dayjs from "dayjs";

import { ConvertWater } from "../../../../helpers/waterHelper";

function GetWaterConsumption(
  fetchData,
  year,
  statisticsFilters,
  setLoading,
  setWaterConsumptionData,
  userSettings,
) {
  fetchData(year, statisticsFilters)
    .then((response) => {
      const { waterUnit } = userSettings;

      let responseDataArray = [];
      if (response && response.data && Array.isArray(response.data)) {
        responseDataArray = response.data;
      } else {
        const emptyPreviousYearData = {
          Year: response.data.Year - 1,
          TotalWaterConsumption: 0,
          StatisticsData: [],
        };
        const thisYearData = response.data;
        responseDataArray = [thisYearData, emptyPreviousYearData];
      }

      const mappedData = responseDataArray.map((yearDetails) => ({
        Year: yearDetails.Year,
        TotalWaterConsumption: ConvertWater(
          waterUnit,
          yearDetails.TotalWaterConsumption,
        ).toFixed(2),
        StatisticsData: yearDetails.StatisticsData.map((s) => ({
          Month: s.Month,
          MonthAsInt: s.MonthAsInt,
          WaterConsumption: ConvertWater(waterUnit, s.WaterConsumption).toFixed(
            2,
          ),
        })),
      }));

      setWaterConsumptionData(mappedData);
    })
    .finally(() => setLoading(false));
}

function GetMonthlyWaterConsumption(
  fetchData,
  year,
  selectedMonth,
  statisticsFilters,
  setMonthlyData,
  userSettings,
  setRenderMonthlyChart,
) {
  try {
    fetchData(year, selectedMonth?.MonthAsInt, {
      propertyId: statisticsFilters.propertyId,
      floorId: statisticsFilters.floorId,
      systemId: statisticsFilters.systemId,
    }).then((response) => {
      if (response.status === "error") {
        setMonthlyData(null);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong
            </span>
          ),
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      }

      if (response.status === "success") {
        const { waterUnit } = userSettings;
        let responseDataArray = [];
        if (response && response.data && Array.isArray(response.data)) {
          responseDataArray = response.data;
        } else {
          const emptyPreviousYearData = {
            Year: response.data.Year - 1,
            Month: response.data.Month,
            TotalWaterConsumption: 0,
            StatisticsData: [],
          };
          const thisYearData = response.data;
          responseDataArray = [thisYearData, emptyPreviousYearData];
        }
        const mappedData = responseDataArray.map((monthDetails) => ({
          Year: monthDetails.Year,
          Month: monthDetails.Month,
          TotalWaterConsumption: ConvertWater(
            waterUnit,
            monthDetails.TotalWaterConsumption,
          ).toFixed(2),
          StatisticsData: monthDetails.StatisticsData.map((v) => ({
            Day: dayjs(v.Date).date(),
            WaterConsumption: ConvertWater(
              waterUnit,
              v.WaterConsumption,
            ).toFixed(2),
          })),
        }));

        setMonthlyData(mappedData);
        setRenderMonthlyChart(true);
      }
    });
  } catch (ex) {
    notification.error({
      message: <span className="uppercase tracking-widest">{ex.message}</span>,
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  }
}

function GetDailyWaterConsumption(
  fetchData,
  year,
  selectedMonth,
  selectedDay,
  statisticsFilters,
  setDailyData,
  userSettings,
  setRenderDailyChart,
) {
  try {
    fetchData(year, selectedMonth?.MonthAsInt, selectedDay?.Day, {
      propertyId: statisticsFilters.propertyId,
      floorId: statisticsFilters.floorId,
      systemId: statisticsFilters.systemId,
    }).then((response) => {
      if (response.status === "error") {
        setDailyData(null);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong
            </span>
          ),
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      }

      if (response.status === "success") {
        const { waterUnit } = userSettings;
        let responseDataArray = [];
        if (response && response.data && Array.isArray(response.data)) {
          responseDataArray = response.data;
        } else {
          const emptyPreviousYearData = {
            Year: response.data.Year - 1,
            Month: response.data.Month,
            Day: response.data.Day,
            TotalWaterConsumption: 0,
            StatisticsData: [],
          };
          const thisYearData = response.data;
          responseDataArray = [thisYearData, emptyPreviousYearData];
        }
        const mappedData = responseDataArray.map((dayDetails) => ({
          Year: dayDetails.Year,
          Month: dayDetails.Month,
          Day: dayDetails.Day,
          TotalWaterConsumption: ConvertWater(
            waterUnit,
            dayDetails.TotalWaterConsumption,
          ).toFixed(2),
          StatisticsData: dayDetails.StatisticsData.map((v) => ({
            Hour: v.Hour,
            WaterConsumption: ConvertWater(
              waterUnit,
              v.WaterConsumption,
            ).toFixed(2),
          })),
        }));

        setDailyData(mappedData);
        setRenderDailyChart(true);
      }
    });
  } catch (ex) {
    notification.error({
      message: <span className="uppercase tracking-widest">{ex.message}</span>,
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  }
}

export {
  GetWaterConsumption,
  GetMonthlyWaterConsumption,
  GetDailyWaterConsumption,
};
