import { createContext, useContext, useState, useMemo } from "react";

import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

const FdsFiltersValuesContext = createContext();

export function FdsFiltersValuesProvider({ children }) {
  const [deviceSerialNumberOptions, setDeviceSerialNumberOptions] = useState(
    [],
  );
  const [serviceUniqueIdOptions, setServiceUniqueIdNewOptions] = useState([]);

  const updateSerialNumberOptions = (deviceSerialNumberNewOptions) => {
    setDeviceSerialNumberOptions(deviceSerialNumberNewOptions);
  };

  const updateAvailableServiceUniqueIdOptions = (serviceUniqueIdNewOptions) => {
    setServiceUniqueIdNewOptions(serviceUniqueIdNewOptions);
  };

  const contextValue = useMemo(() => {
    return {
      availableDeviceSerialNumberOptions: deviceSerialNumberOptions,
      updateAvailableSerialNumberOptions: updateSerialNumberOptions,
      availableServiceUniqueIdOptions: serviceUniqueIdOptions,
      updateAvailableServiceUniqueIdOptions,
    };
  }, [deviceSerialNumberOptions, serviceUniqueIdOptions]);

  return (
    <FdsFiltersValuesContext.Provider value={contextValue}>
      {children}
    </FdsFiltersValuesContext.Provider>
  );
}

FdsFiltersValuesProvider.propTypes = propTypes;
FdsFiltersValuesProvider.defaultProps = defaultProps;

export const useAvailableDeviceSerialNumberOptionsContext = () =>
  useContext(FdsFiltersValuesContext);
