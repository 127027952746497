import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyPreferences(
  propertyId,
  options,
  key = "getPropertyPreferences",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyPreferences = async () => {
    const propertyPreferences = await axiosPrivate({
      method: "GET",
      url: `Property/${propertyId}/Preferences`,
    }).then((res) => res.data);
    return propertyPreferences;
  };

  return useQuery([key, propertyId], () => getPropertyPreferences(propertyId), {
    ...options,
  });
}

export default usePropertyPreferences;
