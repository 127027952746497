import buildEventTitle from "./buildEventTitle";

const formatEventsForExport = (array) => {
  return array?.map((event) => {
    const eventTitle = buildEventTitle(event.EventTitle);
    const {
      Id,
      State,
      RecordType,
      PrevAttributes,
      Attributes,
      ...dataToExport
    } = event.LogsInfo;

    return {
      ...dataToExport,
      Event: eventTitle,
    };
  });
};

export default formatEventsForExport;
