import { ConfigProvider, theme } from "antd";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = {
  children: null,
};

function ThemeConfigProvider({ children }) {
  const tripleYellow = "#06a5d3";
  const tripleSecondaryBlue = "#393D50";
  const colorTripleHeader = "#363346";
  const colorTripleMainBlue = "#303343";
  const colorTripleTertiaryBlue = "#2F3342";
  const colorTripleBackground = "#252331";
  const colorWhite = "#E1E0E3";
  const noColor = "transparent";
  const colorSelectBg = "#454961";

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.lightAlgorithm,
        token: {
          fontFamily: "Rubik",
          colorPrimary: tripleYellow,
          borderRadius: 4,
          colorTextBase: colorWhite,
          colorTextHeading: colorWhite,
          colorTextQuaternary: colorWhite,
          colorText: colorWhite,
          colorLinkActive: tripleYellow,
          boxShadow: "none",
        },
        components: {
          Button: {
            colorTextLightSolid: colorWhite,
            colorBgContainer: noColor,
            colorBgContainerDisabled: tripleSecondaryBlue,
            colorBorder: tripleSecondaryBlue,
            controlHeight: "40px",
            controlOutline: "none",
            colorPrimaryBorder: tripleSecondaryBlue,
          },
          Card: {
            colorBorderSecondary: noColor,
            colorBgContainer: colorTripleMainBlue,
          },
          Checkbox: {
            colorBgContainer: colorWhite,
            colorBorder: noColor,
          },
          DatePicker: {
            colorBgContainer: colorSelectBg,
            colorBgElevated: colorTripleTertiaryBlue,
            colorBgContainerDisabled: "#373c4e",
            colorBorder: noColor,
            colorBorderSecondary: noColor,
            boxShadowSecondary: "none",
            colorLink: tripleYellow,
            colorLinkHover: tripleYellow,
            colorLinkActive: tripleYellow,
            controlItemBgActive: tripleYellow,
            fontSizeLG: "14px",
          },
          Descriptions: {
            colorText: colorWhite,
          },
          Drawer: {
            colorText: colorWhite,
            colorTextBase: colorWhite,
            colorBgElevated: colorTripleTertiaryBlue,
          },
          Dropdown: {
            colorBgElevated: colorTripleHeader,
            boxShadowSecondary: "none",
          },
          Input: {
            colorBgContainer: tripleSecondaryBlue,
            colorBgElevated: tripleSecondaryBlue,
            colorBorder: noColor,
          },
          Menu: {
            colorItemBgSelected: noColor,
            colorItemBgHover: colorTripleHeader,
            colorSubItemBg: noColor,
            colorItemTextSelected: colorWhite,
            colorItemTextHover: colorWhite,
          },
          Modal: { colorBgElevated: colorTripleBackground },
          Pagination: {
            colorBgContainer: noColor,
            colorBorder: noColor,
            colorPrimaryBorder: noColor,
          },
          Select: {
            colorBgContainer: colorSelectBg,
            colorBgElevated: colorSelectBg,
            colorBorder: noColor,
            controlItemBgActive: "#373c4e",
            fontSizeLG: "14px",
            colorBgContainerDisabled: "#373c4e",
            fontWeightStrong: 400,
          },
          Table: {
            colorBorder: "#C4C4C4",
            colorBgContainer: colorTripleMainBlue,
            controlHeight: "20px",
            fontWeightStrong: "500",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

ThemeConfigProvider.propTypes = propTypes;
ThemeConfigProvider.defaultProps = defaultProps;

export default ThemeConfigProvider;
