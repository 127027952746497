import { Select, ConfigProvider } from "antd";
import PropTypes from "prop-types";

import Loader from "../../Loader/Loader";

const propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

const defaultProps = {
  children: null,
  disabled: false,
  options: [],
  placeholder: "",
  status: "",
  value: "",
  onChange: () => {},
  onClear: () => {},
};

function FilterSelect({
  children,
  disabled,
  options,
  placeholder,
  status,
  value,
  onChange,
  onClear,
}) {
  const customizeRenderEmpty = () => (
    <div className="w-full h-14 flex items-center justify-center">
      {status === "loading" ? <Loader /> : <span>Nothing to display</span>}
    </div>
  );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Select
        allowClear
        className="events-filter-select"
        disabled={disabled}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        showSearch
        size="large"
        options={options}
        placeholder={placeholder}
        value={value}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={onChange}
        onClear={onClear}
      >
        {children}
      </Select>
    </ConfigProvider>
  );
}

FilterSelect.propTypes = propTypes;
FilterSelect.defaultProps = defaultProps;

export default FilterSelect;
