import { useState, useEffect, useCallback } from "react";

import { Card, Col, Row } from "antd";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";

import useStatisticsAnnualWaterConsumption from "../../../api/hooks/useStatisticsAnnualWaterConsumption";
import useStatisticsBiennialWaterConsumption from "../../../api/hooks/useStatisticsBiennialWaterConsumption";
import DateConstants from "../../../constants/DateConstants";
import useProfileSettings from "../../../hooks/useProfileSettings";
import Loader from "../../Loader/Loader";
import StatisticsCalendar from "../Calendar/StatisticsCalendar";
import StatisticsConsumptionCards from "../ConsumptionCards/StatisticsConsumptionCards";
import StatisticsFilters from "../Filters/StatisticsFilters";
import StatisticsPropertyOverview from "../PropertyOverview/StatisticsPropertyOverview";
import { GetWaterConsumption } from "./services/WaterConsumptionService";
import StatisticsChart from "./StatisticsCharts";

import "./Charts.scss";

function Charts() {
  const dateTimeNow = {
    MM: DateConstants.months[dayjs().$M],
    m: dayjs().$M,
    year: dayjs().$y,
    d: dayjs().$D,
  };

  // States
  const [property, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [date, setDate] = useState(dateTimeNow);
  const [loading, setLoading] = useState(false);
  const [
    showPreviousWaterConsumptionData,
    setShowPreviousWaterConsumptionData,
  ] = useState(false);
  const [waterConsumptionData, setWaterConsumptionData] = useState();

  // Callbacks
  const handleOnChangeFilterCallback = (property, floorId, systemId) => {
    setPropertyId(property);
    setFloorId(floorId);
    setSystemId(systemId);
  };

  const handleTogglePreviousWaterConsumptionData = useCallback(
    () => setShowPreviousWaterConsumptionData((previousShow) => !previousShow),
    [],
  );

  // Fetching water consumption data
  const annualWaterConsumption = useStatisticsAnnualWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId: property?.PropertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const biennialWaterConsumption = useStatisticsBiennialWaterConsumption(
    date && date.year ? date.year : new Date().getFullYear(),
    {
      propertyId: property?.PropertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const { userSettings } = useProfileSettings();

  useEffect(() => {
    if (date) {
      setLoading(true);
      const fetchData = showPreviousWaterConsumptionData
        ? biennialWaterConsumption.refetch
        : annualWaterConsumption.refetch;
      const yearParam = date.year - 1;
      const statisticsFilters = {
        propertyId: property?.PropertyId,
        floorId,
        systemId,
      };
      GetWaterConsumption(
        fetchData,
        yearParam,
        statisticsFilters,
        setLoading,
        setWaterConsumptionData,
        userSettings,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    property,
    floorId,
    systemId,
    date.year,
    annualWaterConsumption.refetch,
    biennialWaterConsumption.refetch,
    userSettings,
    showPreviousWaterConsumptionData,
  ]);

  return (
    <div className="statistics">
      <Row>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsPropertyOverview propertyOverview={property} />
        </Col>
      </Row>

      <Card
        className="rounded-2xl triple-plus-content"
        bodyStyle={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
      >
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
            <StatisticsFilters
              handleOnChangeFilterCallback={handleOnChangeFilterCallback}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={15}
            xl={17}
            xxl={18}
            className="content-end"
          >
            <StatisticsConsumptionCards
              waterConsumptionTotal={
                waterConsumptionData?.length > 0 && waterConsumptionData[0]
                  ? waterConsumptionData[0].TotalWaterConsumption
                  : 0
              }
              showPreviousWaterConsumptionData={
                showPreviousWaterConsumptionData
              }
              loading={loading}
              onToggle={handleTogglePreviousWaterConsumptionData}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
            <StatisticsCalendar
              setDate={setDate}
              date={date}
              property={property}
              floor={floorId}
              systemId={systemId}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={18}>
            {(biennialWaterConsumption.status === "success" ||
              annualWaterConsumption.status === "success") && (
              <StatisticsChart
                statisticsFilters={{
                  propertyId: property?.PropertyId,
                  floorId,
                  systemId,
                }}
                waterConsumptionData={
                  waterConsumptionData && waterConsumptionData[0]
                    ? waterConsumptionData[0]
                    : {}
                }
                previousWaterConsumptionData={
                  waterConsumptionData && waterConsumptionData[1]
                    ? waterConsumptionData[1]
                    : {}
                }
                showPreviousWaterConsumptionData={
                  showPreviousWaterConsumptionData
                }
                setDate={setDate}
                date={date}
              />
            )}
            {biennialWaterConsumption.status === "loading" &&
              annualWaterConsumption.status === "loading" && (
                <div className="flex justify-center mt-10">
                  <Loader />
                </div>
              )}
          </Col>
        </Row>
      </Card>

      <Outlet />
    </div>
  );
}

export default Charts;
