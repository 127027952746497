import { useEffect, useRef, useState } from "react";

import { Button, Form, Input } from "antd";
import PropTypes from "prop-types";

import useLoggedUser from "../../../../api/hooks/customHooks/useGetLoggedUser";
import useGetServiceProvider from "../../../../api/hooks/customHooks/useGetServiceProvider";
import useAttachments from "../../../../api/hooks/useAttachments";
import usePropertyCreate from "../../../../api/hooks/usePropertyCreate";
import usePropertyFloorCreateMultiple from "../../../../api/hooks/usePropertyFloorCreateMultiple";
import { useUpdatePropertyBasicsv2 } from "../../../../api/hooks/useUpdatePropertyPreferences";
import ImageUploader from "../../../ImageUploader/ImageUploader";
import "./scss/CreateNewProperty.scss";
import Toggle from "../../../Shared/Toggle";
import CreateNewPropertyFloors from "./CreateNewPropertyFloors";
import { useHandleCreate } from "./hooks/usePropertyHooks";
import PropertyPreferencesWorkingDays from "./PropertyPreferencesWorkingDays";
import PropertyServiceProvider from "./PropertyServiceProvider";

function CreateNewProperty({
  setIsFormDirty,
  setCreateFormRef,
  setIsCreateNewPropertyOpen,
  refetchPropertyBasics,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges] = useState(false);
  const [showWorkingDaysRoutine, setShowWorkingDaysRoutine] = useState();
  const [fileListArr, setFileListArr] = useState([]);
  const [createNewPropertyForm] = Form.useForm();
  const createNewPropertyFormRef = useRef(null);

  // Mutation Fn
  const createPropertyMutation = usePropertyCreate();
  const createPropertyFloorsMutation = usePropertyFloorCreateMultiple();
  const uploadAttachmentMutation = useAttachments();
  const updatePropertyPreferencesMutation = useUpdatePropertyBasicsv2();

  const { user } = useLoggedUser();
  const { serviceProvider } = useGetServiceProvider(user?.Claims[0].Value);

  useEffect(() => {
    setCreateFormRef(createNewPropertyForm);
  }, [createNewPropertyForm, setCreateFormRef]);

  const handleFieldsChange = (changedFields) => {
    const fieldName = changedFields[0].name[0];
    const fieldValue = changedFields[0].value;

    if (fieldName === "WorkingDaysEnabled") {
      if (fieldValue === true) {
        setShowWorkingDaysRoutine(true);
      } else {
        setShowWorkingDaysRoutine(false);
      }
    }
  };

  const handleValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const HandleCreate = async (formValues) => {
    const response = await useHandleCreate(
      formValues,
      serviceProvider.Id,
      setIsLoading,
      setIsFormDirty,
      createPropertyMutation,
      updatePropertyPreferencesMutation,
      createPropertyFloorsMutation,
      uploadAttachmentMutation,
    );
    return response;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formValues = createNewPropertyForm.getFieldsValue();
    await HandleCreate(formValues);
    setIsCreateNewPropertyOpen(false);
    refetchPropertyBasics();
  };

  const handleSubmitFailed = (errorInfo) => {
    if (createNewPropertyFormRef.current) {
      const { errorFields } = errorInfo;
      if (errorFields.length > 0) {
        const firstErrorField = errorFields[0].name;
        createNewPropertyFormRef.current.scrollToField(firstErrorField);
      }
    }
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const onImageChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const request = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess("ok");
      }
    }, 1000);
  };

  return (
    <div className="content-wrapper mx-2">
      <Form
        form={createNewPropertyForm}
        ref={createNewPropertyFormRef}
        name="create-new-property-form"
        layout="vertical"
        className="w-full"
        onFieldsChange={handleFieldsChange}
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        initialValues={{
          ImageUrl: null,
          Address: null,
          WorkingDaysEnabled: false,
          AddPropertyFloors: [],
        }}
      >
        <div className="flex items-center justify-center mb-0">
          <h1 className="page-title text-center">Create New Property</h1>
        </div>
        <>
          <Form.Item name="ImageUrl">
            <ImageUploader
              fileListArr={fileListArr}
              onRemoveImg={onRemoveImg}
              onChange={onImageChange}
              request={request}
              autoUpload={false}
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Name"
            name="Name"
            rules={[{ required: true, message: "Name field is required." }]}
          >
            <Input size="middle" maxLength={64} />
          </Form.Item>
          <Form.Item className="mb-4" label="Address" name="Address">
            <Input size="middle" maxLength={128} />
          </Form.Item>
          <PropertyServiceProvider />
          <Toggle
            className="mb-4"
            size="medium"
            fieldLabel="Working Days Routine"
            fieldName="WorkingDaysEnabled"
            fieldValues={[
              {
                value: false,
                label: "Disable",
              },
              {
                value: true,
                label: "Enable",
              },
            ]}
          />
          {showWorkingDaysRoutine === true ? (
            <PropertyPreferencesWorkingDays />
          ) : null}
          <CreateNewPropertyFloors
            createNewPropertyFormRef={createNewPropertyForm}
          />
          <Button
            type="primary"
            htmlType="submit"
            size="medium"
            className="custom-property-save-btn"
            loading={isLoading}
            disabled={disableSaveChanges}
          >
            Save
          </Button>
        </>
      </Form>
    </div>
  );
}

CreateNewProperty.propTypes = {
  setIsFormDirty: PropTypes.func,
  setCreateFormRef: PropTypes.func,
  setIsCreateNewPropertyOpen: PropTypes.func,
  refetchPropertyBasics: PropTypes.func,
};

CreateNewProperty.defaultProps = {
  setIsFormDirty: () => {},
  setCreateFormRef: () => {},
  setIsCreateNewPropertyOpen: () => {},
  refetchPropertyBasics: () => {},
};

export default CreateNewProperty;
