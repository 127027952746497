import { useState, useEffect } from "react";

import { Row, Divider, Col } from "antd";
import PropTypes from "prop-types";

import useCustomerReport from "../../../api/hooks/useCustomerReport";
import logo from "../../../assets/img/property-overview-default-pic.png";
import { getMonthName } from "../../../helpers/dates";

function Body({
  property,
  device,
  totalConsumption,
  waterCharges,
  administrationFees,
  totalCharges,
  waterUOM,
  previousMonthDate,
}) {
  const lastMonth = getMonthName(new Date().getMonth() - 1);
  const firstDateOfCurrentMonth = `01/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`;
  const currentDate = `${new Date().getDate()}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`;

  const [previousConsumption, setPreviousConsumption] = useState(null);

  const { refetch: getCustomerReport } = useCustomerReport(
    {
      filtersWithDeviceId: {
        deviceId: device?.Id,
        propertyId: property?.Id,
        floorId: null,
        systemId: null,
      },
      date: new Date(previousMonthDate),
      costOfWaterPerUOM: 1,
      waterUOM,
      administrationFees,
    },
    { enabled: false, cacheTime: 0 },
  );

  useEffect(() => {
    if (device?.Id && property?.Id) {
      getCustomerReport().then((response) => {
        if (response && response.isSuccess) {
          setPreviousConsumption(
            response.data.TotalConsumption
              ? response.data.TotalConsumption.toString()
              : "0",
          );
        } else {
          setPreviousConsumption("");
        }
      });
    }
  }, [device, getCustomerReport, previousMonthDate, property]);

  return (
    <Row className="w-full justify-start items-start content-start bg-triple-white rounded-md p-3 text-triple-background">
      <Row className="gap-3">
        <Col>
          <div
            className={`property-image rounded ${
              property?.ImageUrl ? "h-20 w-48" : "h-20 w-48"
            } ${window?.width < 991 ? "property-image--blured-bottom" : ""}`}
            style={{
              backgroundImage: `url(${
                property?.ImageUrl ? property?.ImageUrl : logo
              })`,
              backgroundSize: property?.ImageUrl ? "cover" : "contain",
            }}
          >
            &nbsp;
          </div>
        </Col>
        <Col>
          <Row className="">{property?.Name}</Row>
          <Row className="xxxl:text-lg gap-1">
            {property?.Address ?? "Property address is not available"}
          </Row>
        </Col>
        <Col className="xxxl:text-lg font-medium right-5 absolute content-start">
          Water Charges
        </Col>
      </Row>
      <Divider className="my-2 border-2 opacity-25" />
      <Row className="gap-1">
        <Row className="gap-1 w-full">
          <Row className="font-medium uppercase">
            Previous monthly consumption{" "}
          </Row>
          (Calculated using {lastMonth} bills): {previousConsumption}
        </Row>
        <Row className="gap-1 w-full">
          <Row className="font-medium uppercase">Current billing period: </Row>
          {firstDateOfCurrentMonth} - {currentDate}
        </Row>
      </Row>
      <Divider className="my-2 border-2 opacity-25" />
      <Row className="gap-4">
        <Col>
          <Row>
            <Row className="uppercase font-medium mr-1">Meter ID</Row>
            (Device Serial Number)
          </Row>
          <Row>{device?.SerialNumber}</Row>
        </Col>
        <Col>
          <Row className="uppercase font-medium">Current read consumption</Row>
          <Row>{totalConsumption}</Row>
        </Col>
      </Row>
      <Divider className="my-2 border-2 opacity-25" />
      <Row>
        <Col className="font-medium">Water charges</Col>
        <Col className="font-medium uppercase right-5 absolute">
          {waterCharges}
        </Col>
      </Row>
      <Divider className="my-2 border-2 opacity-25" />
      <Row>
        <Col className="font-medium">Administration fees</Col>
        <Col className="font-medium uppercase right-5 absolute">
          {administrationFees}
        </Col>
      </Row>
      <Divider className="my-2 border-2 opacity-25" />
      <Row>
        <Col className="font-medium">Total charges</Col>
        <Col className="text-triple-red font-medium uppercase right-5 absolute">
          {totalCharges}
        </Col>
      </Row>
    </Row>
  );
}

Body.defaultProps = {
  property: {},
  device: {},
  totalConsumption: "",
  waterCharges: 0,
  administrationFees: 0,
  totalCharges: 0,
  waterUOM: "Litres",
  previousMonthDate: new Date(),
};
Body.propTypes = {
  property: PropTypes.object,
  device: PropTypes.object,
  totalConsumption: PropTypes.string,
  waterCharges: PropTypes.number,
  administrationFees: PropTypes.number,
  totalCharges: PropTypes.number,
  waterUOM: PropTypes.string,
  previousMonthDate: PropTypes.any,
};

export default Body;
