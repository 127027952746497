import { Card } from "antd";

import WaterDropIcon from "../../../../assets/icons/WaterDropIcon";
import useWindowSize from "../../../../hooks/useWindowSize";
import MenuTile from "../../../Shared/MenuTile";

function ReportsMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 mt-0 text-center sm:text-left !text-triple-blue">
          Consumption Reports
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
          Consumption Reports
        </h1>
      )}
      <Card className="triple-plus-content mt-4 rounded-2xl">
        <div className="flex md:flex-row flex-col md:items-start items-center gap-5">
          <MenuTile
            title="Consumption Report"
            icon={
              <WaterDropIcon
                className="w-12 h-12 xxxl:w-20 xxxl:h-20 align-sub"
                strokeColor="#757782"
              />
            }
            navigateTo="/preferences/reports/consumption/water"
          />
          <MenuTile
            title="Customer Report"
            icon={
              <WaterDropIcon
                className="w-12 h-12 xxxl:w-20 xxxl:h-20 align-sub"
                strokeColor="#757782"
              />
            }
            navigateTo="/preferences/reports/consumption/customer"
          />
        </div>
      </Card>
    </>
  );
}

export default ReportsMenuPage;
