function ReportsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 503.6 478.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          className="blue-fill-color"
          fill="#ffffff"
          d="M336.2,112V52.5H106.6v373.4h290.5V112H336.2z M368,396.8H135.6V81.5h171.5V141H368V396.8z"
        />
        <path
          className="blue-fill-color"
          fill="#ffffff"
          d="M190.5,220.1H265c8,0,14.5-6.5,14.5-14.5c0-8-6.5-14.5-14.5-14.5h-74.6c-8,0-14.5,6.5-14.5,14.5    C176,213.6,182.5,220.1,190.5,220.1z"
        />
        <path
          className="blue-fill-color"
          fill="#ffffff"
          d="M309.3,255.3H190.5c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5h118.8c8,0,14.5-6.5,14.5-14.5    C323.8,261.8,317.3,255.3,309.3,255.3z"
        />
        <path
          className="blue-fill-color"
          fill="#ffffff"
          d="M309.3,318.5H190.5c-8,0-14.5,6.5-14.5,14.5c0,8,6.5,14.5,14.5,14.5h118.8c8,0,14.5-6.5,14.5-14.5    C323.8,325,317.3,318.5,309.3,318.5z"
        />
        <circle
          fill="#ffffff"
          cx="309.3"
          cy="205.6"
          r="14.5"
          className="blue-fill-color"
        />
      </g>
    </svg>
  );
}
export default ReportsIcon;
