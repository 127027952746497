import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useEventFilters = () => {
  const axiosPrivate = useAxiosPrivate();

  const getEventFilters = async () => {
    const eventFilters = await axiosPrivate({
      method: "GET",
      url: "/Logs/GetLogsAvailableFilter",
    }).then((res) => res.data);
    return eventFilters;
  };

  return useQuery(["getEventFilters"], getEventFilters);
};

export default useEventFilters;
