import { useState } from "react";

import { Row, Col, Divider, Input } from "antd";

import ImageUploader from "../../ImageUploader/ImageUploader";

import "./Footer.scss";

function Footer() {
  const [fileListArr, setFileListArr] = useState([]);

  const onImageChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const imageRequest = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess("ok");
      }
    }, 1000);
  };

  return (
    <Row className="w-full justify-between items-center content-center text-triple-header report-customer-footer">
      <Col span={24} className="bg-triple-white rounded-md">
        <Row className="gap-6 p-3 w-full justify-between">
          <Row className="gap-6">
            <Col className="h-24">
              <ImageUploader
                fileListArr={fileListArr}
                onRemoveImg={onRemoveImg}
                onChange={onImageChange}
                request={imageRequest}
                autoUpload={false}
              />
            </Col>
            <Col>
              <Row className="uppercase">Contact data</Row>
              <Divider className="my-2 border-2 opacity-25" />
              <Row>
                <Row className="mt-1 mr-1">Email:</Row>
                <Row>
                  <Input variant="borderless" size="small" className="w-fit" />
                </Row>
              </Row>
              <Row>
                <Row className="mt-1 mr-1">Address:</Row>
                <Row>
                  <Input variant="borderless" size="small" className="w-fit" />
                </Row>
              </Row>
            </Col>
          </Row>
          <Col className="flex justify-between w-full gap-4 xxxl:flex-col xxxl:justify-start xxxl:gap-0 xxxl:w-fit">
            <Col className="mb-2 flex xxxl:flex-col">
              <Row className="mt-1 xxxl:mr-1 uppercase w-fit xxxl:text-base text-xs">
                Contact name:
              </Row>
              <Input
                variant="borderless"
                size="small"
                className="xxxl:w-fit w-full"
              />
            </Col>
            <Col className="mb-2 flex xxxl:flex-col">
              <Row className="mt-1 xxxl:mr-1 uppercase w-fit xxxl:text-base text-xs">
                Phone number:
              </Row>
              <Input
                variant="borderless"
                size="small"
                className="xxxl:w-fit w-full"
              />
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
