/**
 * Function to format numbers with spacing
 * @param value Value to format
 * @returns Formatted value; output 1 000 or 1 000 000
 */
const format = (value) => {
  // useGrouping true with en-US locale which is a comma for 'en-US'
  return new Intl.NumberFormat("en-US", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
    .format(value)
    .replace(/,/g, " "); // replace all commas (',') with space (' ')
};

export default format;
