import { useState } from "react";

import { LeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const propTypes = {
  redirect: PropTypes.object,
  confirmation: PropTypes.bool,
  className: PropTypes.string,
  confirmationMessage: PropTypes.string,
  confirmationTitleMessage: PropTypes.string,
};

const defaultProps = {
  redirect: {
    to: "",
  },
  confirmation: false,
  className: "mb-5 self-start uppercase tracking-widest",
  confirmationMessage: "",
  confirmationTitleMessage: "",
};

function BackButton({
  redirect,
  confirmation,
  className,
  confirmationMessage,
  confirmationTitleMessage,
}) {
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleLeave = () => {
    navigate(redirect.to);
  };

  if (confirmation) {
    return (
      <>
        <Button
          className={className}
          type="link"
          onClick={() => {
            setOpenConfirmModal(true);
          }}
        >
          <LeftOutlined />
          Back
        </Button>
        <Modal
          open={openConfirmModal}
          title={confirmationTitleMessage}
          onOk={handleLeave}
          onCancel={handleCancel}
          footer={[
            <Button key="back" size="large" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="large"
              onClick={handleLeave}
            >
              Leave
            </Button>,
          ]}
        >
          <p>{confirmationMessage}</p>
        </Modal>
      </>
    );
  }

  return (
    <Button
      className={className}
      type="link"
      onClick={() => {
        navigate(redirect.to);
      }}
    >
      <LeftOutlined />
      Back
    </Button>
  );
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;
