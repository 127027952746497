import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import useStatisticsBiennialDailyWaterConsumption from "../../../api/hooks/useStatisticsBiennialDailyWaterConsumption";
import useStatisticsBiennialMonthlyWaterConsumption from "../../../api/hooks/useStatisticsBiennialMonthlyWaterConsumption";
import useStatisticsDailyWaterConsumption from "../../../api/hooks/useStatisticsDailyWaterConsumption";
import useStatisticsMonthlyWaterConsumption from "../../../api/hooks/useStatisticsMonthlyWaterConsumption";
import DateConstants from "../../../constants/DateConstants";
import generateSkeletonStatisticsAnnualChartData from "../../../helpers/generateSkeletonStatisticsAnnualChartData";
import generateSkeletonStatisticsDailyChartData from "../../../helpers/generateSkeletonStatisticsDailyChartData";
import generateSkeletonStatisticsMonthlyChartData from "../../../helpers/generateSkeletonStatisticsMonthlyChartData";
import useProfileSettings from "../../../hooks/useProfileSettings";
import {
  GetDailyWaterConsumption,
  GetMonthlyWaterConsumption,
} from "./services/WaterConsumptionService";
import AnnualChart from "./StatisticsAnnualChart";
import ChartTitle from "./StatisticsChartTitle";
import StatisticsDailyChart from "./StatisticsDailyChart";
import StatisticsMonthlyChart from "./StatisticsMonthlyChart";

function StatisticsCharts({
  waterConsumptionData,
  previousWaterConsumptionData,
  statisticsFilters,
  setDate,
  date,
  showPreviousWaterConsumptionData,
}) {
  // Monthly
  const [renderMonthlyChart, setRenderMonthlyChart] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthlyData, setMonthlyData] = useState();

  // Daily
  const [renderDailyChart, setRenderDailyChart] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [dailyData, setDailyData] = useState();

  const year = waterConsumptionData?.Year;

  const { userSettings } = useProfileSettings();

  const { status: queryStatus, refetch: getMonthlyWaterConsumption } =
    useStatisticsMonthlyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const { refetch: getBiennialMonthlyWaterConsumptionData } =
    useStatisticsBiennialMonthlyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const { refetch: getDailyWaterConsumption } =
    useStatisticsDailyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      selectedDay?.Day,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const { refetch: getBiennialDailyWaterConsumption } =
    useStatisticsBiennialDailyWaterConsumption(
      year,
      selectedMonth?.MonthAsInt,
      selectedDay?.Day,
      {
        propertyId: statisticsFilters.propertyId,
        floorId: statisticsFilters.floorId,
        systemId: statisticsFilters.systemId,
      },
      { enabled: false, cacheTime: 0 },
    );

  const handleHideMonthlyChart = () => {
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
    setRenderDailyChart(false);
    setSelectedDay(null);
  };

  const handleHideDailyChart = () => {
    setRenderDailyChart(false);
    setSelectedDay(null);
  };

  // Hide the monthly chart if the year has been changed.
  useEffect(() => {
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
  }, [date.year, waterConsumptionData]);

  // Hide the daily chart if the month has been changed.
  useEffect(() => {
    setRenderDailyChart(false);
    setSelectedDay(null);
  }, [date.m, waterConsumptionData]);

  // Enforce rendering of a specific month chart when a month is selected from the calendar
  useEffect(() => {
    const month = waterConsumptionData?.StatisticsData?.find(
      (item) => item.Month === DateConstants.monthsAbbreviated[date.m],
    );
    setSelectedMonth(month);
    // Only when the date.m is changed, we do not want this useEffect when the array of data is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.m]);

  useEffect(() => {
    const day = { Day: date.d };
    setSelectedDay(day);
    // Only when the date.d is changed, we do not want this useEffect when the array of data is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.d]);

  useEffect(() => {
    const fetchData = showPreviousWaterConsumptionData
      ? getBiennialMonthlyWaterConsumptionData
      : getMonthlyWaterConsumption;
    if (selectedMonth) {
      GetMonthlyWaterConsumption(
        fetchData,
        year,
        selectedMonth,
        statisticsFilters,
        setMonthlyData,
        userSettings,
        setRenderMonthlyChart,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showPreviousWaterConsumptionData,
    selectedMonth,
    getMonthlyWaterConsumption,
    getBiennialMonthlyWaterConsumptionData,
    userSettings.waterUnit,
  ]);

  useEffect(() => {
    const fetchData = showPreviousWaterConsumptionData
      ? getBiennialDailyWaterConsumption
      : getDailyWaterConsumption;
    if (selectedMonth && selectedDay) {
      GetDailyWaterConsumption(
        fetchData,
        year,
        selectedMonth,
        selectedDay,
        statisticsFilters,
        setDailyData,
        userSettings,
        setRenderDailyChart,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showPreviousWaterConsumptionData,
    selectedDay,
    getDailyWaterConsumption,
    getBiennialDailyWaterConsumption,
    userSettings.waterUnit,
  ]);

  const titleYears = [
    showPreviousWaterConsumptionData ? previousWaterConsumptionData.Year : "",
    waterConsumptionData.Year,
  ]
    .filter((year) => year !== "")
    .join(" / ");

  const titleMonths = `${
    selectedMonth && selectedMonth.MonthAsInt ? `${selectedMonth.Month} - ` : ""
  }${titleYears}`;

  const titleDaily = `${
    selectedMonth && selectedMonth?.MonthAsInt && selectedDay?.Day
      ? `${selectedDay.Day} - ${titleMonths}`
      : ""
  }`;

  useEffect(() => {
    const t1 = document.querySelector(".daily-chart-container");
    t1?.scrollIntoView(false, { behavior: "smooth" });
  }, [date.d]);

  return (
    <>
      <div className="w-full statistics-charts" style={{ height: 288 }}>
        {titleYears && (
          <ChartTitle
            title={titleYears}
            waterConsumptionData={waterConsumptionData}
            chartType="annual-chart"
          />
        )}
        <AnnualChart
          waterConsumptionData={
            waterConsumptionData?.StatisticsData?.length === 0
              ? generateSkeletonStatisticsAnnualChartData(
                  waterConsumptionData.Year,
                )
              : waterConsumptionData
          }
          previousWaterConsumptionData={
            previousWaterConsumptionData?.StatisticsData?.length === 0
              ? generateSkeletonStatisticsAnnualChartData(
                  previousWaterConsumptionData.Year,
                )
              : previousWaterConsumptionData
          }
          setSelectedMonth={setSelectedMonth}
          setDate={setDate}
          date={date}
          showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
        />
      </div>
      {queryStatus === "success" &&
        renderMonthlyChart === true &&
        selectedMonth &&
        monthlyData && (
          <div
            className="w-full statistics-charts-monthly mt-12"
            style={{ height: 288 }}
          >
            <ChartTitle
              title={titleMonths}
              waterConsumptionData={monthlyData[0]}
              handleHideChart={handleHideMonthlyChart}
              chartType="monthly-chart"
            />
            <StatisticsMonthlyChart
              waterConsumptionData={
                monthlyData[0]?.StatisticsData?.length === 0
                  ? generateSkeletonStatisticsMonthlyChartData(
                      monthlyData[0].Year,
                      monthlyData[0].Month,
                    )
                  : monthlyData[0]
              }
              previousWaterConsumptionData={
                monthlyData[1]?.StatisticsData?.length === 0
                  ? generateSkeletonStatisticsMonthlyChartData(
                      monthlyData[1].Year,
                      monthlyData[1].Month,
                    )
                  : monthlyData[1]
              }
              setSelectedDay={setSelectedDay}
              handleHideMonthlyChart={handleHideMonthlyChart}
              showPreviousWaterConsumptionData={
                showPreviousWaterConsumptionData
              }
              setDate={setDate}
              date={date}
            />
          </div>
        )}

      {renderDailyChart === true && selectedDay && dailyData && (
        <div
          className="w-full statistics-charts-daily mt-12"
          style={{ height: 288 }}
        >
          <ChartTitle
            title={titleDaily}
            waterConsumptionData={dailyData[0]}
            handleHideChart={handleHideDailyChart}
            chartType="daily-chart"
          />
          <StatisticsDailyChart
            waterConsumptionData={
              dailyData[0]?.StatisticsData.length === 0
                ? generateSkeletonStatisticsDailyChartData(
                    dailyData[0].Year,
                    dailyData[0].Month,
                    dailyData[0].Day,
                  )
                : dailyData[0]
            }
            previousWaterConsumptionData={
              dailyData[1]?.StatisticsData.length === 0
                ? generateSkeletonStatisticsDailyChartData(
                    dailyData[1].Year,
                    dailyData[1].Month,
                    dailyData[1].Day,
                  )
                : dailyData[1]
            }
            handleHideDailyChart={handleHideDailyChart}
            showPreviousWaterConsumptionData={showPreviousWaterConsumptionData}
            setDate={setDate}
            date={date}
          />
        </div>
      )}
    </>
  );
}

StatisticsCharts.defaultProps = {
  waterConsumptionData: {},
  previousWaterConsumptionData: {},
  statisticsFilters: {},
  showPreviousWaterConsumptionData: false,
  setDate: () => {},
  date: {},
};

StatisticsCharts.propTypes = {
  waterConsumptionData: PropTypes.object,
  previousWaterConsumptionData: PropTypes.object,
  statisticsFilters: PropTypes.object,
  showPreviousWaterConsumptionData: PropTypes.bool,
  setDate: PropTypes.func,
  date: PropTypes.object,
};

export default StatisticsCharts;
