import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function HeimanCarbonMonoxideSensorIcon({ className }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3384 10.9201C13.1858 11.1655 12.9226 11.3005 12.6534 11.3005C12.5082 11.3005 12.3613 11.2613 12.2288 11.179C11.851 10.9441 11.7351 10.4474 11.9699 10.0695C12.4716 9.26237 12.7368 8.33054 12.7368 7.37472C12.7368 6.45946 12.4923 5.56182 12.0299 4.77887C11.8035 4.39577 11.9306 3.90176 12.3137 3.67549C12.6969 3.44917 13.1909 3.57627 13.4171 3.95932C14.0262 4.99032 14.3481 6.17135 14.3481 7.37472C14.3481 8.63143 13.9989 9.85741 13.3384 10.9201ZM11.3269 12.0615C11.5535 12.4444 11.4267 12.9386 11.0439 13.1651C10.0118 13.7758 8.8293 14.0986 7.62423 14.0986C3.91672 14.0986 0.900391 11.0823 0.900391 7.37472C0.900391 3.66721 3.91672 0.650879 7.62423 0.650879C8.81855 0.650879 9.99184 0.96824 11.0173 1.56872C11.4012 1.79355 11.5302 2.28707 11.3054 2.671C11.0806 3.05493 10.5871 3.18395 10.2031 2.95917C9.4244 2.50318 8.53263 2.26216 7.62423 2.26216C4.80514 2.26216 2.51167 4.55563 2.51167 7.37472C2.51167 10.1938 4.80514 12.4873 7.62423 12.4873C8.54081 12.4873 9.43955 12.2421 10.2233 11.7784C10.6063 11.5519 11.1004 11.6786 11.3269 12.0615V12.0615ZM10.8239 8.55922C10.7902 9.35259 10.1197 9.96843 9.32639 9.93468C8.53307 9.90104 7.91723 9.23054 7.95092 8.43722C7.98462 7.6439 8.65507 7.02811 9.44839 7.06175C10.2418 7.0955 10.8575 7.7659 10.8239 8.55922ZM7.29754 8.55922C7.26385 9.35259 6.5934 9.96843 5.80003 9.93468C5.00671 9.90104 4.39092 9.23054 4.42461 8.43722C4.45831 7.6439 5.1287 7.02811 5.92208 7.06175C6.7154 7.0955 7.33124 7.7659 7.29754 8.55922ZM7.71193 4.05322C8.50525 4.08686 9.12104 4.75736 9.0874 5.55068C9.0537 6.344 8.38325 6.95979 7.58988 6.92615C6.79656 6.8924 6.18077 6.22201 6.21447 5.42869C6.24811 4.63531 6.91856 4.01947 7.71193 4.05322V4.05322Z"
        fill="white"
      />
    </svg>
  );
}

HeimanCarbonMonoxideSensorIcon.propTypes = {
  className: PropTypes.string,
};

HeimanCarbonMonoxideSensorIcon.defaultProps = {
  className: "",
};
