import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useServiceProvider(
  serviceProviderId,
  options,
  key = `${serviceProviderId}-getServiceProvider`,
) {
  const axiosPrivate = useAxiosPrivate();

  const getServiceProvider = async () => {
    const serviceProvider = await axiosPrivate({
      method: "GET",
      url: `ServiceProvider/${serviceProviderId}`,
    }).then((res) => res.data);
    return serviceProvider;
  };

  return useQuery([key], () => getServiceProvider(serviceProviderId), {
    ...options,
  });
}

export default useServiceProvider;
