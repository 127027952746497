import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Card, Skeleton } from "antd";
import Search from "antd/es/input/Search";
import { useSearchParams } from "react-router-dom";

import {
  usePropertyOverviewMetadata,
  usePropertyOverviewStatuses,
  usePropertyOverviewWaterConsumption,
} from "../../../../../api/hooks/usePropertyOverview";
import useWindowSize from "../../../../../hooks/useWindowSize";
import BackButton from "../../../../Shared/BackButton";
import FloorManagementPropertyCard from "./FloorManagementPropertyCard";

function FloorManagementPropertyList() {
  const window = useWindowSize();
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const pageSize = 10;
  // get search params from URl and format them as expected from the API
  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { data: properties, status: metadataQueryStatus } =
    usePropertyOverviewMetadata({
      search: debouncedSearchQuery,
    });

  const statusTypes = ["Alert"];
  const statusTypesQuery = new URLSearchParams();
  statusTypes.forEach((statusType, index) => {
    statusTypesQuery.append(`statusTypes[${index}]`, statusType);
  });
  const {
    data: overviewStatusesCollectionData,
    isLoading: overviewStatusesIsLoading,
  } = usePropertyOverviewStatuses(statusTypesQuery);

  const {
    data: waterConsumptionCollectionData,
    isLoading: waterConsumptionIsLoading,
  } = usePropertyOverviewWaterConsumption();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const filteredProperties = properties
    ?.sort((firstElement, secondElement) =>
      firstElement.Name.localeCompare(secondElement.Name),
    )
    .filter((p) => p.Name !== "Default Property");

  return (
    <div className="floor-management-property-list content-wrapper overflow-y-hidden">
      <BackButton
        redirect={{ to: "/preferences/property-management" }}
        confirmation={false}
        className="self-start uppercase tracking-widest mr-4"
      />
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0 !text-triple-blue">
              floor management
            </h1>
          )}
          {window?.width <= 768 && (
            <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
              floor management
            </h1>
          )}
          <div className="ml-auto inline-flex items-center">
            <Search
              className="w-full md:w-fit mr-4"
              placeholder="Search Properties"
              onChange={handleSearchChange}
              allowClear
              disabled={
                (!searchQuery && filteredProperties?.length === 0) ||
                metadataQueryStatus === "loading"
              }
              size="middle"
            />
          </div>
        </div>
      </div>

      <Card className="triple-plus-content rounded-2xl">
        {metadataQueryStatus === "error" && (
          <div className="p-6 text-white text-opacity-80 text-center">
            <FrownOutlined className="text-xl mr-2" />
            Something went wrong
          </div>
        )}

        {metadataQueryStatus === "loading" &&
          Array.from({ length: pageSize }, (value, index) => index).map(
            (el) => (
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
                key={el}
              />
            ),
          )}

        {metadataQueryStatus === "success" &&
          filteredProperties?.length === 0 && (
            <div className="p-6 text-white text-opacity-80">
              <FrownOutlined className="text-xl mr-2" />
              We couldn`t find any matches
            </div>
          )}

        {metadataQueryStatus === "success" &&
          filteredProperties?.length > 0 && (
            <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
              {filteredProperties.map((property) => (
                <div className="sm:my-2 my-4" key={property.Id}>
                  <FloorManagementPropertyCard
                    key={`${property.Id}_${property.Name}`}
                    objectProperty={property}
                    waterConsumptionCollectionData={
                      waterConsumptionCollectionData
                    }
                    overviewStatusesCollectionData={
                      overviewStatusesCollectionData
                    }
                    waterConsumptionIsLoading={waterConsumptionIsLoading}
                    overviewStatusesIsLoading={overviewStatusesIsLoading}
                  />
                </div>
              ))}
            </div>
          )}
      </Card>
    </div>
  );
}

export default FloorManagementPropertyList;
