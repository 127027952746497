import PropTypes from "prop-types";

import logo from "../../../assets/img/property-overview-default-pic.png";
import useWindowSize from "../../../hooks/useWindowSize";

const propTypes = { imageUrl: PropTypes.string };

const defaultProps = { imageUrl: "" };

function PropertyImage({ imageUrl }) {
  const window = useWindowSize();
  const propertyImageUrl = imageUrl || logo;

  return (
    <div
      style={{
        backgroundImage: `url(${propertyImageUrl})`,
        backgroundSize: "cover",
      }}
      className={`property-image ${
        window?.width < 991 ? "property-image--blured-bottom" : ""
      }`}
    >
      &nbsp;
    </div>
  );
}

PropertyImage.propTypes = propTypes;
PropertyImage.defaultProps = defaultProps;

export default PropertyImage;
