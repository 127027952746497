import { useState, useRef, useEffect } from "react";

import {
  MenuUnfoldOutlined,
  LeftOutlined,
  RightOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Table, Button, Skeleton, Alert, ConfigProvider } from "antd";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import "./CustomTable.scss";
import useAuth from "../../hooks/useAuth";
import useWindowSize from "../../hooks/useWindowSize";
import CustomTableSidebar from "./CustomTableSidebar";

const propTypes = {
  autoRows: PropTypes.bool,
  className: PropTypes.string,
  currentPage: PropTypes.number,
  data: PropTypes.array,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  noPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  sidebarColumns: PropTypes.array,
  scroll: PropTypes.any,
  size: PropTypes.string,
  status: PropTypes.string,
  tableColumns: PropTypes.array,
  tableHeader: PropTypes.any,
  totalCount: PropTypes.number,
  withHeader: PropTypes.bool,
  withSidebar: PropTypes.bool,
  onChange: PropTypes.func,
  onRowClick: PropTypes.func,
};

const defaultProps = {
  autoRows: false,
  className: "",
  currentPage: null,
  errorMessage: "If you think we might be wrong, try adjusting your filters",
  errorTitle: "No records found",
  tableColumns: [],
  data: [],
  status: "loading",
  noPagination: false,
  pageSize: 10,
  sidebarColumns: [],
  scroll: { x: "100%" },
  size: "middle",
  tableHeader: null,
  totalCount: null,
  withHeader: false,
  withSidebar: false,
  onChange: () => {},
  onRowClick: () => {},
};

function CustomTable({
  autoRows,
  className,
  currentPage,
  errorMessage,
  errorTitle,
  tableColumns,
  data,
  scroll,
  status,
  noPagination,
  pageSize,
  sidebarColumns,
  size,
  tableHeader,
  totalCount,
  withHeader,
  withSidebar,
  onChange,
  onRowClick,
}) {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [rowsBasedOnHeight, setRowsBasedOnHeight] = useState(null);
  const [searchParams] = useSearchParams();

  const tableContainerRef = useRef(null);
  const rowRef = useRef(null);

  const window = useWindowSize();
  const { auth, setAuth } = useAuth();

  const [firstColumn, ...restColumns] = tableColumns;

  const columns = [
    {
      title: withSidebar ? (
        <div className="flex items-center">
          {!isSidebarVisible && (
            <MenuUnfoldOutlined
              className="ml-2"
              onClick={() => setIsSidebarVisible(true)}
            />
          )}
          <div className={`${!isSidebarVisible ? "ml-6" : "mx-auto"}`}>
            {firstColumn?.title}
          </div>
        </div>
      ) : (
        <div className="text-center">{firstColumn?.title}</div>
      ),
      key: firstColumn?.key,
      render: firstColumn?.render,
      width: 150,
    },
    ...restColumns,
  ];
  const columnsNames = columns.map((column) => column.key);
  const [columnsToDisplay, setColumnsToDisplay] = useState(columnsNames);

  // calculate number of rows based on screen size
  useEffect(() => {
    if (autoRows) {
      if (rowRef?.current) {
        // container height - pagination - table header - 40px
        const avaliableSpace = Math.floor(
          tableContainerRef.current.offsetHeight -
            3.5 * rowRef.current.offsetHeight,
        );
        const maxRowsPerPage = Math.floor(
          avaliableSpace / rowRef.current.offsetHeight,
        );

        setRowsBasedOnHeight(maxRowsPerPage);
        setAuth({ ...auth, rowsPerPage: maxRowsPerPage });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRows]);

  if (status === "error") {
    return (
      <div className="flex items-center justify-center">
        <Alert
          message="We have bad news"
          description="Еither we broke something or there is nothing to display."
          type="error"
          showIcon
          icon={<FrownOutlined />}
          className="w-fit"
        />
      </div>
    );
  }

  // toggle visible columns (sidebar checkboxes)
  const onColumnsToDisplayChange = (updatedColumnsToDisplay) => {
    setColumnsToDisplay(updatedColumnsToDisplay);
  };

  // table sidebar
  const renderSidebar = () => {
    return (
      <CustomTableSidebar
        columns={sidebarColumns}
        columnsToDisplay={columnsToDisplay}
        onSidebarFold={() => setIsSidebarVisible(false)}
        onColumnsToDisplayChange={onColumnsToDisplayChange}
      />
    );
  };

  // table header
  const renderHeader = () => {
    return tableHeader;
  };

  // Pagination with next / prev buttons
  const amendPagination = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <Button type="link" className="pagination-buttons" size="small">
          <LeftOutlined />
          {window?.width > 992 && "Previous"}
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button type="link" className="pagination-buttons" size="small">
          {window?.width > 992 && "Next"}
          <RightOutlined />
        </Button>
      );
    }
    // actual page numbers 1, 2, 3 ...
    return originalElement;
  };

  // columns for skeleton table (loading state)
  const ghostTableColumns = columns.map((column) => {
    return {
      ...column,
      render: function renderPlaceholder() {
        return <Skeleton key={column.key} active paragraph={false} />;
      },
    };
  });

  // data for skeleton table (loading state)
  const ghostTableData = [...Array(3)].map((_, index) => ({
    key: `key${index}`,
  }));

  const customizeRenderEmpty = () => (
    <div className="w-full h-32 flex flex-col items-center justify-center">
      <div className="uppercase tracking-widest mb-2 flex items-center">
        <FrownOutlined className="text-lg mr-2" /> {errorTitle}
      </div>
      <div>{errorMessage}</div>
    </div>
  );

  return (
    <div ref={tableContainerRef} className="h-full">
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={
            status === "loading"
              ? ghostTableColumns
              : columns.filter((item) => columnsToDisplay?.includes(item.key))
          }
          dataSource={status === "loading" ? ghostTableData : data}
          rowKey={(record) => record?.LogsInfo?.Id || Math.random()}
          title={
            (withHeader && renderHeader) ||
            (withSidebar && isSidebarVisible && renderSidebar) ||
            null
          }
          pagination={
            !noPagination
              ? {
                  current: Number(searchParams.get("page")) || currentPage || 1,
                  pageSize: rowsBasedOnHeight || pageSize,
                  itemRender: amendPagination,
                  total: totalCount,
                  showSizeChanger: false,
                  size: window?.width <= 576 && "small",
                  showLessItems: window?.width <= 576,
                }
              : false
          }
          tableLayout="fixed"
          className={`custom-table ${className} ${
            withSidebar ? "table-with-sidebar" : ""
          } ${withHeader ? "table-with-header" : ""}`}
          size={size}
          scroll={scroll}
          rowClassName={(record) => {
            if (record?.LogsInfo?.State) {
              return `record-${record?.LogsInfo?.State?.toLowerCase()}`;
            }
            return null;
          }}
          onRow={(record) => {
            return {
              ref: rowRef,
              onClick: (e) => onRowClick(e, record),
            };
          }}
          onChange={(pagination) => {
            onChange(pagination);
          }}
        />
      </ConfigProvider>
    </div>
  );
}

CustomTable.propTypes = propTypes;
CustomTable.defaultProps = defaultProps;

export default CustomTable;
