import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useFdsSchedulesList(params, options, key = "getFdsSchedulesList") {
  const axiosPrivate = useAxiosPrivate();

  const getFdsSchedulesList = async () => {
    const fdsSchedulesList = await axiosPrivate({
      method: "GET",
      url: "Schedule/Fds",
      params,
    }).then((res) => res.data);
    return fdsSchedulesList;
  };

  return useQuery([key], () => getFdsSchedulesList(params), {
    ...options,
  });
}

export default useFdsSchedulesList;
