function ProfileIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 503.6 478.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        className="blue-fill-color"
        fill="#ffffff"
        d="M434.5,384c-28.8-49.8-76.6-85.1-131.1-99c28-17.3,46.7-48.3,46.7-83.6c0-54.2-44.1-98.3-98.3-98.3   c-54.2,0-98.3,44.1-98.3,98.3c0,35.3,18.7,66.3,46.7,83.6c-54.5,13.8-102.3,49.2-131.1,99c-4,6.9-1.6,15.8,5.3,19.8   c6.9,4,15.8,1.6,19.8-5.3c32.5-56.1,92.8-90.9,157.5-90.9s125.1,34.8,157.5,90.9c2.7,4.7,7.6,7.3,12.6,7.3c2.5,0,5-0.6,7.3-2   C436.1,399.8,438.5,390.9,434.5,384z M251.8,132.1c38.2,0,69.3,31.1,69.3,69.3s-31.1,69.3-69.3,69.3s-69.3-31.1-69.3-69.3   S213.6,132.1,251.8,132.1z"
      />
    </svg>
  );
}

export default ProfileIcon;
