import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyBasics(params, options, key = "getPropertyBasics") {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyBasics = async () => {
    const propertyBasics = await axiosPrivate({
      method: "GET",
      url: "/Property/Basics",
      params,
    }).then((res) => res.data);
    return propertyBasics;
  };

  return useQuery([key, params], () => getPropertyBasics(params), {
    ...options,
  });
}

export default usePropertyBasics;
