import { useEffect, useState } from "react";

import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip, Input, Divider, Row, Col, Card } from "antd";
import { useNavigate } from "react-router-dom";

import useScheduleSystems from "../../../hooks/useScheduleSystems";
import useWindowSize from "../../../hooks/useWindowSize";
import DevicesTree from "../DevicesTree/DevicesTree";
import SummaryTree from "../SummaryTree/SummaryTree";
import SystemsTree from "../SystemsTree/SystemsTree";
import SelectDeviceTypeInput from "./SelectDeviceTypeInput/SelectDeviceTypeInput";
import SelectFloorInput from "./SelectFloorInput/SelectFloorInput";
import SelectPropertyInput from "./SelectPropertyInput/SelectPropertyInput";

function CreateNewSchedule() {
  const window = useWindowSize();
  const navigate = useNavigate();
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();
  const [scheduleName, setScheduleName] = useState(
    scheduleSystems?.scheduleName,
  );
  const [debouncedScheduleName, setDebouncedScheduleName] = useState("");
  const [disabledCreateSchedule, setDisabledCreateSchedule] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedScheduleName(scheduleName);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scheduleName]);

  useEffect(() => {
    setScheduleSystems((prevState) => ({
      ...prevState,
      scheduleName,
    }));
  }, [debouncedScheduleName, scheduleName, setScheduleSystems]);

  const tooltipText =
    "Define the days and hours that the selected devices will be enabled";

  useEffect(() => {
    if (
      scheduleSystems.selectedDevicesUniqueIds.length > 0 &&
      scheduleName.length > 0 &&
      /\S/.test(scheduleName)
    ) {
      setDisabledCreateSchedule(false);
    } else {
      setDisabledCreateSchedule(true);
    }
  }, [scheduleSystems.selectedDevicesUniqueIds, scheduleName]);

  return (
    <div className="create-new-schedule h-full flex flex-col">
      <Button
        className="self-start uppercase tracking-widest"
        type="link"
        onClick={() => {
          navigate(-1);
        }}
      >
        <LeftOutlined />
        Back
      </Button>
      <div className="schedule-header">
        {window?.width > 992 && (
          <div className="flex items-center">
            <h1 className="page-title mb-0">Create new Schedule</h1>
            <Tooltip
              placement="top"
              title={tooltipText}
              className="ml-4 text-triple-white"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        )}

        <Button
          size="large"
          type="primary"
          className="ml-auto mb-2"
          disabled={disabledCreateSchedule}
          onClick={() =>
            navigate("/settings/schedule/manage-schedule-calendar")
          }
        >
          Create schedule
        </Button>
      </div>
      <Card className="triple-plus-content rounded-2xl">
        <div className="flex-1 flex flex-col">
          <Divider orientation="left" orientationMargin={0}>
            Name your schedule
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={16} md={12}>
              <Input
                placeholder="Schedule name"
                size="large"
                allowClear
                className="py-2"
                value={scheduleName}
                onChange={(e) => setScheduleName(e.target.value)}
              />
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>
            Choose property
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8} md={6}>
              <SelectPropertyInput />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <SelectFloorInput />
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>
            Choose device type
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8} md={6}>
              <SelectDeviceTypeInput />
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>
            Configure results
          </Divider>
          <div className="flex flex-col md:flex-row bg-[#303343] rounded-md flex-1">
            <SystemsTree className="w-full md:w-1/3 px-8 py-6 flex flex-col" />
            <Divider type="vertical" className="h-auto" />
            <DevicesTree className="w-full md:w-1/3 px-8 py-6 flex flex-col" />
            <Divider type="vertical" className="h-auto" />
            <SummaryTree className="w-full md:w-1/3 px-8 py-6 flex flex-col" />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CreateNewSchedule;
