import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useTotalEventsCount = (data) => {
  const axiosPrivate = useAxiosPrivate();

  const getTotalEventsCount = async () => {
    const totalEventsCount = await axiosPrivate({
      method: "POST",
      url: "/Logs/GetLogsCountByFilter2",
      data: {
        StartIndex: 0,
        ...data,
      },
    }).then((res) => res.data);
    return totalEventsCount;
  };

  return useQuery(
    ["getTotalEventsCount", data],
    () => getTotalEventsCount(data),
    {
      enabled: true,
    },
  );
};

export default useTotalEventsCount;
