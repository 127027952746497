import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsAnnualWaterConsumption(
  year,
  params,
  options,
  key = "getAnnualWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();
  const getAnnualWaterConsumption = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/${year}`,
      params,
    });

    return data;
  };

  return useQuery([key], () => getAnnualWaterConsumption(year, params), {
    ...options,
  });
}

export default useStatisticsAnnualWaterConsumption;
