function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 503.6 478.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#E1E0E3">
        <path
          className="blue-both-colors"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ffffff"
          d="M474.2,233.9l0.1-0.1h-0.2c-0.1-0.1-0.1-0.3-0.2-0.4l-1.7-3.1l-81-80.2c-5.3-5.3-14.6-5.3-20,0     c-2.7,2.6-4.2,6.2-4.2,9.9c0,3.7,1.5,7.3,4.2,9.9l55.8,55.2l-247.6,0.4c-7.8,0-14.1,6.3-14.1,14c0,7.7,6.3,14,14.2,14l247.6-0.3     l-55.7,55.2c-5.5,5.5-5.5,14.4,0,19.8c2.8,2.7,6.4,4.1,10,4.1c3.6,0,7.2-1.4,10-4.1l79.7-78.9c2.8-2.8,4.3-6.7,4.1-10.2     C475.2,237.1,474.8,235.2,474.2,233.9z M326.2,335.7c-8,0-14.5,6.5-14.5,14.5v52c0,8.6-8.6,15.6-19.3,15.6H94.5     c-10.6,0-19.3-7-19.3-15.6V76.1c0-8.6,8.6-15.6,19.3-15.6h197.9c10.6,0,19.3,7,19.3,15.6v54.7c0,8,6.5,14.5,14.5,14.5     c8,0,14.5-6.5,14.5-14.5V76.1c0-24.5-21.7-44.5-48.3-44.5H94.5c-26.6,0-48.3,20-48.3,44.5v326.1c0,24.5,21.7,44.5,48.3,44.5     h197.9c26.6,0,48.3-20,48.3-44.5v-52C340.7,342.2,334.2,335.7,326.2,335.7z"
        />
      </g>
    </svg>
  );
}

export default LogoutIcon;
