import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useSchedulesCount = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getSchedulesCount = async () => {
    const schedulesCount = await axiosPrivate({
      method: "GET",
      url: "/Schedule/All/Overview/Count",
      params,
    }).then((res) => res.data);
    return schedulesCount;
  };

  return useQuery(
    ["getSchedulesCount", params],
    () => getSchedulesCount(params),
    {
      enabled: true,
    },
  );
};

export default useSchedulesCount;
