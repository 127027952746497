const DateConstants = {
  daysOfWeek: {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  },
  fullyQualifiedDaysOfWeek: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
  months: {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  },
  monthsAbbreviated: {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  },
  formats: {
    // bucket type: year
    fullYear_monthAbbreviation: "yyyy MMM",
    fullYear_monthAbbreviation_label: "yyyy MMM, Example: 2023 Jan",
    fullYear_fullMonthName: "yyyy MMMM",
    fullYear_fullMonthName_label: "yyyy MMMM, Example: 2023 January",
    monthAbbreviation_fullYear: "MMM yyyy",
    monthAbbreviation_fullYear_label: "MMM yyyy, Example: Jan 2023",
    fullMonthName_fullYear: "MMMM yyyy",
    fullMonthName_fullYear_label: "MMMM yyyy, Example: January 2023",
    // bucket type: month
    twoDigitsYear_monthAbbreviation_dayOfMonth: "yy MMM dd",
    twoDigitsYear_monthAbbreviation_dayOfMonth_label:
      "yy MMM dd, Example: 23 Jan 10",
    fullYear_monthAbbreviation_dayOfMonth: "yyyy MMM dd",
    fullYear_monthAbbreviation_dayOfMonth_label:
      "yyyy MMM dd, Example: 2023 Jan 10",
    // bucket type: day
    dayOfMonth_monthAbbreviation_fullYear_hours_minutes: "dd MMM yyyy HH:mm tt",
    dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label:
      "dd MMM yyyy HH:mm tt, Ex: 10 Jan 2023 14:00 PM",
    dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes:
      "dddd dd MMM yyyy HH:mm tt",
    dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label:
      "dddd dd MMM yyyy HH:mm tt, Ex: Tuesday 10 Jan 2023 14:00 PM",
  },
};
export default DateConstants;
