import useUsersGetTotalAndUsedLicenses from "../../../../api/hooks/useUsersGetTotalAndUsedLicenses";
import CustomTable from "../../../CustomTable/CustomTable";

import "../scss/UsersListLicenses.scss";

function UsersListLicenses() {
  const { data: licensesCount, status } = useUsersGetTotalAndUsedLicenses({
    enabled: true,
    cacheTime: 0,
  });

  const renderLicenses = (title, countTotal, countUsed) => {
    return (
      <>
        <div className="text-center tracking-widest inline-flex">
          <div className="mx-1">{title}</div>
          <div className="mx-2 border border-solid rounded-md border-triple-blue px-2">
            {countTotal}
          </div>
        </div>
        <div className="text-center tracking-widest inline-flex">
          <div className="mx-1 mt-1">Used</div>
          <div className="mx-2 mt-1 border border-solid rounded-md border-triple-blue px-2 bg-triple-blue">
            {countUsed}
          </div>
        </div>
      </>
    );
  };
  const tableColumns = [
    {
      title: <span className="tracking-widest uppercase">licenses</span>,
      width: 450,
    },
    {
      title: () => {
        return renderLicenses(
          "Administrator",
          licensesCount?.Value.AdministratorLicensesTotalCount,
          licensesCount?.Value.AdministratorLicensesUsedCount,
        );
      },
      width: 200,
    },
    {
      title: () => {
        return renderLicenses(
          "Operator",
          licensesCount?.Value.OperatorLicensesTotalCount,
          licensesCount?.Value.OperatorLicensesUsedCount,
        );
      },

      width: 220,
    },
    {
      title: () => {
        return renderLicenses(
          "User",
          licensesCount?.Value.UserLicensesTotalCount,
          licensesCount?.Value.UserLicensesUsedCount,
        );
      },
      width: 150,
    },
    {
      title: "",
      width: 250,
    },
  ];

  return (
    <CustomTable
      className="licenses-table border border-solid border-triple-blue rounded-sm"
      status={status}
      noPagination
      tableColumns={tableColumns}
    />
  );
}

export default UsersListLicenses;
