import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function FloodDetectorIcon({ className }) {
  return (
    <svg
      width="1.75em"
      height="1.75em"
      fill="white"
      viewBox="0 0 800 800"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <circle cx="319.7" cy="620.4" r="15" fill="white" />
        <path
          className={className}
          d="M508,225.8l-12.2-13.9l-12.2,13.9c-3.9,4.5-16.9,19.5-33.2,40.2v-74c0-72-58.6-130.6-130.6-130.6      s-130.6,58.6-130.6,130.6V621c0,72,58.6,130.6,130.6,130.6S450.3,692.9,450.3,621v-38.7c14.3,4.8,29.6,7.4,45.5,7.4      c78.3,0,142.1-63.2,142.1-140.8C637.8,376,521.3,241,508,225.8z M420.3,621c0,55.4-45.1,100.6-100.6,100.6      S219.2,676.4,219.2,621V192.1c0-55.4,45.1-100.6,100.6-100.6s100.6,45.1,100.6,100.6v114.1c-33.1,46.7-66.6,103.6-66.6,142.6      c0,50.2,26.6,94.3,66.6,119.2V621z M495.8,557.2c-60.4,0-109.6-48.6-109.6-108.3c0-46,70.5-140.3,109.6-187.2      c39.1,46.9,109.6,141.2,109.6,187.2C605.3,508.6,556.2,557.2,495.8,557.2z"
        />
      </g>
    </svg>
  );
}

FloodDetectorIcon.propTypes = {
  className: PropTypes.string,
};

FloodDetectorIcon.defaultProps = {
  className: "",
};
