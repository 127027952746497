import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const usePropertiesCount = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getPropertiesCount = async () => {
    const propertiesCount = await axiosPrivate({
      method: "GET",
      url: "/Property/Overview/Count",
      params,
    }).then((res) => res.data);
    return propertiesCount;
  };

  return useQuery(
    ["getPropertiesCount", params],
    () => getPropertiesCount(params),
    {
      enabled: true,
    },
  );
};

export default usePropertiesCount;
