import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useFdsSchedulesListCount = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getFdsSchedulesListCount = async () => {
    const fdsSchedulesListCount = await axiosPrivate({
      method: "GET",
      url: "Schedule/Fds/Count",
      params,
    }).then((res) => res.data);
    return fdsSchedulesListCount;
  };

  return useQuery(
    ["getFdsSchedulesListCount", params],
    () => getFdsSchedulesListCount(params),
    {
      enabled: true,
    },
  );
};

export default useFdsSchedulesListCount;
