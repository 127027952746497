import { useRef, useState } from "react";

import { FrownOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";

import useForgotPassword from "../../api/hooks/useForgotPassword";
import logoTriplePlus from "../../assets/img/wasens-logo-tm-2.svg";
import GoogleRecaptcha from "../GoogleRecaptcha/GoogleRecaptcha";

function ForgotPassword() {
  const { mutate: resetPasswordV2 } = useForgotPassword();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordPageForm] = Form.useForm();
  const gRecaptchaRef = useRef(null);

  const handleSubmit = async () => {
    if (gRecaptchaRef.current) {
      setIsLoading(true);
      const gRecaptchaToken = await gRecaptchaRef.current.execute();
      const username = resetPasswordPageForm.getFieldValue("username");
      const requestBody = {
        username,
        recaptcha_token: gRecaptchaToken,
      };

      resetPasswordV2(requestBody, {
        onSuccess: () => {
          notification.success({
            message: (
              <span className="uppercase tracking-widest">
                password reset success
              </span>
            ),
            description:
              "A new password has been sent to the provided email address.",
            placement: "topRight",
            duration: 5,
            icon: <SmileOutlined className="text-triple-green" />,
          });
          setIsLoading(false);
          navigate("/login");
        },
        onError: () => {
          setIsLoading(false);
          notification.error({
            message: (
              <span className="uppercase tracking-widest">
                Something went wrong!
              </span>
            ),
            duration: 0,
            placement: "topRight",
            icon: <FrownOutlined className="text-triple-red" />,
          });
        },
      });
    }
  };

  return (
    <div className="login-page">
      <Card className="login-card rounded-3xl">
        <div className="login-content">
          <div className="flex justify-center items-center">
            <img
              src={logoTriplePlus}
              alt="Triple+ logo"
              className="w-28 sm:w-48 h-full object-contain"
            />
          </div>
          <div className="flex justify-center items-center">
            <h4 className="login-title">Reset Password</h4>
          </div>
          <Form
            name="login-form"
            layout="vertical"
            requiredMark={false}
            form={resetPasswordPageForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              className="mb-4"
              labelCol={{ style: { paddingBottom: 0 } }}
              name="username"
              rules={[
                {
                  required: true,
                  message: "Email Field is required.",
                },
              ]}
            >
              <Input
                prefix={
                  <div className="bg-triple-blue">
                    <UserOutlined
                      className="text-triple-white bg-triple-blue m-1"
                      style={{ fontSize: 20 }}
                    />
                  </div>
                }
                size="large"
                placeholder="Email"
                className="p-0 bg-triple-white border border-solid border-triple-blue"
              />
            </Form.Item>
            <div className="flex justify-between">
              <Button
                type="primary"
                htmlType="submit"
                size="sm"
                className="!bg-triple-blue w-full mr-1 reset-pwd-btn p-1"
                loading={isLoading}
              >
                <span className="font-medium">Reset Password</span>
              </Button>
              <Button
                type="primary"
                size="sm"
                className="!bg-triple-blue w-full ml-1 back-to-login-btn p-1"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <span className="font-medium">Back to Login</span>
              </Button>
            </div>
          </Form>
        </div>
      </Card>
      <GoogleRecaptcha
        siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        action="cmspasswordreset"
        ref={gRecaptchaRef}
      />
    </div>
  );
}

export default ForgotPassword;
