import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useDevicesByPropertyId(
  params,
  options,
  key = "getDevicesByPropertyId",
) {
  const axiosPrivate = useAxiosPrivate();

  const getDevicesByPropertyId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `Property/GetDevicesOverviewForProperty/${params?.propertyId}`,
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getDevicesByPropertyId(params), {
    ...options,
  });
}

export default useDevicesByPropertyId;
