import { useEffect, useState } from "react";

import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Divider,
  Drawer,
  Row,
  Col,
  Input,
  TimePicker,
  Checkbox,
  Button,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import useScheduleSystems from "../../../hooks/useScheduleSystems";
import useWindowSize from "../../../hooks/useWindowSize";

import "./CreateNewScene.scss";

const defaultScene = {
  id: null,
  startTime: "",
  endTime: "",
  days: [],
};

// eslint-disable-next-line react/prop-types
function CreateNewScene({ isDisabled = false }) {
  const window = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();

  const predefinedDay = searchParams.get("day") || null;
  const predefinedStartTime = searchParams.get("startTime")
    ? `${searchParams.get("startTime")}:00`
    : null;
  const isEdit = searchParams.get("editScene");
  const programId = searchParams.get("programId");

  const [isOpen, setIsOpen] = useState(false);
  const [sceneData, setSceneData] = useState(defaultScene);

  const canCreate =
    sceneData.name &&
    sceneData.startTime &&
    sceneData.endTime &&
    sceneData.days.length > 0;

  useEffect(() => {
    if (predefinedDay && predefinedStartTime) {
      setIsOpen(true);
      setSceneData({
        ...defaultScene,
        startTime: predefinedStartTime,
        days: [predefinedDay],
      });
    }
  }, [predefinedDay, predefinedStartTime]);

  useEffect(() => {
    if (isEdit) {
      const [test] = scheduleSystems.scheduleIntervals;
      setSceneData({
        ...test,
      });
    }
  }, [isEdit]);

  const resetDrawer = () => {
    setIsOpen(false);
    setSceneData(defaultScene);
    setSearchParams((params) => {
      params.delete("day");
      params.delete("startTime");
      params.delete("editScene");
      return params;
    });
  };

  const handleSceneSave = () => {
    if (sceneData.startTime === sceneData.endTime) {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Start time and End time should be different
          </span>
        ),
      });
    } else if (!isEdit) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        scheduleIntervals: [
          ...scheduleSystems.scheduleIntervals,
          { ...sceneData, id: uuidv4() },
        ],
      }));

      resetDrawer();
    } else {
      setScheduleSystems((prevState) => ({
        ...prevState,
        scheduleIntervals: [{ ...sceneData, id: uuidv4() }],
      }));

      resetDrawer();
    }
  };

  return (
    <>
      {!programId && (
        <Button
          size="large"
          className="ml-auto"
          disabled={isDisabled}
          onClick={() => setIsOpen(true)}
        >
          <PlusOutlined />
          Add new scene
        </Button>
      )}

      <Drawer
        placement="right"
        open={isOpen || isEdit}
        closeIcon={
          window?.width <= 576 ? (
            <ArrowLeftOutlined className="alarm-details-close-button" />
          ) : (
            <CloseOutlined className="alarm-details-close-button" />
          )
        }
        headerStyle={{ borderBottom: "none" }}
        width={window?.width > 576 ? "420px" : "100%"}
        onClose={resetDrawer}
        extra={
          <Button
            type="primary"
            size="large"
            disabled={!canCreate}
            onClick={handleSceneSave}
          >
            Apply
          </Button>
        }
      >
        <h1 className="drawer-title">
          {isEdit ? "Edit scene" : "Add new scene"}
        </h1>
        <div className="drawer-subtitle text-white">
          Define the days and hours that the selected devices will be enabled
        </div>

        <div className="mt-8">
          {!isEdit && (
            <>
              <Divider orientation="left" orientationMargin={0}>
                Scene Title
              </Divider>
              <Input
                placeholder="Scene name"
                size="large"
                allowClear
                value={sceneData?.name}
                onChange={(e) =>
                  setSceneData({ ...sceneData, name: e.target.value })
                }
              />
            </>
          )}

          <Divider orientation="left" orientationMargin={0}>
            Scene Time
          </Divider>
          <Row gutter={[16, 16]} className="mt-4">
            <Col xs={24} md={12} className="events-filter-col">
              <TimePicker
                className="events-filter-timepicker"
                format="HH:mm"
                inputReadOnly
                placeholder="Start Time"
                size="large"
                showNow={false}
                value={
                  sceneData?.startTime
                    ? dayjs(sceneData.startTime, "HH:mm")
                    : ""
                }
                onChange={(_, timeString) =>
                  setSceneData({
                    ...sceneData,
                    startTime: timeString,
                    endTime: null,
                  })
                }
              />
            </Col>
            <Col xs={24} md={12} className="events-filter-col">
              <TimePicker
                className="events-filter-timepicker"
                format="HH:mm"
                inputReadOnly
                placeholder="End Time"
                size="large"
                showNow={false}
                value={
                  sceneData?.endTime ? dayjs(sceneData.endTime, "HH:mm") : ""
                }
                disabled={!sceneData?.startTime}
                onChange={(_, timeString) => {
                  const selectedStartHour = sceneData?.startTime.substring(
                    0,
                    2,
                  );
                  const selectedStartMinutes = sceneData?.startTime.substring(
                    3,
                    5,
                  );
                  const formattedtTimeStringMinutes =
                    Number(selectedStartMinutes) + 1 > 9
                      ? Number(selectedStartMinutes) + 1
                      : `0${Number(selectedStartMinutes) + 1}`;

                  if (
                    timeString.substring(0, 2) === selectedStartHour &&
                    Number(timeString.substring(3, 5)) <=
                      Number(selectedStartMinutes)
                  ) {
                    setSceneData({
                      ...sceneData,
                      endTime: `${selectedStartHour}:${formattedtTimeStringMinutes}`,
                    });
                  } else {
                    setSceneData({ ...sceneData, endTime: timeString });
                  }
                }}
              />
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={0}>
            Repeat scene
          </Divider>
          <div className="flex flex-col">
            {[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ].map((day) => {
              return (
                <Checkbox
                  className="m-2"
                  key={day}
                  // value={day}
                  // checked={sceneData?.days.includes(
                  //   day.toLowerCase().substring(0, 3),
                  // )}
                  checked={
                    // day.toLowerCase().substring(0, 3) === predefinedDay
                    sceneData?.days.includes(day.toLowerCase().substring(0, 3))
                  }
                  onChange={() => {
                    if (
                      sceneData?.days.includes(
                        day.toLowerCase().substring(0, 3),
                      )
                    ) {
                      setSceneData({
                        ...sceneData,
                        days: sceneData?.days.filter(
                          (v) => v !== day.toLowerCase().substring(0, 3),
                        ),
                      });
                    } else {
                      setSceneData({
                        ...sceneData,
                        days: [
                          ...sceneData.days,
                          day.toLowerCase().substring(0, 3),
                        ],
                      });
                    }

                    // const { checked, value } = e.target;
                    // if (checked) {
                    //   setSceneData({
                    //     ...sceneData,
                    //     days: [
                    //       ...sceneData.days,
                    //       e.target.value.substring(0, 3).toLowerCase(),
                    //     ],
                    //   });
                    // } else {
                    //   const filteredDays = sceneData.days.filter(
                    //     (day) => day !== value,
                    //   );

                    //   setSceneData({
                    //     ...sceneData,
                    //     days: filteredDays,
                    //   });
                    // }
                  }}
                >
                  {day}
                </Checkbox>
              );
            })}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default CreateNewScene;
