import { Collapse, Row, Col, Divider } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../Loader/Loader";
import FloorSystemsTable from "../FloorSystemsTable/FloorSystemsTable";
import PropertyWaterConsumptionChart from "../PropertyCharts/PropertyWaterConsumptionChart";
import PropertyWaterSavingsChart from "../PropertyCharts/PropertyWaterSavingsChart";
import FloorDetails from "./FloorDetails";

const propTypes = {
  floor: PropTypes.object,
  floorsWaterConsumptionCollectionData: PropTypes.array,
  floorsWaterConsumptionIsLoading: PropTypes.bool,
  floorsOverviewStatusesCollectionData: PropTypes.array,
  floorsOverviewStatusesIsLoading: PropTypes.bool,
};

const defaultProps = {
  floor: {},
  floorsWaterConsumptionCollectionData: [],
  floorsWaterConsumptionIsLoading: false,
  floorsOverviewStatusesCollectionData: [],
  floorsOverviewStatusesIsLoading: false,
};

function Floor({
  floor,
  floorsWaterConsumptionCollectionData,
  floorsWaterConsumptionIsLoading,
  floorsOverviewStatusesCollectionData,
  floorsOverviewStatusesIsLoading,
}) {
  const { Panel } = Collapse;
  const window = useWindowSize();
  const navigate = useNavigate();
  const { Id } = floor;

  const waterConsumption = floorsWaterConsumptionCollectionData?.find(
    (waterConsumption) => waterConsumption.Id === Id,
  );

  const overviewStatus = floorsOverviewStatusesCollectionData?.find(
    (overviewStatus) => overviewStatus.Id === Id,
  );

  const devicesAlertStatuses = overviewStatus?.SystemStatuses?.flatMap(
    (x) => x.DeviceStatuses,
  ).filter((d) => d.Status === "Alert");

  const renderPanelHeader = () => {
    return (
      <Row
        className="lg:flex-nowrap"
        onClick={(e) => {
          if (window.width < 992) {
            e.stopPropagation();
            navigate(`floor/${floor.Id}`);
          }
        }}
      >
        {/* DETAIL */}
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <FloorDetails
            floor={floor}
            floorOverviewStatus={overviewStatus}
            floorOverviewStatusesIsLoading={floorsOverviewStatusesIsLoading}
            className="w-full lg:w-fit mt-0 py-0 xl:justify-center"
          />
        </Col>

        {/* DIVIDER */}
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1, offset: 0 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            className="lg:h-4/5 m-0 mb-4 lg:mb-0"
            type={window?.width > 992 ? "vertical" : "horizontal"}
          />
        </Col>

        {/* CHART 1 */}
        <Col
          xs={{ span: 12 }}
          md={{ span: 13 }}
          lg={{ span: 8 }}
          className="px-4 sm:px-0 lg:px-auto flex justify-center items-center"
        >
          {floorsWaterConsumptionIsLoading && (
            <Loader className="justify-center" />
          )}
          {waterConsumption && (
            <PropertyWaterConsumptionChart
              className="justify-between"
              data={waterConsumption?.TotalWaterConsumption}
            />
          )}
        </Col>
        {/* DIVIDER */}
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1, offset: 0 }}
          className="hidden lg:flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width < 768 ? "horizontal" : "vertical"}
            className="my-4 lg:h-4/5 lg:my-0"
          />
        </Col>
        {/* CHART 2 */}
        <Col
          xs={{ span: 12 }}
          md={{ span: 11 }}
          lg={{ span: 8 }}
          className="px-4 sm:px-0 lg:px-0 flex justify-center items-center"
        >
          {floorsWaterConsumptionIsLoading && (
            <Loader className="justify-center" />
          )}
          {waterConsumption && (
            <PropertyWaterSavingsChart
              className="justify-between"
              percent={
                waterConsumption?.TotalWaterConsumption?.WaterSavingsPercentage
              }
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="property-floor">
      <Collapse
        bordered={false}
        size="large"
        key={floor?.Id}
        className={`site-collapse-custom-collapse w-full p-0 bg-[#303343] mb-6 border border-solid ${
          devicesAlertStatuses?.length > 0
            ? "border-triple-red"
            : "border-triple-blue"
        }`}
      >
        <Panel
          showArrow={window.width > 992}
          header={renderPanelHeader()}
          key="1"
        >
          {window.width > 992 && (
            <FloorSystemsTable
              floor={floor}
              floorOverviewStatus={overviewStatus}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
}

Floor.propTypes = propTypes;
Floor.defaultProps = defaultProps;

export default Floor;
