import { useState, useEffect } from "react";

function TermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState("");
  useEffect(() => {
    fetch("/terms-and-conditions/terms-of-use.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load Terms of Use & Privacy Policy");
        }
        return response.text();
      })
      .then((text) => {
        setTermsAndConditions(text);
      })
      .catch(() => {
        setTermsAndConditions("Unable to load Temrs of Use & Privacy Policy.");
      });
  }, []);

  return (
    <div>
      <pre className="whitespace-pre-wrap break-words">
        {termsAndConditions}
      </pre>
    </div>
  );
}

export default TermsAndConditions;
