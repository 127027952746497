import { useEffect } from "react";

import { Button } from "antd";
import PropTypes from "prop-types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  getMaxWaterConsumptionValue,
  roundToNearest,
} from "../../../helpers/calculateChartYAxisUpperBound";
import useProfileSettings from "../../../hooks/useProfileSettings";
import StatisticsCustomTooltip from "./StatisticsCustomTooltip";
import YAxisLabel from "./StatisticsYAxisLabel";

function StatisticsDailyChart({
  waterConsumptionData,
  previousWaterConsumptionData,
  handleHideDailyChart,
  showPreviousWaterConsumptionData,
}) {
  const { userSettings } = useProfileSettings();

  const chartData = showPreviousWaterConsumptionData
    ? waterConsumptionData.StatisticsData.map((item, index) => ({
        Key: item.Hour,
        PreviousValue:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0 &&
          previousWaterConsumptionData.StatisticsData[index]
            ? previousWaterConsumptionData.StatisticsData[index]
                .WaterConsumption
            : 0,
        Value: item.WaterConsumption,
      }))
    : waterConsumptionData?.StatisticsData.map((item) => ({
        Key: item.Hour,
        Value: item.WaterConsumption,
      }));

  const maxWaterConsumption = getMaxWaterConsumptionValue(
    waterConsumptionData?.StatisticsData,
  );

  let maxYAxisValue = maxWaterConsumption;

  if (showPreviousWaterConsumptionData) {
    const maxPreviousWaterConsumption = getMaxWaterConsumptionValue(
      previousWaterConsumptionData?.StatisticsData,
    );

    maxYAxisValue = Math.max(maxWaterConsumption, maxPreviousWaterConsumption);
  }

  useEffect(() => {
    const dailyChartContainer = document.querySelector(
      ".daily-chart-container",
    );
    dailyChartContainer?.scrollIntoView(false, { behavior: "smooth" });
  }, []);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className="bg-triple-white rounded-b-xl pl-1 daily-chart-container"
    >
      <BarChart data={chartData}>
        <Button type="primary" size="small" onClick={handleHideDailyChart}>
          close
        </Button>
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <XAxis dataKey="Key">
          <Label
            content={
              <g>
                <text x="10" y="270">
                  Hour
                </text>
              </g>
            }
          />
        </XAxis>
        <YAxis name="Consumption" domain={[0, roundToNearest(maxYAxisValue)]}>
          <Label content={YAxisLabel(userSettings.waterUnit)} />
        </YAxis>
        <Tooltip
          labelClassName="text-triple-sidebar"
          content={
            <StatisticsCustomTooltip
              payload={waterConsumptionData?.StatisticsData}
              active
              chartType="Daily"
              waterUnit={userSettings.waterUnit}
            />
          }
        />

        <Legend verticalAlign="top" align="right" height={40} />
        {showPreviousWaterConsumptionData && (
          <Bar
            dataKey="PreviousValue"
            name={previousWaterConsumptionData.Year}
            fill="#5CD6EC"
            barSize={15}
            minPointSize={5}
            style={{ cursor: "pointer" }}
          />
        )}
        <Bar
          dataKey="Value"
          name={waterConsumptionData?.Year}
          fill="#03a5d2"
          barSize={15}
          minPointSize={5}
          style={{ cursor: "pointer" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

StatisticsDailyChart.propTypes = {
  waterConsumptionData: PropTypes.object,
  previousWaterConsumptionData: PropTypes.object,
  handleHideDailyChart: PropTypes.func,
  showPreviousWaterConsumptionData: PropTypes.bool,
};

StatisticsDailyChart.defaultProps = {
  waterConsumptionData: {},
  previousWaterConsumptionData: {},
  handleHideDailyChart: () => {},
  showPreviousWaterConsumptionData: true,
};

export default StatisticsDailyChart;
