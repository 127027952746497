import { useEffect, useState } from "react";

import { CalendarOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import Search from "antd/es/input/Search";
import { useNavigate, useSearchParams } from "react-router-dom";

import useSchedules from "../../api/hooks/useSchedules";
import useSchedulesCount from "../../api/hooks/useSchedulesCount";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader/Loader";
import ScheduleTable from "./ScheduleTable/ScheduleTable";

import "./Schedule.scss";

function Schedule() {
  const navigate = useNavigate();
  const window = useWindowSize();

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;

  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { data: totalSchedulesCount, refetch: refetchSchedulesCount } =
    useSchedulesCount({
      search: debouncedSearchQuery,
    });

  const { page } = params;

  const {
    data: schedules,
    status,
    refetch: getSchedules,
  } = useSchedules(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      search: searchQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    getSchedules();
    refetchSchedulesCount();
  }, [debouncedSearchQuery, page, getSchedules, refetchSchedulesCount]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="schedule">
      <div className="schedule-header mb-8">
        {window?.width > 992 && <h1 className="page-title mb-0">Schedule</h1>}

        <div className="ml-auto inline-flex items-center">
          <Search
            className="w-full md:w-fit mr-4"
            placeholder="Search Schedules"
            onSearch={onSearch}
            onChange={handleSearchChange}
            allowClear
            size="large"
          />
          <Button
            size="large"
            type="primary"
            className="ml-auto"
            // TODO: disabled while deleting maybe
            //   disabled={isDisabled}
            onClick={() => navigate("create-schedule")}
          >
            <CalendarOutlined />
            Set new schedule
          </Button>
        </div>
      </div>
      <Card className="triple-plus-content rounded-2xl">
        {schedules?.length === 0 && !searchQuery && (
          <div className="schedule--empty">
            <div className="schedule--empty-icon">
              <CalendarOutlined />
            </div>

            <span className="schedule--empty-message">
              You have not scheduled anything yet
            </span>
          </div>
        )}

        {!pageSize && <Loader className="absolute-center" />}

        {(status === "loading" || schedules?.length > 0 || searchQuery) && (
          <ScheduleTable
            data={schedules}
            status={status}
            pageSize={pageSize}
            totalCount={totalSchedulesCount}
            onChange={onPageChange}
          />
        )}
      </Card>
    </div>
  );
}

export default Schedule;
