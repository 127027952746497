import { RightOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useNavigate } from "react-router-dom";

function EventsLogTableHeader() {
  const navigate = useNavigate();

  return (
    <div className="custom-table-header-content">
      <div className="custom-table-header-title-holder">
        <div className="custom-table-header-title">Events Log</div>
        <Divider type="vertical" />
        <span className="custom-table-header-subtitle">All properties</span>
      </div>
      <Button
        type="link"
        className="custom-table-header-action"
        onClick={() => navigate("/events")}
      >
        View all events <RightOutlined />
      </Button>
    </div>
  );
}

export default EventsLogTableHeader;
