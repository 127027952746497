import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useFloorsByPropertyId(params, options, key = "getFloorsByPropertyId") {
  const axiosPrivate = useAxiosPrivate();

  const getFloorsByPropertyId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "/PropertyFloor/Overview",
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getFloorsByPropertyId(params), {
    ...options,
  });
}

export default useFloorsByPropertyId;
