import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useAssignMultipleDevicesToFloor() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: ({ floorId, request }) => {
      return axiosPrivate({
        method: "POST",
        url: `Device/AssignMultipleDevicesToFloor/${floorId}`,
        data: request,
      });
    },
  });
}

export default useAssignMultipleDevicesToFloor;
