import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUserBasics = () => {
  const axiosPrivate = useAxiosPrivate();

  const getUserBasics = async () => {
    const userBasics = await axiosPrivate({
      method: "GET",
      url: "/User/Basics/V2",
    }).then((response) => response.data);

    return userBasics;
  };

  return useQuery(["getUserBasics"], getUserBasics, {
    enabled: false,
    refetchOnMount: false,
    retry: false,
    staleTime: "Infinity",
  });
};

export default useUserBasics;
