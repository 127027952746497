import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useUpdateUserBasicsV2() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate({
        method: "PUT",
        url: "/User/Basics/V2",
        data,
      });
    },
  });
}

export default useUpdateUserBasicsV2;
