import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUsersManagementEditUser = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ userId, request }) => {
      return axiosPrivate({
        method: "PUT",
        url: `Users/Management/EditUser/${userId}`,
        data: request,
      });
    },
  });
};

export default useUsersManagementEditUser;
