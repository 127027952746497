import { Button, Modal } from "antd";
import PropTypes from "prop-types";

function UnsavedChangesWarning({
  onSaveClick,
  onDontSaveClick,
  onCancelClick,
  isAlertUnsavedChangesOpen,
}) {
  return (
    <Modal
      className="alert-unsaved-changes"
      maskClosable={false}
      closable={false}
      zIndex={9999}
      footer={
        <>
          <Button size="small" onClick={onSaveClick}>
            Save
          </Button>
          <Button
            size="small"
            className="btn-leave"
            danger
            onClick={onDontSaveClick}
          >
            Don&apos;t Save
          </Button>
          <Button size="small" onClick={onCancelClick}>
            Cancel
          </Button>
        </>
      }
      open={isAlertUnsavedChangesOpen}
      keyboard={false}
      destroyOnClose
    >
      <div className="text-center">
        <h4 className="uppercase">You have unsaved changes.</h4>
        <p>
          You can click &quot;CANCEL&quot; to continue editing, click
          &quot;DON&apos;T SAVE&quot; to discard changes or click
          &quot;SAVE&quot; to commit the changes.
        </p>
      </div>
    </Modal>
  );
}

UnsavedChangesWarning.propTypes = {
  onSaveClick: PropTypes.func,
  onDontSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isAlertUnsavedChangesOpen: PropTypes.bool,
};

UnsavedChangesWarning.defaultProps = {
  onSaveClick: () => {},
  onDontSaveClick: () => {},
  onCancelClick: () => {},
  isAlertUnsavedChangesOpen: false,
};

export default UnsavedChangesWarning;
