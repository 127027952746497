import { useEffect, useState, useMemo } from "react";

import { CalendarOutlined } from "@ant-design/icons";
import { Button, Divider, Card } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import useFdsSchedulesList from "../../api/hooks/useFdsSchedulesList";
import useFdsSchedulesListCount from "../../api/hooks/useFdsSchedulesListCount";
import { FdsFiltersValuesProvider } from "../../context/FdsFiltersValuesProvider";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader/Loader";
import FdsScheduleListTable from "./FdsScheduleListTable/FdsScheduleListTable";
import FdsSchedulesListFilters from "./Filters/FdsSchedulesListFilters";
import "./FdsSchedulesList.scss";

function FdsSchedulesList() {
  const navigate = useNavigate();

  const [refreshTable, setRefreshTable] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemUniqueId, setSystemUniqueId] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);

  const window = useWindowSize();
  const [debouncedPropertyQuery, setDebouncedPropertyQuery] = useState("");
  const [debouncedFloorQuery, setDebouncedFloorQuery] = useState("");
  const [debouncedSystemUniqueIdQuery, setDebouncedSystemUniqueIdQuery] =
    useState("");
  const [debouncedDeviceTypeQuery, setDebouncedDeviceTypeQuery] = useState("");
  const [
    debouncedDeviceSerialNumberQuery,
    setDebouncedDeviceSerialNumberQuery,
  ] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;

  // get search params from URl and format them
  // as expected from the API
  const params = useMemo(() => {
    const result = {};
    searchParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }, [searchParams]);
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  const { page } = params;

  const handleOnChangePropertyCallback = (value, option) => {
    setPropertyId(value === "all" ? "" : option.value);
    setFloorId("");
    setSystemUniqueId("");
    setDeviceType("");
    setDeviceSerialNumber("");
    setRefreshTable(true);
  };
  const handleOnChangeFloorCallback = (value, option) => {
    setFloorId(value === "all" ? "" : option.value);
    setSystemUniqueId("");
    setDeviceType("");
    setDeviceSerialNumber("");
    setRefreshTable(true);
  };
  const handleOnChangeSystemCallback = (value) => {
    setSystemUniqueId(value === "all" ? "" : value);
    setDeviceType("");
    setDeviceSerialNumber("");
    setRefreshTable(true);
  };
  const handleOnChangeDeviceTypeCallback = (value) => {
    setDeviceType(value === "all" ? "" : value);
    setDeviceSerialNumber("");
    setRefreshTable(true);
  };
  const handleOnChangeDeviceSerialNumberCallback = (value) => {
    setDeviceSerialNumber(value === "all" ? "" : value);
    setRefreshTable(true);
  };

  const { data: totalSchedulesCount, getFdsSchedulesListCount } =
    useFdsSchedulesListCount(
      {
        pageSize,
        startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
        propertyId: debouncedPropertyQuery,
        floorId: debouncedFloorQuery,
        systemUniqueId: debouncedSystemUniqueIdQuery,
        deviceTypeId: debouncedDeviceTypeQuery,
        deviceSerialNumber: debouncedDeviceSerialNumberQuery,
      },
      {
        enabled: pageSize > 0,
        cacheTime: 0,
      },
    );

  const {
    data: fdsSchedulesList,
    status,
    refetch: getFdsSchedulesList,
  } = useFdsSchedulesList(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      propertyId: debouncedPropertyQuery,
      floorId: debouncedFloorQuery,
      systemUniqueId: debouncedSystemUniqueIdQuery,
      deviceTypeId: debouncedDeviceTypeQuery,
      deviceSerialNumber: debouncedDeviceSerialNumberQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    getFdsSchedulesList();
  }, [
    debouncedPropertyQuery,
    debouncedFloorQuery,
    debouncedSystemUniqueIdQuery,
    debouncedDeviceTypeQuery,
    debouncedDeviceSerialNumberQuery,
    getFdsSchedulesList,
    getFdsSchedulesListCount,
    params,
    page,
  ]);

  useEffect(() => {
    if (refreshTable) {
      setRefreshTable(false);
      setDebouncedPropertyQuery(propertyId);
      setDebouncedFloorQuery(floorId);
      setDebouncedSystemUniqueIdQuery(systemUniqueId);
      setDebouncedDeviceTypeQuery(deviceType);
      setDebouncedDeviceSerialNumberQuery(deviceSerialNumber);
    }
  }, [
    refreshTable,
    propertyId,
    floorId,
    systemUniqueId,
    deviceType,
    deviceSerialNumber,
    getFdsSchedulesList,
    params,
  ]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const IsFiltered = () => {
    const result =
      (propertyId && propertyId !== "all") ||
      (floorId && floorId !== "all") ||
      (systemUniqueId && systemUniqueId !== "all") ||
      (deviceType && deviceType !== "all") ||
      (deviceSerialNumber && deviceSerialNumber !== "all");

    return result;
  };

  return (
    <div className="schedule">
      <div className="schedule-header mb-6">
        {window?.width > 992 && (
          <h1 className="page-title mb-0">FLOW CONFIGURATION</h1>
        )}
        <Button
          size="large"
          type="primary"
          className="ml-auto"
          onClick={() => navigate("create-flow-configuration")}
        >
          <CalendarOutlined />
          Set New Flow Configuration
        </Button>
      </div>

      {fdsSchedulesList?.length === 0 && !IsFiltered() && (
        <div className="schedule--empty">
          <div className="schedule--empty-icon">
            <CalendarOutlined />
          </div>

          <span className="schedule--empty-message">
            You have not created flow configurations yet
          </span>
        </div>
      )}
      {(fdsSchedulesList?.length > 0 ||
        (fdsSchedulesList?.length === 0 && IsFiltered())) && (
        <Card className="triple-plus-content rounded-2xl">
          <FdsFiltersValuesProvider>
            <FdsSchedulesListFilters
              handleOnChangePropertyCallback={handleOnChangePropertyCallback}
              handleOnChangeFloorCallback={handleOnChangeFloorCallback}
              handleOnChangeSystemCallback={handleOnChangeSystemCallback}
              handleOnChangeDeviceTypeCallback={
                handleOnChangeDeviceTypeCallback
              }
              handleOnChangeDeviceSerialNumberCallback={
                handleOnChangeDeviceSerialNumberCallback
              }
            />
          </FdsFiltersValuesProvider>
          <Divider orientation="left" orientationMargin={0} />
          {!pageSize && <Loader className="absolute-center" />}
          {(fdsSchedulesList?.length > 0 || debouncedPropertyQuery) && (
            <FdsScheduleListTable
              data={fdsSchedulesList}
              status={status}
              pageSize={pageSize}
              totalCount={totalSchedulesCount}
              onChange={onPageChange}
              refetchFdsSchedulesList={getFdsSchedulesList}
            />
          )}
        </Card>
      )}
    </div>
  );
}

export default FdsSchedulesList;
