import { Spin } from "antd";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

const defaultProps = {
  className: "",
  text: "",
};

function Loader({ text, className }) {
  return (
    <div className={`${className}`}>
      <Spin />
      {<span className="mr-4">{text}</span> || null}
    </div>
  );
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
