import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function ZoneIcon({ className }) {
  return (
    <svg
      id="zone-icon"
      data-name="zone-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.16 9.97"
      className={`w-4 ${className}`}
    >
      <path
        className="cls-1"
        fill="white"
        fillOpacity="0.85"
        d="m5.08.12H.19v9.78h9.78v-4.89h-4.89V.12Zm4.2,5.58v3.5H.88V.81h3.5v4.89h4.89Z"
      />
      <rect
        fill="white"
        fillOpacity="0.85"
        className="cls-1"
        x="6.31"
        y=".12"
        width="3.65"
        height="3.65"
      />
    </svg>
  );
}

ZoneIcon.propTypes = {
  className: PropTypes.string,
};

ZoneIcon.defaultProps = {
  className: "",
};
