import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useAttachments() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate({
        method: "POST",
        url: "/Attachments",
        data,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },
  });
}

export default useAttachments;
