import { useEffect } from "react";

import { Select, Spin } from "antd";

import useScheduleProperty from "../../../../api/hooks/useScheduleProperty";
import useScheduleSystems from "../../../../hooks/useScheduleSystems";

function SelectDeviceTypeInput() {
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();
  const isSelectedAllProperties = scheduleSystems.selectedPropertyId === "all";
  const isSelectedAllDevices = scheduleSystems.selectedDeviceType?.Id === "all";

  const {
    data: selectedProperties,
    status: deviceTypesStatus,
    refetch: getDeviceTypes,
  } = useScheduleProperty(
    {
      propertyId: scheduleSystems.selectedPropertyId,
      floorId: scheduleSystems.selectedFloorId,
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  let deviceTypes = null;
  if (selectedProperties) {
    if (Array.isArray(selectedProperties) && selectedProperties.length > 0) {
      const devices = selectedProperties.flatMap((property) =>
        property.Floors.flatMap((floor) =>
          floor.Systems.flatMap((system) =>
            system.Devices.map((device) => ({
              Id: device.DeviceTypeId,
              PublicName: device?.DeviceTypePublicName,
            })),
          ),
        ),
      );

      deviceTypes = [
        ...new Map(devices.map((device) => [device.Id, device])).values(),
      ];
    }
  }

  const systemsToDisplay = () => {
    let systems = [];

    if (isSelectedAllProperties) {
      systems = scheduleSystems.properties
        ?.map((property) =>
          property.Floors?.map((floor) => floor.Systems).flat(),
        )
        .flat();
    }

    if (
      scheduleSystems.selectedPropertyId &&
      !isSelectedAllProperties &&
      !scheduleSystems.selectedFloor
    ) {
      systems = scheduleSystems.selectedProperty?.Floors?.map(
        (floor) => floor.Systems,
      ).flat();
    }

    if (scheduleSystems.selectedPropertyId && scheduleSystems.selectedFloor) {
      systems = scheduleSystems.selectedFloor?.Systems;
    }

    if (isSelectedAllDevices) {
      return systems;
    }

    return systems
      ?.map((system) => {
        return {
          ...system,
          Devices: system?.Devices?.filter((device) => {
            return (
              device?.DeviceTypePublicName ===
              scheduleSystems.selectedDeviceType?.Name
            );
          }),
          hasDeviceType: system?.Devices?.some((device) => {
            return (
              device?.DeviceTypePublicName ===
              scheduleSystems.selectedDeviceType?.Name
            );
          }),
        };
      })
      .filter((el) => el.hasDeviceType);
  };

  useEffect(() => {
    setScheduleSystems((prevState) => ({
      ...prevState,
      systemsWithSelectedDevice: systemsToDisplay(),
    }));
  }, [scheduleSystems.selectedDeviceType?.Name]);

  const handleClick = () => {
    if (!deviceTypes && scheduleSystems.selectedPropertyId) {
      getDeviceTypes();
    }
  };

  const handleChange = (value, option) => {
    if (value) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        selectedDeviceType: { Id: value, Name: option?.label },
        selectedSystemsWithSelectedDevice: [],
        confirmedSystemsWithSelectedDevice: [],
        selectedDevicesUniqueIds: [],
        checkedSystemsKeys: [],
        checkedDevicesKeys: [],
      }));
    }
  };

  const handleClear = () => {
    setScheduleSystems((prevState) => ({
      ...prevState,
      selectedDeviceType: null,
      selectedSystemsWithSelectedDevice: [],
      confirmedSystemsWithSelectedDevice: [],
      selectedDevicesUniqueIds: [],
      checkedSystemsKeys: [],
      checkedDevicesKeys: [],
    }));
  };

  return (
    <Select
      allowClear
      className="w-full"
      disabled={!(scheduleSystems.selectedProperty || isSelectedAllProperties)}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      showSearch
      size="large"
      value={scheduleSystems?.selectedDeviceType?.Name || undefined}
      options={
        deviceTypes && deviceTypesStatus === "success"
          ? [
              {
                label: "All Devices",
                value: "all",
              },
              ...deviceTypes
                .filter(
                  (deviceType) =>
                    deviceType?.PublicName !== "Remote Water Meter Reader" &&
                    deviceType?.PublicName !== "Repeater",
                )
                .map((deviceType) => {
                  return {
                    label: deviceType?.PublicName,
                    value: deviceType?.Id,
                  };
                }),
            ]
          : []
      }
      placeholder="Device"
      getPopupContainer={(trigger) => trigger.parentNode}
      notFoundContent={
        deviceTypesStatus === "loading" ? (
          <div className="flex items-center justify-center p-8">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex items-center justify-center p-8">
            <span>No devices found</span>
          </div>
        )
      }
      onClick={handleClick}
      onChange={handleChange}
      onClear={handleClear}
    />
  );
}

export default SelectDeviceTypeInput;
