import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Alert, Card } from "antd";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import usePropertyWaterConsumptionPercentageMonthly from "../../../api/hooks/usePropertyWaterConsumptionPercentageMonthly";
import { any } from "../../../helpers/jsLinq";
import randomHexColor from "../../../helpers/randomHexColor";
import Loader from "../../Loader/Loader";

/** Water Consumption Chart | All Properties. Renders pie chart data by percentages % */
function WaterConsumption() {
  const { data, status } = usePropertyWaterConsumptionPercentageMonthly({
    cacheTime: 0,
  });
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const COLORS = data?.map(() => {
      return `${randomHexColor()}`;
    });
    setColors(COLORS);
  }, [data, setColors]);

  return (
    <Card
      headStyle={{ padding: 4 }}
      bodyStyle={{ padding: 4 }}
      title={
        <span className="page-title-mobile p-1">
          Water Consumption | All Properties
        </span>
      }
      bordered={false}
      className="text-14 p-1"
    >
      {status === "error" && (
        <div
          className="flex items-center justify-center"
          style={{ height: 150 }}
        >
          <Alert
            message="We have bad news"
            description="Еither we broke something or there is nothing to display."
            type="error"
            showIcon
            icon={<FrownOutlined />}
            className="w-fit"
          />
        </div>
      )}

      {status === "loading" && (
        <div style={{ height: 150 }}>
          <Loader className="absolute-center" />
        </div>
      )}

      {status === "success" && (
        <div className="flex">
          <div
            className="self-center w-1/3 flex flex-col mr-3 overflow-y-auto"
            style={{ height: 130 }}
          >
            {data.map((entry, index) => (
              <div className="inline-flex" key={entry.Name}>
                <div
                  className="w-5 h-2 mr-2 rounded-lg self-center pl-5"
                  style={{
                    backgroundColor: colors && colors[index % colors.length],
                  }}
                />
                <span
                  className="mb-0 self-center overflow-hidden text-ellipsis whitespace-nowrap"
                  title={entry.Name}
                >
                  {entry.WaterConsuptionPercentage}% {entry.Name}
                </span>
              </div>
            ))}
          </div>
          <div style={{ height: 150 }} className="w-3/5">
            <ResponsiveContainer className="w-full" height={150}>
              {any(data, (x) => x.WaterConsuptionPercentage > 0) ? (
                <PieChart>
                  <Pie
                    data={data}
                    innerRadius={60}
                    outerRadius={70}
                    cx="60%"
                    cy="50%"
                    paddingAngle={1}
                    stroke=""
                    dataKey="WaterConsuptionPercentage"
                  >
                    {data?.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.Name}`}
                        fill={colors && colors[index % colors.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              ) : (
                <div className="flex justify-center items-center">
                  <span className="page-title-mobile">no available data</span>
                </div>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </Card>
  );
}

export default WaterConsumption;
