import { Card } from "antd";
import PropTypes from "prop-types";
import "./scss/MenuTile.scss";
import { useNavigate } from "react-router-dom";

function MenuTile({ className, title, icon, navigateTo }) {
  const navigate = useNavigate();

  return (
    <Card
      className={`${className} menu-tile-card`}
      onClick={() => navigate(navigateTo)}
      bodyStyle={{ padding: 12, paddingTop: 18 }}
    >
      <div className="flex justify-center items-center flex-col">
        <span className="text-triple-wintergreen tracking-widest uppercase text-center text-13 md:text-10 xl:text-13 mb-3 truncate">
          {title}
        </span>
        <div className="menu-tile-icon">{icon}</div>
      </div>
    </Card>
  );
}

MenuTile.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  navigateTo: PropTypes.string,
};

MenuTile.defaultProps = {
  className: "",
  title: "",
  icon: null,
  navigateTo: "",
};
export default MenuTile;
