import format from "../../helpers/numbersHelper";
import useProfileSettings from "../../hooks/useProfileSettings";

function Water({ value, showUnitOfMeasure = true }) {
  const { userSettings } = useProfileSettings();
  const gallon = 0.264172;
  switch (userSettings.waterUnit) {
    case "Gallons": {
      const converted = Math.round(value * gallon);
      return showUnitOfMeasure
        ? `${format(converted)} gal`
        : `${format(converted)}`;
    }
    case "Litres":
    default:
      return showUnitOfMeasure ? `${format(value)} L` : `${format(value)}`;
  }
}

export default Water;
