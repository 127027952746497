import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandlePropertyFloorUpdate(
  formValues,
  floorId,
  updatePropertyFloorMutation,
  assignMultipleDevicesToFloorMutation,
  setIsFormDirty,
  setIsLoading,
) {
  let updatePropertyFloorResponse;
  let assignMultipleDevicesToFloorResponse;

  const updatePropertyFloorDto = {
    Name: formValues.Name,
  };

  const assignMultipleDevicesToFloorRequest = {
    AssignedDeviceIds: formValues?.Systems.AssignedSystems.flatMap(
      (x) => x.children,
    ).map((x) => x.key),
    UnassignedDeviceIds: formValues?.Systems.UnassignedSystems.flatMap(
      (x) => x.children,
    ).map((x) => x.key),
  };

  try {
    // Floor Name Update
    updatePropertyFloorResponse = await updatePropertyFloorMutation.mutateAsync(
      {
        floorId,
        request: updatePropertyFloorDto,
      },
    );

    // Device Assignment/Unassignment
    if (
      assignMultipleDevicesToFloorRequest.AssignedDeviceIds.length > 0 ||
      assignMultipleDevicesToFloorRequest.UnassignedDeviceIds.length > 0
    ) {
      assignMultipleDevicesToFloorResponse =
        await assignMultipleDevicesToFloorMutation.mutateAsync({
          floorId,
          request: assignMultipleDevicesToFloorRequest,
        });
    }
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could not update floor.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">floor update success</span>
      ),
      duration: 2,
      placement: "topRight",
    });
  }

  return { updatePropertyFloorResponse, assignMultipleDevicesToFloorResponse };
}

export default useHandlePropertyFloorUpdate;
