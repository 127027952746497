import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandleDeleteUser(useUserDeleteMutation, user) {
  let deleteUserResponse;
  try {
    deleteUserResponse = await useUserDeleteMutation.mutateAsync(
      user?.Username,
    );
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could Not Delete User.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    notification.success({
      message: <span className="uppercase tracking-widest">user deleted</span>,
      duration: 2,
      placement: "topRight",
    });
  }
  return deleteUserResponse;
}
export default useHandleDeleteUser;
