import { useContext } from "react";

import UserSettingsContext from "../context/UserSettingsProvider";

// hook to use the user settings context
const useProfileSettings = () => {
  return useContext(UserSettingsContext);
};

export default useProfileSettings;
