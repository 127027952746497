import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyFloorById(floorId, options, key = "getPropertyFloorById") {
  const axiosPrivate = useAxiosPrivate();

  const getFloorsByPropertyId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `/PropertyFloor/Overview/${floorId}`,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, floorId], () => getFloorsByPropertyId(floorId), {
    ...options,
  });
}

export default usePropertyFloorById;
