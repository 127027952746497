import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandleUpdateUser(
  userId,
  formValues,
  setIsLoading,
  setIsFormDirty,
  updateUserMutationFn,
) {
  let editUserResponse;
  // Remove extra whitespaces from a string and ensure there is only one space between.
  const nameParts = formValues.FullName.replace(/\s+/g, " ").trim().split(" ");
  const firstName = nameParts[0];
  // The rest of the array elements are joined back together to form the last name.
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const request = {
    UserId: userId,
    FirstName: firstName,
    LastName: lastName,
    PhoneNumber: formValues.PhoneNumber,
  };

  try {
    editUserResponse = await updateUserMutationFn.mutateAsync({
      userId: request.UserId,
      request,
    });
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could not update property.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">user update success</span>
      ),
      duration: 2,
      placement: "topRight",
    });
  }

  return editUserResponse;
}
export default useHandleUpdateUser;
