import { Row, Col, Card } from "antd";

import useEvents from "../../api/hooks/useEvents";
import useWindowSize from "../../hooks/useWindowSize";
import EventsLogTable from "../Events/EventsLogTable/EventsLogTable";
import Alarms from "./Alarms/Alarms";
import WaterConsumption from "./Charts/WaterConsumption";
import WaterSavings from "./Charts/WaterSavings";

function Dashboard() {
  const window = useWindowSize();
  const { data: events, status } = useEvents(
    { pageSize: 3, startIndex: 0 },
    {
      cacheTime: 0,
    },
    "getEventsOnDashboardPage",
  );

  return (
    <>
      {window?.width > 992 && <h1 className="page-title mb-6">Dashboard</h1>}
      <Card className="triple-plus-content rounded-2xl">
        <Row gutter={[30, 0]} className="mb-4 sm:mb-6">
          <Col xs={24}>
            <Alarms />
          </Col>
        </Row>
        <Row className="mb-6">
          <Col xs={24}>
            <EventsLogTable
              data={events}
              status={status}
              noPagination
              withHeader
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            xs={24}
            md={12}
            lg={12}
            xl={8}
            className="text-triple-white w-full my-1"
          >
            <WaterConsumption />
          </Col>
          <Col
            xs={24}
            md={12}
            lg={12}
            xl={8}
            className="text-triple-white w-full my-1"
          >
            <WaterSavings />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Dashboard;
