import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useSchedules(params, options, key = "getSchedules") {
  const axiosPrivate = useAxiosPrivate();

  const getSchedules = async () => {
    const schedules = await axiosPrivate({
      method: "GET",
      url: "Schedule/All/Overview",
      params,
    }).then((res) => res.data);
    return schedules;
  };

  return useQuery([key], () => getSchedules(params), {
    ...options,
  });
}

export default useSchedules;
