/* eslint-disable react/prop-types */
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useSearchParams } from "react-router-dom";

function AddSceneButton({ day, hour }) {
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const handleOnClick = () => {
    const startTime = hour > 9 ? `${hour}` : `0${hour}`;
    setSearchParams((params) => {
      params.set("day", day);
      params.set("startTime", startTime < 24 ? startTime : "00");
      return params;
    });
  };

  return (
    <Button
      className=" invisible group-hover:visible w-full h-full"
      type="link"
      size="small"
      onClick={handleOnClick}
    >
      <span>
        <PlusCircleOutlined className="text-triple-blue text-lg" />
      </span>
    </Button>
  );
}

export default AddSceneButton;
