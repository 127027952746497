import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useGetSystemsForFloorManagementWithDevices(
  floorId,
  params,
  options,
  key = "getSystemsForFloorManagementWithDevices",
) {
  const axiosPrivate = useAxiosPrivate();

  const getSystemsForFloorManagementWithDevices = async () => {
    const systemsToFloorWithDevices = await axiosPrivate({
      method: "GET",
      url: `Systems/GetForFloorManagementWithDevices/${floorId}`,
      params,
    }).then((res) => res.data);
    return systemsToFloorWithDevices;
  };

  return useQuery(
    [key, floorId, params],
    () => getSystemsForFloorManagementWithDevices(floorId, params),
    {
      ...options,
    },
  );
}

export default useGetSystemsForFloorManagementWithDevices;
