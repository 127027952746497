import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const RESOURCE_ENDPOINT = "/PropertyOverview";
function usePropertyOverviewCount(
  params,
  options,
  key = "getPropertyOverviewCount",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewCount = async () => {
    const count = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/Count`,
      params,
    }).then((res) => res.data);
    return count;
  };

  return useQuery([key, params], () => getPropertyOverviewCount(params), {
    ...options,
  });
}

function usePropertyOverviewMetadata(
  params,
  options,
  key = "getPropertyOverviewMetadata",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewMetadata = async () => {
    const metadataCollection = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/Metadata`,
      params,
    }).then((res) => res.data);
    return metadataCollection;
  };

  return useQuery([key, params], () => getPropertyOverviewMetadata(params), {
    ...options,
  });
}

function usePropertyOverviewStatuses(
  params,
  options,
  key = "getPropertyOverviewStatuses",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewStatuses = async () => {
    const statusCollection = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/Statuses`,
      params,
    }).then((res) => res.data);
    return statusCollection;
  };

  return useQuery([key, params], () => getPropertyOverviewStatuses(params), {
    ...options,
  });
}

function usePropertyOverviewWaterConsumption(
  params,
  options,
  key = "getPropertyOverviewWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewWaterConsumption = async () => {
    const waterConsumptionCollection = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/WaterConsumption`,
      params,
    }).then((res) => res.data);
    return waterConsumptionCollection;
  };

  return useQuery(
    [key, params],
    () => getPropertyOverviewWaterConsumption(params),
    {
      ...options,
    },
  );
}

function usePropertyOverviewFloorsCount(
  propertyId,
  params,
  options,
  key = "getPropertyOverviewFloorsCount",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewFloorsCount = async () => {
    const count = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${propertyId}/Floors/Count`,
      params,
    }).then((response) => response.data);

    return count;
  };

  return useQuery({
    queryFn: getPropertyOverviewFloorsCount,
    queryKey: [key, params],
    ...options,
  });
}

function usePropertyOverviewFloorsMetadata(
  propertyId,
  params,
  options,
  key = "getPropertyOverviewFloorsMetadata",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewFloorsMetadata = async () => {
    const floorsMetadata = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${propertyId}/Floors/Metadata`,
      params,
    }).then((response) => response.data);

    return floorsMetadata;
  };

  return useQuery({
    queryFn: getPropertyOverviewFloorsMetadata,
    queryKey: [key, params],
    ...options,
  });
}

function usePropertyOverviewFloorsStatuses(
  propertyId,
  params,
  options,
  key = "getPropertyOverviewFloorsStatuses",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewFloorsStatuses = async () => {
    const floorsStatuses = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${propertyId}/Floors/Statuses`,
      params,
    }).then((response) => response.data);

    return floorsStatuses;
  };

  return useQuery({
    queryFn: getPropertyOverviewFloorsStatuses,
    queryKey: [key, params],
    ...options,
  });
}

function usePropertyOverviewFloorsWaterConsumption(
  propertyId,
  params,
  options,
  key = "getPropertyOverviewFloorsWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyOverviewFloorsWaterConsumption = async () => {
    const floorsWaterConsumption = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${propertyId}/Floors/WaterConsumption`,
      params,
    }).then((response) => response.data);

    return floorsWaterConsumption;
  };

  return useQuery({
    queryFn: getPropertyOverviewFloorsWaterConsumption,
    queryKey: [key, params],
    ...options,
  });
}

export {
  usePropertyOverviewCount,
  usePropertyOverviewMetadata,
  usePropertyOverviewStatuses,
  usePropertyOverviewWaterConsumption,
  usePropertyOverviewFloorsCount,
  usePropertyOverviewFloorsMetadata,
  usePropertyOverviewFloorsStatuses,
  usePropertyOverviewFloorsWaterConsumption,
};
