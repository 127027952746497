export const getMaxWaterConsumptionValue = (data) => {
  if (!data) return 0;
  return data.reduce(
    (max, item) =>
      Number(item.WaterConsumption) > Number(max)
        ? Number(item.WaterConsumption)
        : Number(max),
    0,
  );
};

export function roundToNearest(value) {
  const numVal = Number(value);

  if (Number.isNaN(numVal)) return 0;

  let delta = 1000;
  if (numVal < 100) delta = 10;
  else if (numVal < 1000) delta = 100;

  return Math.max(Math.ceil(value / delta) * delta, delta);
}
