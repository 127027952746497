import { useQuery } from "@tanstack/react-query";

import useUser from "../useUser";

const useLoggedUser = () => {
  const { refetch: getLoggedUser } = useUser();
  const { data: response, isLoading } = useQuery({
    queryKey: ["getLoggedUser"],
    queryFn: getLoggedUser,
    staleTime: 1000 * 60 * 10, // data is fresh for 10 mins
    cacheTime: 1000 * 60 * 10, // remove from cache after 10 mins
  });
  const user = response?.data || response;

  return { user, isLoading };
};

export default useLoggedUser;
