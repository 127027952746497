import { Card } from "antd";

import {
  FloorManagementIcon,
  FlowManagementIcon,
  PropertyPreferencesIcon,
  ScheduleManagementIcon,
} from "../../../assets/icons/preferences-tiles-icons";
import useWindowSize from "../../../hooks/useWindowSize";
import BackButton from "../../Shared/BackButton";
import MenuTile from "../../Shared/MenuTile";

function PropertyPreferencesMenuPage() {
  const window = useWindowSize();

  return (
    <>
      <BackButton
        redirect={{ to: "/preferences" }}
        confirmation={false}
        className="uppercase tracking-widest mr-4"
      />
      <div className="flex md:items-center flex-col-reverse md:flex-row">
        {window?.width > 768 && (
          <h1 className="page-title text-center sm:text-left !text-triple-blue">
            Property Management
          </h1>
        )}

        {window?.width <= 768 && (
          <h4 className="page-title-mobile text-center sm:text-left uppercase !text-triple-blue">
            Property Management
          </h4>
        )}
      </div>
      <Card className="triple-plus-content rounded-2xl">
        <div className="flex md:flex-row flex-col md:items-start items-center gap-5">
          <MenuTile
            title="Property"
            icon={<PropertyPreferencesIcon />}
            navigateTo="/preferences/property-management/properties"
          />
          <MenuTile
            title="Floor Management"
            icon={<FloorManagementIcon />}
            navigateTo="/preferences/property-management/floor-management/properties"
          />
          <MenuTile
            title="Flow"
            icon={<FlowManagementIcon />}
            navigateTo="/settings/flow-configuration"
          />
          <MenuTile
            title="Schedule"
            icon={<ScheduleManagementIcon />}
            navigateTo="/settings/schedule"
          />
        </div>
      </Card>
    </>
  );
}
export default PropertyPreferencesMenuPage;
