import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUsers = (options, key = "getTotalAndUsedDedicatedLicenses") => {
  const axiosPrivate = useAxiosPrivate();
  const getTotalAndUsedDedicatedLicenses = async () => {
    const licensesCounts = await axiosPrivate({
      method: "GET",
      url: "Users/Management/GetTotalAndUsedDedicatedLicenses",
    }).then((response) => response.data);
    return licensesCounts;
  };

  return useQuery([key], () => getTotalAndUsedDedicatedLicenses(), {
    ...options,
  });
};

export default useUsers;
