import PropTypes from "prop-types";

function FloorIcon({ className = "" }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#a)">
        <path
          d="M3.022 14.034h3.023v-3.022h3.023V7.989h3.023V4.966h3.023"
          stroke="#fff"
          strokeOpacity=".85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="white"
            transform="translate(0 .432)"
            d="M0 0h18.137v18.137H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

FloorIcon.propTypes = {
  className: PropTypes.string,
};

FloorIcon.defaultProps = {
  className: "",
};

export default FloorIcon;
