import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useAlertsByPropertyId(params, options, key = "getAlertsByPropertyId") {
  const axiosPrivate = useAxiosPrivate();

  const getAlertsByPropertyId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `/Status/GetAlertsForProperty/${params?.propertyId}`,
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getAlertsByPropertyId(params), {
    ...options,
  });
}

export default useAlertsByPropertyId;
