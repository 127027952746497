import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useFlowRateRange = (params) => {
  const axiosPrivate = useAxiosPrivate();

  const getFlowRateRange = async () => {
    const range = await axiosPrivate({
      method: "GET",
      url: "/Schedule/Fds/FlowRateRange",
      params,
    }).then((res) => res.data);
    return range;
  };

  return useQuery(
    ["getFlowRateRange", params],
    () => getFlowRateRange(params),
    {
      enabled: true,
    },
  );
};

export default useFlowRateRange;
