import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useDetailsByDeviceId(deviceId, options, key = "getDetailsByDeviceId") {
  const axiosPrivate = useAxiosPrivate();

  const getDetailsByDeviceId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `Device/Details/${deviceId}`,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, deviceId], () => getDetailsByDeviceId(deviceId), {
    ...options,
  });
}

export default useDetailsByDeviceId;
