export const any = (arr, fn = Boolean) => arr.some(fn);

export const groupBy = (array, key) => {
  return array?.reduce((result, currentValue) => {
    const groupKey = currentValue[key];
    if (!result[groupKey]) {
      // eslint-disable-next-line no-param-reassign
      result[groupKey] = [];
    }

    result[groupKey].push(currentValue);

    return result;
  }, {});
};

export function flattenGroupedArray(obj) {
  const result = [];

  function flatten(obj, parentKey = "") {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => {
            result.push({
              ...item,
              group: parentKey ? `${parentKey}.${key}` : key,
            });
          });
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          flatten(obj[key], parentKey ? `${parentKey}.${key}` : key);
        }
      });
    }
  }

  flatten(obj);
  return result;
}
