/* eslint-disable react/prop-types */
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tree } from "antd";

import { HubIcon } from "../../../assets/icons/systems";
import useScheduleSystems from "../../../hooks/useScheduleSystems";

function SystemsTree({ className = "" }) {
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();

  const treeData = [
    {
      title: "All Systems",
      key: "0-0",
      children: scheduleSystems.systemsWithSelectedDevice?.map(
        (system, index) => {
          return {
            title: (
              <span className="flex items-center">
                <HubIcon className="mx-2" />
                {system.Name}
              </span>
            ),
            key: `0-0-${index}`,
          };
        },
      ),
    },
  ];

  return (
    <div className={`schedule-tree ${className}`}>
      <h3 className="text-triple-white uppercase tracking-widest font-light mb-4">
        Systems
      </h3>

      {/* {loading && "Loading..."} */}

      {scheduleSystems.systemsWithSelectedDevice?.length > 0 && (
        <Tree
          className="bg-[#303343]"
          checkable
          defaultExpandedKeys={["0-0"]}
          defaultSelectedKeys={["0-0"]}
          treeData={treeData}
          checkedKeys={scheduleSystems?.checkedSystemsKeys}
          onCheck={(keys) => {
            if (keys?.length === 0) {
              setScheduleSystems((prevState) => ({
                ...prevState,
                selectedSystemsWithSelectedDevice: [],
                selectedDevicesUniqueIds: [],
                checkedSystemsKeys: keys,
              }));
            } else if (
              keys?.length > scheduleSystems.systemsWithSelectedDevice.length
            ) {
              setScheduleSystems((prevState) => ({
                ...prevState,
                selectedSystemsWithSelectedDevice:
                  scheduleSystems.systemsWithSelectedDevice,
                checkedSystemsKeys: keys,
              }));
            } else {
              setScheduleSystems((prevState) => ({
                ...prevState,
                selectedSystemsWithSelectedDevice: keys.map((key) => {
                  const index = Number(key.slice(key.lastIndexOf("-") + 1));
                  return scheduleSystems.systemsWithSelectedDevice[index];
                }),
                checkedSystemsKeys: keys,
              }));
            }
          }}
        />
      )}

      {scheduleSystems.systemsWithSelectedDevice?.length === 0 && (
        <div className="flex h-full items-center justify-center text-triple-white font-light text-sm">
          <InfoCircleOutlined className="mr-2" />
          Choose property and device type to see available systems
        </div>
      )}
    </div>
  );
}

export default SystemsTree;
