import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useLicenseFeaturesForUser(
  options,
  key = "getAvailableFeaturesForUser",
) {
  const axiosPrivate = useAxiosPrivate();

  const getAvailableFeaturesForUser = async () => {
    const schedules = await axiosPrivate({
      method: "GET",
      url: "LicenseFeatures/GetAvailableFeaturesForUser",
    }).then((res) => res.data);
    return schedules;
  };

  return useQuery([key], () => getAvailableFeaturesForUser(), {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: 1000 * 60 * 10,
  });
}

export default useLicenseFeaturesForUser;
