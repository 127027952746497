import { useState, useEffect } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";
import Cookies from "js-cookie";

import { refreshAccessToken } from "../api/requests/auth";
import useAuth from "./useAuth";
import useLogout from "./useLogout";

// hook to refresh the access_token of the logged user
const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const [unauthorized, setUnauthorized] = useState(false);
  const logout = useLogout();

  useEffect(() => {
    if (unauthorized) {
      if (sessionStorage.getItem("previouslyVisited")) {
        notification.error({
          message: (
            <span className="uppercase tracking-widest">Session expired</span>
          ),
          description:
            "We know this might be annoying, but we value privacy a tad more than convenience",
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
          duration: 5,
        });
      }
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorized]);

  async function refreshToken() {
    try {
      const currentRefreshToken = Cookies.get("refreshToken");
      const response = await refreshAccessToken(currentRefreshToken);
      setAuth({ accessToken: response.access_token, isAuthenticated: true });
      return response;
    } catch (Error) {
      setUnauthorized(true);
      return null;
    }
  }
  return refreshToken;
};

export default useRefreshToken;
