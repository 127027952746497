import { useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import useAuth from "./useAuth";

// hook to logout the user
const useLogout = () => {
  const queryClient = useQueryClient();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    setAuth({ isAuthenticated: false });
    Cookies.remove("refreshToken");
    queryClient.clear();
    navigate("/login");
  };

  return logout;
};
export default useLogout;
