import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function RemoteWaterMeterReaderIcon({ className }) {
  return (
    <svg
      width="1.75em"
      height="1.75em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      fill="white"
      className={className}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M519.5,379.6c-16.3,0.1-38.6,1-51,4.4c-3.9-2.6-8.5-4.1-13.5-4.1c-13.3,0-24.1,10.8-24.1,24.1      c0,13.3,10.8,24.1,24.1,24.1c10.2,0,18.9-6.3,22.4-15.2c12-4.5,30.8-17.5,44.1-27.4C524.8,383.3,523.5,379.6,519.5,379.6z" />
        <path d="M470.5,242.3v-52.6c0-72-58.6-130.6-130.6-130.6c-72,0-130.6,58.6-130.6,130.6v428.9c0,72,58.6,130.6,130.6,130.6      c72,0,130.6-58.6,130.6-130.6v-61.7c80.2-7.6,142.9-75.1,142.9-157.3S550.7,249.9,470.5,242.3z M440.5,618.6      c0,55.4-45.1,100.6-100.6,100.6S239.4,674,239.4,618.6V189.7c0-55.4,45.1-100.6,100.6-100.6s100.6,45.1,100.6,100.6v52.6      c-80.3,7.5-143.1,75-143.1,157.3s62.9,149.8,143.1,157.3V618.6z M470.2,530.7v-28.6c0-8.2-6.6-14.8-14.8-14.8      c-8.2,0-14.9,6.6-14.9,14.8v28.6c-24.8-2.8-48.1-12.5-67.4-27.9l20.2-20.2c5.8-5.8,5.8-15.2,0-21v0c-5.8-5.8-15.2-5.8-21,0      l-20.2,20.2c-15.5-19.4-25.1-42.8-27.9-67.4h28.6c8.2,0,14.9-6.6,14.9-14.8v0c0-8.2-6.6-14.8-14.9-14.8h-28.6      c2.8-24.6,12.4-48,27.9-67.4l20.2,20.2c5.8,5.8,15.2,5.8,21,0v0c5.8-5.8,5.8-15.2,0-21l-20.2-20.2      c19.3-15.4,42.6-25.1,67.4-27.9V297c0,8.2,6.6,14.8,14.9,14.8c8.2,0,14.8-6.6,14.8-14.8v-28.6c24.8,2.8,48.1,12.5,67.4,27.9      l-20.2,20.2c-5.8,5.8-5.8,15.2,0,21v0c5.8,5.8,15.2,5.8,21,0l20.2-20.2c15.5,19.4,25.1,42.8,27.9,67.4H558      c-8.2,0-14.9,6.6-14.9,14.8v0c0,8.2,6.6,14.8,14.8,14.8h28.6c-2.8,24.6-12.4,48-27.9,67.4l-20.2-20.2c-5.8-5.8-15.2-5.8-21,0v0      c-5.8,5.8-5.8,15.2,0,21l20.2,20.2C518.3,518.2,495,527.9,470.2,530.7z" />
      </g>
    </svg>
  );
}

RemoteWaterMeterReaderIcon.propTypes = {
  className: PropTypes.string,
};

RemoteWaterMeterReaderIcon.defaultProps = {
  className: "",
};
