import PropTypes from "prop-types";

function ShutOffIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      className={className}
      width="50"
      height="50"
    >
      <g id="Group_58" data-name="Group 58">
        <g id="Group_65" data-name="Group 65">
          <g id="Rectangle_3_copy_10" data-name="Rectangle 3 copy 10">
            <path
              fill={className === "active" ? "#06a5d3" : "#303136"}
              strokeWidth="0px"
              d="M143.08,48.66H57.03c-28.45,0-51.52,23.07-51.52,51.52s23.07,51.52,51.52,51.52h86.05c28.46,0,51.52-23.07,51.52-51.52s-23.07-51.52-51.52-51.52Z"
            />
          </g>
          <g id="Shape_1039" data-name="Shape 1039">
            <path
              fill="#fff"
              strokeWidth="0px"
              d="M150.7,88.71h-26.43c-.35,0-.69-.15-.92-.41-4.22-4.65-9.65-7.88-15.63-9.37-.56-.14-.96-.63-.96-1.21v-3.22c0-.69.56-1.24,1.24-1.24h30.68c3.37,0,6.11-2.73,6.11-6.11v-.33c0-2.65-2.13-4.81-4.77-4.86l-48.99-2.5h-.15c-2.68,0-4.86,2.18-4.86,4.86v2.84c0,3.37,2.73,6.11,6.11,6.11.69,0,1.24.56,1.24,1.24v3.22c0,.58-.4,1.07-.96,1.21-5.98,1.49-11.41,4.72-15.63,9.37-.24.26-.57.41-.92.41h-26.43c-.69,0-1.24.56-1.24,1.24v39.01c0,.69.56,1.24,1.24,1.24h26.43c.35,0,.68.15.92.41,5.97,6.58,14.35,10.31,23.29,10.31s17.32-3.73,23.29-10.31c.24-.26.57-.41.92-.41h26.43c.69,0,1.24-.56,1.24-1.24v-39.01c0-.69-.56-1.24-1.24-1.24ZM146.1,123.12c0,.69-.56,1.24-1.24,1.24h-23.31c-.38,0-.74.17-.97.47l-.5.63c-4.9,6.12-12.19,9.63-20.01,9.63s-15.11-3.51-20.01-9.63l-.5-.63c-.24-.29-.59-.47-.97-.47h-23.31c-.69,0-1.24-.56-1.24-1.24v-27.32c0-.69.56-1.24,1.24-1.24l24.28-.47.5-.63c4.9-6.12,12.19-9.63,20.01-9.63s15.11,3.51,20.01,9.63l.5.63c.24.29.59.47.97.47h23.31c.69,0,1.24.56,1.24,1.24v27.32Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

ShutOffIcon.propTypes = {
  className: PropTypes.string,
};

ShutOffIcon.defaultProps = {
  className: "",
};

export default ShutOffIcon;
