function ExclamationMarkIcon() {
  return (
    <svg
      width="77"
      height="137"
      viewBox="0 0 77 137"
      xmlns="http://www.w3.org/2000/svg"
      className="p-3"
      fill="none"
    >
      <path
        stroke="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3,14.738l6.693,78.028H45.4l6.693-78.028H25.3Zm13.386,86.707c-5.341,0-9.672,4.592-9.672,10.257s4.33,10.269,9.672,10.269,9.676-4.6,9.676-10.269S44.035,101.444,38.688,101.444Z"
      />
    </svg>
  );
}

export default ExclamationMarkIcon;
