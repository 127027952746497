import { Form, Select, TimePicker } from "antd";

import DateConstants from "../../../../constants/DateConstants";

function PropertyPreferencesWorkingDays() {
  return (
    <>
      <p className="mb-2">Working Days Routine Setup</p>
      <div className="inline-flex w-full">
        <Form.Item name="WorkingDaysStartDay" className="w-full">
          <Select
            placeholder="Start Day"
            className="working-days-picker"
            options={Object.values(DateConstants.fullyQualifiedDaysOfWeek).map(
              (day, i) => {
                return { value: i, label: day };
              },
            )}
          />
        </Form.Item>
        <span className="divider-To m-2">To</span>
        <Form.Item name="WorkingDaysEndDay" className="w-full">
          <Select
            placeholder="End Day"
            className="working-days-picker"
            options={Object.values(DateConstants.fullyQualifiedDaysOfWeek).map(
              (day, i) => {
                return { value: i, label: day };
              },
            )}
          />
        </Form.Item>
      </div>
      <div className="inline-flex w-full">
        <Form.Item name="WorkingDaysStartTime" className="w-full">
          <TimePicker
            className="working-days-timepicker w-full"
            format="HH:mm"
            inputReadOnly
            placeholder="Start Time"
            showNow={false}
          />
        </Form.Item>
        <span className="divider-To m-2">To</span>
        <Form.Item name="WorkingDaysEndTime" className="w-full">
          <TimePicker
            className="working-days-timepicker w-full"
            format="HH:mm"
            inputReadOnly
            placeholder="End Time"
            size="middle"
            showNow={false}
          />
        </Form.Item>
      </div>
    </>
  );
}
export default PropertyPreferencesWorkingDays;
