import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUsersGetDedicatedLicenses = (
  options,
  key = "getDedicatedLicensesForManagement",
) => {
  const axiosPrivate = useAxiosPrivate();
  const getDedicatedLicensesForManagement = async () => {
    const dedicatedLicenses = await axiosPrivate({
      method: "GET",
      url: "Users/Management/GetDedicatedLicensesForManagement",
    }).then((response) => response.data);
    return dedicatedLicenses;
  };

  return useQuery([key], () => getDedicatedLicensesForManagement(), {
    ...options,
  });
};

export default useUsersGetDedicatedLicenses;
