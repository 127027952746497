import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Tooltip } from "antd";

import { SystemIcon } from "../../../../../assets/icons/systems";
import EventIcon from "../../../../Events/EventIcon";

const columns = [
  {
    title: "System",
    width: 80,
    render: (record) => (
      <div className="inline-flex items-center cursor-pointer">
        {record?.Name && (
          <>
            <SystemIcon className="mr-2" />
            <Popover
              color="#363346"
              overlayInnerStyle={{
                borderColor: "#ffffff",
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                fontWeight: 400,
                maxWidth: 300,
              }}
              content={
                <div className="flex flex-col">
                  {record?.devicesOnOtherFloors &&
                  record?.devicesOnOtherFloors?.length > 0 ? (
                    record.devicesOnOtherFloors?.map((item, index) => {
                      return (
                        <p key={item.Id}>
                          {`${index + 1}.Device ${
                            item.PublicTypeName
                          } with Id ${item.Id} from System ${
                            record.Name
                          } is currently assigned to ${item.PropertyFloorName}`}
                        </p>
                      );
                    })
                  ) : (
                    <p className="text-center px-2 pt-2">No Info</p>
                  )}
                </div>
              }
              destroyTooltipOnHide
            >
              <div className="inline-flex">
                {`(${record.CurrentFloorDeviceCount}/${record?.totalFloorDeviceCount})`}
                <InfoCircleOutlined className="ml-1" />
              </div>
            </Popover>
          </>
        )}
        {record?.PublicTypeName && (
          <EventIcon
            key={record?.Id}
            iconName={record?.PublicTypeName}
            className="ml-4"
            defaultIcon={<span>*icon*</span>}
          />
        )}
      </div>
    ),
  },
  {
    title: "Name",
    width: 80,
    ellipsis: true,
    render: (record) => (
      <Tooltip
        color="#363346"
        title={record?.Name || record?.PublicTypeName || "N/A"}
      >
        <span>{record?.Name || record?.PublicTypeName || "N/A"}</span>
      </Tooltip>
    ),
  },
  {
    title: "Serial Number",
    width: 115,
    render: (record) => record?.Hub?.SerialNumber || record?.SerialNumber,
  },
  {
    title: "Location",
    width: 70,
    ellipsis: true,
    render: (record) => (
      <Tooltip
        color="#363346"
        title={record?.Hub?.Location || record?.Location || "N/A"}
      >
        <span>{record?.Hub?.Location || record?.Location || "N/A"}</span>
      </Tooltip>
    ),
  },
];

const dataStructure = (systems) => {
  const assignedSystems = systems.AssignedSystems.map((system) => ({
    ...system,
    assigned: true,
  }));
  const unassignedSystems = systems.UnassignedSystems.map((system) => ({
    ...system,
    assigned: false,
  }));
  const allSystems = [...assignedSystems, ...unassignedSystems].map(
    (system) => {
      const { Devices, DevicesOnOtherFloors, ...System } = system;
      // Replace Devices[] with children[] property
      const children = (Devices || []).map((device) => ({
        key: device.Id,
        parentKey: System.Id,
        ...device,
      }));
      return {
        key: System.Id,
        ...System,
        devicesOnOtherFloors: DevicesOnOtherFloors,
        totalFloorDeviceCount: Devices.length + DevicesOnOtherFloors.length,
        children,
      };
    },
  );
  return allSystems;
};

const deviceCounter = (dataArray) => {
  return dataArray.reduce((acc, obj) => {
    return acc + (obj.children ? obj.children.length : 0);
  }, 0);
};

const renderTitle = (tableData, titleText) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span>
        {deviceCounter(tableData)}
        <span style={{ marginLeft: "0.5em" }}>Devices</span>
      </span>
      <span style={{ textTransform: "uppercase" }}>{titleText}</span>
    </div>
  );
};

const filterLeftSource = (dataSource, newTargetKeys) => {
  const filtered = dataSource
    .map((system) => ({
      ...system,
      children: system.children.filter(
        (child) => !newTargetKeys.includes(child.key),
      ),
    }))
    .filter(
      (system) =>
        !newTargetKeys.includes(system.key) || system.children.length > 0,
    )
    .reduce((acc, system) => {
      if (!acc[system.key]) {
        acc[system.key] = { ...system, children: [...system.children] };
      } else {
        // Merge unique children for duplicate parent keys
        const existingChildrenKeys = new Set(
          acc[system.key].children.map((child) => child.key),
        );
        acc[system.key].children.push(
          ...system.children.filter(
            (child) => !existingChildrenKeys.has(child.key),
          ),
        );
      }
      return acc;
    }, {});

  return Object.values(filtered);
};

const filterRightSource = (dataSource, newTargetKeys) => {
  const filtered = dataSource
    .map((system) => ({
      ...system,
      children: system.children.filter((child) =>
        newTargetKeys.includes(child.key),
      ),
    }))
    .filter(
      (system) =>
        newTargetKeys.includes(system.key) || system.children.length > 0,
    )
    .reduce((acc, system) => {
      if (!acc[system.key]) {
        acc[system.key] = { ...system, children: [...system.children] };
      } else {
        // Merge unique children for duplicate parent keys
        const existingChildrenKeys = new Set(
          acc[system.key].children.map((child) => child.key),
        );
        acc[system.key].children.push(
          ...system.children.filter(
            (child) => !existingChildrenKeys.has(child.key),
          ),
        );
      }
      return acc;
    }, {});

  return Object.values(filtered);
};

const flattenTree = (nodes) => {
  const flatList = [];
  const flatten = (node, parentKey = null) => {
    flatList.push({
      key: node.key,
      Name: node.Name,
      Hub: node.Hub,
      parentKey,
    });
    if (node.children) {
      node.children.forEach((child) => {
        flatten(child, node.key);
      });
    }
  };
  nodes.forEach((node) => flatten(node));
  return flatList;
};

const deviceTypeOptions = [
  { label: "Flood Detector", value: 1 },
  { label: "Water Shutoff", value: 2 },
  { label: "Repeater", value: 3 },
  { label: "Hub", value: 4 },
  { label: "Gas Valve", value: 5 },
  { label: "Gas Sensor", value: 6 },
  { label: "CLM Link", value: 7 },
  { label: "Flow Sensor", value: 8 },
  { label: "Acoustic Sensor", value: 9 },
  { label: "Magnetic Sensor", value: 10 },
  { label: "Smoke Sensor", value: 11 },
  { label: "Irrigation Controller", value: 12 },
  { label: "Irrigation Station", value: 13 },
  { label: "Heiman Magnetic Sensor", value: 14 },
  { label: "Heiman Motion Sensor", value: 15 },
  { label: "Heiman Combustible Gas Sensor", value: 16 },
  { label: "Heiman Carbon Monoxide Sensor", value: 17 },
  { label: "Heiman Smoke Sensor", value: 18 },
  { label: "Trust LED Bulb", value: 19 },
  { label: "Trust LED Spot", value: 20 },
  { label: "Heiman Smart Metering Plug", value: 21 },
  { label: "Spruce Soil Moisture Sensor", value: 22 },
  { label: "Heiman Emergency Button", value: 23 },
  { label: "Zone Container", value: 24 },
  { label: "Zone Container Safe@Home", value: 25 },
  { label: "Remote Water Meter Reader", value: 26 },
  { label: "Irrigation Controller Standalone", value: 27 },
  { label: "Heiman Air Quality Monitor", value: 28 },
];

const updateTransferList = (responseData) => {
  const allSystems = dataStructure(responseData);
  const allRowKeys = allSystems.map((system) => system.key);
  const assignedSystems = allSystems.filter((x) => x.assigned);
  const unassignedSystems = allSystems.filter((x) => !x.assigned);
  const flattenTargetKeys = flattenTree(unassignedSystems).map((x) => x.key);

  return {
    allSystems,
    allRowKeys,
    assignedSystems,
    unassignedSystems,
    flattenTargetKeys,
  };
};

export {
  columns,
  dataStructure,
  deviceCounter,
  renderTitle,
  filterLeftSource,
  filterRightSource,
  flattenTree,
  deviceTypeOptions,
  updateTransferList,
};
