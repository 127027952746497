/* eslint-disable no-unused-vars */
import { useState } from "react";

import { Button, Menu, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import useGetAvailableFeatures from "../../api/hooks/customHooks/useGetAvailableFeatures";
import {
  AnalyticsIcon,
  DashboardIcon,
  EventsIcon,
  FlowConfigurationIcon,
  LogoutIcon,
  ProfileIcon,
  PropertyOverviewIcon,
  ReportsIcon,
  ScheduleIcon,
  SettingsIcon,
  SystemPreferencesIcon,
} from "../../assets/icons/sidebar";
import { groupBy } from "../../helpers/jsLinq";
import useLogout from "../../hooks/useLogout";
import useWindowSize from "../../hooks/useWindowSize";
import Profile from "../Profile/Profile";
import "./Sidebar.scss";

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = { className: "" };

function Sidebar({ className }) {
  const window = useWindowSize();
  const logout = useLogout();
  const { data } = useGetAvailableFeatures();
  const userFeaturesGroupedData = groupBy(
    data?.AvailableFeatures,
    "FeatureArea",
  );

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const onProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const navigationLookup = {
    Dashboard: {
      label: (
        <NavLink to="/" className="nav-link" end>
          <DashboardIcon />
          {t("sidebar.dashboard")}
        </NavLink>
      ),
      key: "dashboard",
      icon: null,
      children: null,
      type: "Dashboard",
      order: 1,
    },
    PropertyOverview: {
      label: (
        <NavLink to="/property-overview" className="nav-link">
          <PropertyOverviewIcon />
          {t("sidebar.property_overview")}
        </NavLink>
      ),
      key: "property-overview",
      icon: null,
      children: null,
      type: "Property Overview",
      order: 2,
    },
    Statistics: {
      label: (
        <NavLink to="/statistics" className="nav-link">
          <AnalyticsIcon />
          {t("sidebar.statistics")}
        </NavLink>
      ),
      key: "statistics",
      icon: null,
      children: null,
      type: "Statistics",
      order: 3,
    },
    Reports: {
      label: (
        <NavLink to="/reports" className="nav-link">
          <ReportsIcon />
          {t("sidebar.reports")}
        </NavLink>
      ),
      key: "reports",
      icon: null,
      children: null,
      type: "Reports",
      order: 4,
    },
    Events: {
      label: (
        <NavLink to="/events" className="nav-link">
          <EventsIcon />
          {t("sidebar.events")}
        </NavLink>
      ),
      key: "events",
      icon: null,
      children: null,
      type: "Events",
      order: 5,
    },
    Settings: {
      label: (
        <NavLink to="/settings" className="nav-link">
          <SettingsIcon />
          {t("sidebar.settings.settings")}
        </NavLink>
      ),
      key: "settings",
      icon: null,
      type: "Settings",
      order: 6,
    },
    Preferences: {
      label: (
        <NavLink to="/preferences" className="nav-link">
          <SystemPreferencesIcon />
          {t("sidebar.preferences.preferences")}
        </NavLink>
      ),
      key: "preferences",
      icon: null,
      type: "Preferences",
      order: 97,
    },
    Profile: {
      label: (
        <Button type="link" onClick={onProfileClick} className="nav-link">
          <ProfileIcon />
          Profile
        </Button>
      ),
      key: "profile",
      icon: null,
      children: null,
      type: "Profile",
      order: 98,
    },
    Logout: {
      label: (
        <NavLink to="/login" className="nav-link" onClick={logout}>
          <LogoutIcon />
          Logout
        </NavLink>
      ),
      key: "logout",
      icon: null,
      children: null,
      type: "Logout",
      order: 99,
    },
  };

  const menuItems = [];
  // Core Features (Logout & Profile)
  menuItems.push(navigationLookup.Profile, navigationLookup.Logout);

  if (userFeaturesGroupedData) {
    Object.keys(userFeaturesGroupedData).forEach((key) => {
      const mappedMenuItem = navigationLookup[key.replace(/\s/g, "")];
      if (mappedMenuItem?.children) {
        const featuresPublicName = userFeaturesGroupedData[key].flatMap(
          (x) => x.FeaturePublicName,
        );

        // Filter the children based on userFeatures.groupedData Public Name
        const filteredChildren = mappedMenuItem.children.filter((child) =>
          featuresPublicName.includes(child.type),
        );

        // Create a new object with the filtered children property
        const filteredMappedMenuItem = {
          ...mappedMenuItem,
          children: filteredChildren,
        };

        menuItems.push(filteredMappedMenuItem);
      } else {
        menuItems.push(mappedMenuItem);
      }
    });
    menuItems.sort((a, b) => a.order - b.order);
  }

  return (
    <>
      <Menu
        className={`sidebar bg-triple-sidebar-new text-triple-white ${className}`}
        items={window?.width <= 992 ? menuItems : menuItems.slice(0, -2)}
        mode="inline"
      />
      <Modal
        className="profile-modal"
        maskClosable={false}
        okButtonProps={{
          size: "small",
        }}
        centered
        footer={null}
        okText="Save"
        open={isProfileModalOpen}
        keyboard={false}
        onCancel={() => setIsProfileModalOpen(false)}
      >
        <Profile />
      </Modal>
    </>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
