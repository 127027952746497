import { useEffect, useState } from "react";

import { Select, Row, Col, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import PropTypes from "prop-types";

import useWaterConsumptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import "./Filters.scss";

dayjs.extend(localizedFormat);

function Filters({ handleOnChangeFilterCallback }) {
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  const [deviceSelectDisabled, setDeviceSelectDisabled] = useState(true);
  // comment below applies to the following three states: selectedPropertyId, selectedFloorId & selectedSystemId
  // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [selectedDeviceUniqueId, setSelectedDeviceUniqueId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const propertyOptions = [
    {
      value: "",
      label: "All Properties",
    },
  ];
  const [floorOptions, setFloorOptions] = useState([
    { value: "", label: "All Floors" },
  ]);
  const [systemOptions, setSystemOptions] = useState([
    { value: "", label: "All Systems" },
  ]);
  const [deviceOptions, setDeviceOptions] = useState([
    { value: "", label: "Select Device" },
  ]);

  // Hooks
  const { data: propertiesObject } = useWaterConsumptionFilters();
  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Effects
  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
      selectedDeviceId,
      selectedDeviceUniqueId,
      selectedDate,
    );
  });

  // Effects
  useEffect(() => {
    if (
      selectedPropertyId !== "" &&
      propertiesObject &&
      propertiesObject.Properties
    ) {
      let property = null;
      const tempFloorOptions = [{ value: "", label: "All Floors" }];
      const tempSystemOptions = [{ value: "", label: "All Systems" }];
      const tempDeviceOptions = [{ value: "", label: "Select Device" }];

      propertiesObject.Properties.forEach((p) => {
        if (p.Id === selectedPropertyId) {
          property = p;
        }
      });

      if (property) {
        property.Floors?.forEach((f) => {
          tempFloorOptions.push({ value: f.Id, label: f.Name });

          if (f) {
            f?.Systems?.forEach((s) => {
              tempSystemOptions.push({ value: s.Id, label: s.Name });

              if (s) {
                s?.Devices?.forEach((d) =>
                  tempDeviceOptions.push({
                    value: d.Id,
                    label: d.UniqueId,
                  }),
                );
              }
            });
          } else if (selectedFloorId === "") {
            property?.Floors?.forEach((floor) => {
              floor.Systems.map((fs) =>
                tempSystemOptions.push({ value: fs.Id, label: fs.Name }),
              );
            });
          }
        });
      }

      setFloorOptions(tempFloorOptions);
      setSystemOptions(tempSystemOptions);
      setDeviceOptions(tempDeviceOptions);
    }
  }, [selectedPropertyId, selectedFloorId, propertiesObject]);

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
      setFloorSelectDisabled(true);
      setSystemSelectDisabled(true);
      setDeviceSelectDisabled(true);
    } else {
      setSelectedPropertyId(value);
      setFloorSelectDisabled(false);
      setSystemSelectDisabled(false);
      setDeviceSelectDisabled(false);
    }

    // on changing property filter option
    // set floor & system filter options to default
    setSelectedFloorId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedSystemId("");
    setSelectedDeviceId("");
    setSelectedDeviceUniqueId("");
  };

  const onChangeFloor = (value) => {
    if (value) {
      setSelectedFloorId(value);
    }

    // on changing floor filter option
    // set system filter options to default
    setSelectedSystemId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedDeviceId("");
    setSelectedDeviceUniqueId("");
  };

  const onChangeSystem = (value) => {
    if (value) {
      setSelectedSystemId(value);
    }

    setSelectedDeviceId("");
    setSelectedDeviceUniqueId("");
  };

  const onChangeDevice = (value) => {
    if (value) {
      setSelectedDeviceId(value);

      const deviceUniqueId = deviceOptions.filter((d) => d.value === value)[0];

      if (deviceUniqueId && deviceUniqueId.label) {
        setSelectedDeviceUniqueId(deviceUniqueId.label);
      } else {
        setSelectedDeviceId("");
        setSelectedDeviceUniqueId("");
      }
    } else {
      setSelectedDeviceId("");
      setSelectedDeviceUniqueId("");
    }
  };

  const onDateChange = (_, dateString) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Col className="bg-triple-blue rounded-md px-3 py-2 xs:h-32 sm:h-24 xl:h-20 xxxl:flex-col flex">
      <Row className="gap-1 w-fit">
        <Select
          size="small"
          showSearch
          placeholder="All Properties"
          optionFilterProp="property"
          onChange={onChangeProperty}
          filterOption={filterSelect}
          className="customer-filter-selectors xxxl:w-32"
          defaultValue=""
          options={propertyOptions}
        />
        <Select
          size="small"
          showSearch
          placeholder="All Floors"
          optionFilterProp="propertyFloor"
          onChange={onChangeFloor}
          filterOption={filterSelect}
          className="customer-filter-selectors xxxl:w-32"
          disabled={floorSelectDisabled}
          value={selectedFloorId}
          options={floorOptions}
        />
        <Select
          size="small"
          showSearch
          placeholder="All Systems"
          optionFilterProp="propertySystem"
          onChange={onChangeSystem}
          filterOption={filterSelect}
          className="customer-filter-selectors xxxl:w-32"
          disabled={systemSelectDisabled}
          value={selectedSystemId}
          options={systemOptions}
        />
        <Select
          size="small"
          showSearch
          placeholder="Select Device"
          optionFilterProp="propertyDevice"
          onChange={onChangeDevice}
          filterOption={filterSelect}
          className="customer-filter-selectors xxxl:w-32"
          disabled={deviceSelectDisabled}
          value={selectedDeviceId}
          options={deviceOptions}
        />
      </Row>
      <Row className="w-fit">
        <Form.Item name="date">
          <DatePicker
            className="bg-triple-sidebar-new h-6 xxxl:w-64"
            inputReadOnly
            placeholder="Select specific timeframe"
            value={selectedDate}
            onChange={(_, dateString) =>
              onDateChange(_, dateString, "ReportDate")
            }
            disabledDate={(current) => {
              if (current.isAfter(dayjs())) {
                return true;
              }

              return false;
            }}
            picker="month"
          />
        </Form.Item>
      </Row>
    </Col>
  );
}

Filters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
};
Filters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
};

export default Filters;
