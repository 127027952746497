import dayjs from "dayjs";

const PropertyPreferencesMapper = (destination, source) => {
  const dest = destination;
  dest.current.Id = source.Id;
  dest.current.Name = source.Name;
  dest.current.Address = source.Address;
  dest.current.ImageUrl = source.ImageUrl;
  dest.current.Floors = source.Floors.map((floor) => {
    return {
      Id: floor.Id,
      Name: floor.Name,
      PropertyId: floor.PropertyId,
    };
  });
  dest.current.NumberOfOccupants = source.PropertyPreferences.NumberOfOccupants;
  dest.current.CostOfWater = source.PropertyPreferences.CostOfWater;
  dest.current.Currency = source.PropertyPreferences.Currency;
  dest.current.WorkingDaysEnabled =
    source.PropertyPreferences.WorkingDaysEnabled;
  dest.current.WorkingDaysStartDay =
    source.PropertyPreferences.WorkingDaysStartDayOfWeek;
  dest.current.WorkingDaysEndDay =
    source.PropertyPreferences.WorkingDaysEndDayOfWeek;
  dest.current.WorkingDaysStartTime = dayjs(
    new Date(
      source.PropertyPreferences.WorkingDaysStartTime,
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    "HH:mm",
  );
  dest.current.WorkingDaysEndTime = dayjs(
    new Date(source.PropertyPreferences.WorkingDaysEndTime).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      },
    ),
    "HH:mm",
  );

  return dest;
};

export default PropertyPreferencesMapper;
