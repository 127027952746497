import PropTypes from "prop-types";
import { useLocation, Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = { children: null };

// protect private app routes
function RequireAuth({ children }) {
  const { auth } = useAuth();
  const location = useLocation();

  // CASE: user is logged in
  return auth?.isAuthenticated ? (
    // eslint-disable-next-line
    <>{children}</>
  ) : (
    // CASE: user is not logged in
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

RequireAuth.propTypes = propTypes;
RequireAuth.defaultProps = defaultProps;

export default RequireAuth;
