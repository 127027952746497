import { useState, useEffect, useRef } from "react";

import { Card, Form } from "antd";

import useWaterConsumptionReport from "../../api/hooks/useWaterConsumptionReport";
import WaterDropIcon from "../../assets/icons/WaterDropIcon";
import { isValidDate } from "../../helpers/dates";
import useProfileSettings from "../../hooks/useProfileSettings";
import useWindowSize from "../../hooks/useWindowSize";
import ReportsLayout from "./Layout/ReportsLayout";

function WaterConsumptionReport() {
  const [showData, setShowData] = useState(false);
  const [generateReportForm] = Form.useForm();
  const initialFormValues = useRef({});
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [aggregationLevel, setAggregationLevel] = useState(null);
  const [bucketType, setBucketType] = useState(null);
  const [includePreviousYear, setIncludePreviousYear] = useState(false);
  const [propertyNameField, setPropertyNameField] = useState(null);
  const [propertyAddressField, setPropertyAddressField] = useState(null);
  const [floorNameField, setFloorNameField] = useState(null);
  const [systemNameField, setSystemNameField] = useState(null);
  const [deviceNameField, setDeviceNameField] = useState(null);
  const [deviceSerialNumberField, setDeviceSerialNumberField] = useState(null);
  const [deviceLocationField, setDeviceLocationField] = useState(null);
  const [deviceTypeField, setDeviceTypeField] = useState(null);

  const { userSettings } = useProfileSettings();
  const window = useWindowSize();
  const [waterUOM, setWaterUOM] = useState(userSettings.waterUnit);

  useEffect(() => {
    setWaterUOM(userSettings.waterUnit);

    if (
      userSettings.waterUnit !== null &&
      waterUOM !== null &&
      waterUOM !== userSettings.waterUnit &&
      isValidDate(reportDate)
    ) {
      setShowData(true);
      generateReportForm.submit();
    }

    if (isValidDate(reportDate)) {
      setShowData(true);
      generateReportForm.submit();
    }
  }, [
    generateReportForm,
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
    userSettings.waterUnit,
    waterUOM,
  ]);

  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setReportDate(reportDate);
    setDateFormat(dateFormat);
    setAggregationLevel(aggregationLevel);
    setBucketType(bucketType);
    setIncludePreviousYear(includePreviousYear);
    setPropertyNameField(propertyNameField);
    setPropertyAddressField(propertyAddressField);
    setFloorNameField(floorNameField);
    setSystemNameField(systemNameField);
    setDeviceNameField(deviceNameField);
    setDeviceSerialNumberField(deviceSerialNumberField);
    setDeviceLocationField(deviceLocationField);
    setDeviceTypeField(deviceTypeField);
  };

  initialFormValues.current = {
    date: null,
    dateFormat: "yyyy MMM",
    aggregationLevel: "Device",
    bucketType: "year",
    includePreviousYear: false,
  };

  const handleFieldsChange = (changedFields) => {
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;
    if (fieldName === "date") {
      if (fieldValue === null || fieldValue === "") {
        generateReportForm.setFields([
          {
            name: "date",
            errors: ["Date field is required."],
          },
        ]);
      }
    }
  };

  const { refetch: getWaterConsumptionReport } = useWaterConsumptionReport(
    {
      filtersWithDeviceId: {
        deviceId: null,
        propertyId: propertyId === "" ? null : propertyId,
        floorId: floorId === "" ? null : floorId,
        systemId: systemId === "" ? null : systemId,
      },
      aggregationLevel,
      bucketType,
      date: new Date(reportDate),
      dateFormat,
      upToDate: false,
      includeBuckets: false,
      includeTotal: true,
      includePreviousYear,
      waterUOM,
      reportFileType: "CSV",
      fields: {
        PropertyName: propertyNameField,
        PropertyAddress: propertyAddressField,
        FloorName: floorNameField,
        SystemName: systemNameField,
        DeviceName: deviceNameField,
        DeviceSerialNumber: deviceSerialNumberField,
        DeviceLocation: deviceLocationField,
        DeviceType: deviceTypeField,
      },
    },
    { enabled: false },
  );

  return (
    <>
      <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
        {window?.width > 768 && (
          <h1 className="page-title mb-6">Water Consumption Report</h1>
        )}
      </div>
      <Card
        className="triple-plus-content rounded-2xl"
        bodyStyle={{ padding: "0.25rem" }}
      >
        <ReportsLayout
          iconTitle="Consumption"
          icon={
            <WaterDropIcon
              className="xs:w-12 xs:h-12 sm:w-8 sm:h-8 lg:w-6 lg:h-6 align-sub"
              strokeColor="#757782"
            />
          }
          fetchData={getWaterConsumptionReport}
          useGraph
          handleFieldsChange={handleFieldsChange}
          initialFormValues={initialFormValues}
          handleOnChangeFilterCallback={handleOnChangeFilterCallback}
          generateReportForm={generateReportForm}
          reportDate={reportDate}
          setReportDate={setReportDate}
          propertyId={propertyId}
          floorId={floorId}
          systemId={systemId}
          includePreviousYear={includePreviousYear}
          showData={showData}
        />
      </Card>
    </>
  );
}

export default WaterConsumptionReport;
