function PropertyPreferencesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208 256">
      <path
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M178.333,154.775H169.8v62l-27.6,17.27-0.144,34.622,27.747-.076v44.044h8.529V268.571l27.745-.075V234.048L178.333,216.69V154.775ZM197.55,259.966l-46.929.128,0.09-21.289,23.428-14.659,23.411,14.646v21.174Zm75.955-65.993H249.873v-39.2h-8.529v39.2H217.711v84.1h23.633v34.566h8.529V278.072h23.632v-84.1Zm-8.528,75.546H226.24V202.526h38.737v66.993Zm-14.038-59.948H240.278v10.69h10.661v-10.69Zm0,21.106H240.278v10.691h10.661V230.677Zm-82.2,21.381H179.4v-10.69H168.736v10.69Zm82.2-.273H240.278v10.69h10.661v-10.69Z"
      />
    </svg>
  );
}

export default PropertyPreferencesIcon;
