import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useScheduleById(programId, options, key = "getScheduleById") {
  const axiosPrivate = useAxiosPrivate();

  const getSchedules = async () => {
    const schedules = await axiosPrivate({
      method: "GET",
      url: `Schedule/Program/${programId}`,
    }).then((res) => res.data);
    return schedules;
  };

  return useQuery([key], () => getSchedules(), {
    ...options,
  });
}

export default useScheduleById;
