import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function HubIcon({ className }) {
  return (
    <svg
      width="1.75em"
      height="1.75em"
      viewBox="0 0 800 800"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M696.7,525.5l-11-312.8l0,0c-0.4-38.4-32-69.7-70.5-69.7h-439c-38.6,0-70.2,31.5-70.5,70l0,0L94.8,525.5      c-33.6,15.1-57.2,49-57.2,88.1v0c0,53.1,43.4,96.5,96.5,96.5h523.2c53.1,0,96.5-43.4,96.5-96.5v0      C753.9,574.5,730.3,540.7,696.7,525.5z M119.3,562.3L119.3,562.3l10.6-295.8c12.4,10.9,28.7,17.5,46.4,17.5h439      c17.8,0,34-6.7,46.4-17.6l10.6,301c0,0.4-0.1,0.8-0.1,1.1c0,11.8-4.7,23-13.1,31.4c-8.4,8.4-19.6,13.1-31.4,13.1h-464      c-11.8,0-23-4.7-31.4-13.1c-8.4-8.4-13.1-19.6-13.1-31.4C119.2,567,119.3,564.9,119.3,562.3z M144.8,182.1      c8.4-8.4,19.6-13.1,31.4-13.1h439c11.8,0,23,4.7,31.4,13.1c8.4,8.4,13.1,19.6,13.1,31.4s-4.7,23-13.1,31.4      c-8.4,8.4-19.6,13.1-31.4,13.1h-439c-11.8,0-23-4.7-31.4-13.1c-8.4-8.4-13.1-19.6-13.1-31.4S136.4,190.6,144.8,182.1z       M707.1,663.3c-13.4,13.4-31,20.7-49.8,20.7H134.1c-18.7,0-36.4-7.4-49.8-20.7c-13.4-13.4-20.7-31-20.7-49.8      c0-18.7,7.4-36.4,20.7-49.8c2.9-2.9,6.1-5.6,9.4-7.9l-0.2,6.3c-0.2,2.1-0.3,4.2-0.3,6.4c0,38.8,31.7,70.5,70.5,70.5h464      c38.8,0,70.5-31.7,70.5-70.5c0-1.7-0.1-3.3-0.2-5l0,0l-0.3-7.7c3.3,2.3,6.4,5,9.3,7.9c13.4,13.4,20.7,31,20.7,49.8      C727.9,632.3,720.5,650,707.1,663.3z"
      />
      <ellipse
        xmlns="http://www.w3.org/2000/svg"
        cx="395.8"
        cy="213.6"
        rx="16.5"
        ry="9.5"
        fill="white"
      />
    </svg>
  );
}

HubIcon.propTypes = {
  className: PropTypes.string,
};

HubIcon.defaultProps = {
  className: "",
};
