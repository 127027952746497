import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useWaterConsuptionFilters(
  params,
  options,
  key = "getWaterConsumptionFilters",
) {
  const axiosPrivate = useAxiosPrivate();

  const getWaterConsumptionFilters = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "/Statistics/WaterConsumption/Filters",
      params,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, params], () => getWaterConsumptionFilters(params), {
    ...options,
  });
}

export default useWaterConsuptionFilters;
