import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsBiennialWaterConsumption(
  year,
  params,
  options,
  key = "getBiennialWaterConsumptionData",
) {
  const axiosPrivate = useAxiosPrivate();
  const getBiennialWaterConsumptionData = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/Biennial/${year}`,
      params,
    });

    return data;
  };

  return useQuery([key], () => getBiennialWaterConsumptionData(year, params), {
    ...options,
  });
}

export default useStatisticsBiennialWaterConsumption;
