import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./ErrorPages.scss";

function Forbidden() {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="text-center">
        <h3 className="page-title">Forbidden</h3>
        <h1 className="number-code">
          <span>4</span>
          <span>0</span>
          <span>3</span>
        </h1>
        <h2 className="page-subtitle">
          We are sorry, but you don&apos;t have permission to access this page.
          You can try to
        </h2>
        <div className="page-actions">
          <Button size="large" onClick={() => navigate("/login")}>
            Login
          </Button>
          <span className="divider">or</span>
          <Button size="large" onClick={() => navigate("/")}>
            Go back to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Forbidden;
