import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useEvent(eventId, options) {
  const axiosPrivate = useAxiosPrivate();

  const getEvents = async () => {
    const event = await axiosPrivate({
      method: "GET",
      url: `/Logs/GetLog2/${eventId}`,
    }).then((res) => res.data);
    return event;
  };

  return useQuery(["getEventById", eventId], () => getEvents(eventId), {
    ...options,
  });
}

export default useEvent;
