function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      version="1.1"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 503.6 478.4"
    >
      <path
        className="blue-fill-color"
        fill="#ffffff"
        d="M129.6,51.6H98.2v149.3H65.7v96.3h32.6v127.6h31.4V297.3h32.6v-96.3h-32.6V51.6z M269.5,51.6h-31.4V95h-32.6     v96.3h32.6v233.6h31.4V191.3h32.6V95h-32.6V51.6z M409.4,297.3V51.6h-31.4v245.7h-32.6v96.3h32.6v31.3h31.4v-31.3H442v-96.3     H409.4z"
      />
    </svg>
  );
}

export default SettingsIcon;
