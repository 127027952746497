import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

import {
  usefnCreatePropertyFloorDtos,
  usefnUpdatePropertyFloorDtos,
} from "./useFactoryPropertyFloorDtos";

async function useHandleUpdate(
  propertyId,
  initialFormValues,
  formValues,
  setIsLoading,
  setIsFormDirty,
  updatePropertyPreferencesMutation,
  updatePropertyFloorsMutation,
  createPropertyFloorsMutation,
  uploadAttachmentMutation,
) {
  let createPropertyFloorsResponse;
  let updatePropertyPreferencesResponse;
  let responseUploadAttachment;

  const createPropertyFloorDtos = usefnCreatePropertyFloorDtos(
    formValues,
    propertyId,
  );
  const updatePropertyFloorDtos = usefnUpdatePropertyFloorDtos(
    initialFormValues,
    formValues,
  );

  const request = {
    Id: propertyId,
    Name: formValues.Name,
    Address: formValues.Address,
    ImageUrl: formValues.ImageUrl,
    PropertyPreferences: {
      NumberOfOccupants: 0,
      CostOfWater: 0,
      Currency: "USD",
      WorkingDaysEnabled: formValues.WorkingDaysEnabled,
      WorkingDaysStartDayOfWeek: formValues.WorkingDaysStartDay
        ? formValues.WorkingDaysStartDay
        : initialFormValues.current.WorkingDaysStartDay,
      WorkingDaysEndDayOfWeek: formValues.WorkingDaysEndDay
        ? formValues.WorkingDaysEndDay
        : initialFormValues.current.WorkingDaysEndDay,
      WorkingDaysStartTime: formValues.WorkingDaysStartTime
        ? formValues.WorkingDaysStartTime.format("HH:mm")
        : initialFormValues.current.WorkingDaysStartTime.format("HH:mm"),
      WorkingDaysEndTime: formValues.WorkingDaysEndTime
        ? formValues.WorkingDaysEndTime.format("HH:mm")
        : initialFormValues.current.WorkingDaysEndTime.format("HH:mm"),
    },
  };

  if (formValues.ImageUrl?.file?.status === "removed") {
    request.ImageUrl = null;
  }

  if (formValues.ImageUrl?.file?.status === "done") {
    const { fileList } = formValues.ImageUrl;
    const data = new FormData();
    data.append("file", fileList[0].originFileObj);
    responseUploadAttachment = await uploadAttachmentMutation.mutateAsync(data);
    request.ImageUrl = responseUploadAttachment?.data;
  }

  try {
    updatePropertyPreferencesResponse =
      await updatePropertyPreferencesMutation.mutateAsync(request);

    if (createPropertyFloorDtos.Floors.length > 0) {
      const createFloorsResponse =
        await createPropertyFloorsMutation.mutateAsync(createPropertyFloorDtos);
      createPropertyFloorsResponse = createFloorsResponse;
    }

    if (updatePropertyFloorDtos.Floors.length > 0) {
      await updatePropertyFloorsMutation.mutateAsync(updatePropertyFloorDtos);
    }
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could not update property.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">
          property update success
        </span>
      ),
      duration: 2,
      placement: "topRight",
    });
  }
  return { createPropertyFloorsResponse, updatePropertyPreferencesResponse };
}

export default useHandleUpdate;
