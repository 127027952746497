import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyFloorUpdateMultiple() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate({
        method: "PUT",
        url: "PropertyFloor/Multiple",
        data,
      });
    },
  });
}

export default usePropertyFloorUpdateMultiple;
