const csvParser = (csv, delimiter = ",") => {
  const rows = [];
  const lines = csv.split("\n");

  for (let j = 0; j < lines.length; j += 1) {
    const line = lines[j];

    // If line is null skip empty lines
    if (line && line.trim()) {
      const row = [];
      let insideQuotes = false;
      let value = "";

      for (let i = 0; i < line.length; i += 1) {
        const char = line[i];

        if (char === '"') {
          insideQuotes = !insideQuotes;
        } else if (char === delimiter && !insideQuotes) {
          // If we encounter a delimiter outside of quotes, we add the value to the row
          row.push(value.trim());

          // Reset the value for the next field
          value = "";
        } else {
          // Add the current character to the value
          value += char;
        }
      }

      // Add the last value to the row
      if (value) {
        row.push(value.trim());
      }

      // Add the row to the results
      rows.push(row);
    }
  }
  return rows;
};

export default csvParser;
