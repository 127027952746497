import { useEffect, useState } from "react";

import { DollarOutlined, FrownOutlined } from "@ant-design/icons";
import { Skeleton, Divider, Pagination, Card } from "antd";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import {
  // usePropertyOverviewFloorsCount,
  usePropertyOverviewFloorsMetadata,
  usePropertyOverviewFloorsStatuses,
  usePropertyOverviewFloorsWaterConsumption,
} from "../../../api/hooks/usePropertyOverview";
import WaterDropIcon from "../../../assets/icons/WaterDropIcon";
import AlertsModal from "../../Modals/AlertsModal";
import DevicesModal from "../../Modals/DevicesModal";
import SearchInput from "../../SearchInput/SearchInput";
import Water from "../../Shared/Water";
import Floor from "../Floor/Floor";

import "./FloorList.scss";

function FloorList() {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { property } = location.state || {};
  const { propertyId } = useParams();

  const pageSize = 14;
  const [searchParams, setSearchParams] = useSearchParams();

  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  // Get PropertyOverview Floors Count
  // const { data: totalFloors } = usePropertyOverviewFloorsCount(propertyId, {
  //   search: debouncedSearchQuery,
  // });

  // Get PropertyOverview Floors Metadata.
  const { data: floors, status } = usePropertyOverviewFloorsMetadata(
    propertyId,
    {
      search: debouncedSearchQuery,
      pageSize,
    },
  );

  // Get PropertyOverview Floors Water Consumption.
  const {
    data: floorsWaterConsumptionCollectionData,
    isLoading: floorsWaterConsumptionIsLoading,
  } = usePropertyOverviewFloorsWaterConsumption(propertyId);

  // Get PropertyOverview Floors Statuses

  const {
    data: floorsStatusesCollectionData,
    isLoading: floorsStatusesIsLoading,
  } = usePropertyOverviewFloorsStatuses(propertyId);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="floor-list">
      <div className="flex flex-col-reverse sm:flex-row sm:items-center mb-4 md:mb-1 w-full">
        <div className="mt-6 sm:mt-0 flex-1 flex flex-col md:flex-row md:items-center mr-auto">
          <h1 className="page-title mb-0">{property?.Name}</h1>

          <div className="states md:ml-4">
            <div>
              <span className="square square--blue">&nbsp;</span>
              <span>Neutral state</span>
            </div>
            <div>
              <span className="square square--orange">&nbsp;</span>
              <span>Negative state</span>
            </div>
          </div>
        </div>

        <SearchInput
          className="ml-auto w-full sm:w-fit"
          placeholder="Search Floors"
          disabled={
            (!searchQuery && floors?.length === 0) || status === "loading"
          }
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <div className="text-white text-xs text-opacity-80 font-light mb-4">
        <span className="text-14">{property?.Address || "-"}</span>
        <Divider type="vertical" className="mx-3" />

        <span className="text-14">{property?.SystemsCount || 0} Hubs</span>
        <Divider type="vertical" className="ml-3" />

        <DevicesModal
          devicesCount={property?.DevicesCount}
          propertyId={property?.Id}
        />
        <Divider type="vertical" />

        <AlertsModal
          alertsCount={property?.AlertsCount}
          propertyId={property?.Id}
        />
        <Divider type="vertical" />

        <span>
          <WaterDropIcon className="mr-1 w-[0.6rem] align-sub" />
          <span className="mr-2">Consumption:</span>
          <Water value={property?.TotalWaterConsumption?.CurrentMonthValue} />
        </span>
        <Divider type="vertical" />

        <span>
          <DollarOutlined className="mr-1" />
          <span className="mr-2">Savings:</span>
          {property?.TotalWaterConsumption?.WaterSavingsPercentage || "-"}%
        </span>
      </div>

      <Card className="rounded-2xl triple-plus-content">
        {status === "loading" &&
          Array.from({ length: 3 }, (value, index) => index).map((el) => (
            <Skeleton
              active
              paragraph={{
                rows: 2,
              }}
              key={el}
            />
          ))}

        {status === "success" && floors?.length === 0 && (
          <div className="p-6 text-white text-opacity-80">
            <FrownOutlined className="text-xl mr-2" />
            We couldn`t find any matches
          </div>
        )}

        {status === "success" && floors?.length > 0 && (
          <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
            {floors?.map((floor) => (
              <Floor
                key={floor.Id}
                floor={floor}
                floorsWaterConsumptionCollectionData={
                  floorsWaterConsumptionCollectionData
                }
                floorsWaterConsumptionIsLoading={
                  floorsWaterConsumptionIsLoading
                }
                floorsOverviewStatusesCollectionData={
                  floorsStatusesCollectionData
                }
                floorsOverviewStatusesIsLoading={floorsStatusesIsLoading}
              />
            ))}
          </div>
        )}

        {status === "success" &&
          floors?.length > 0 &&
          floors?.length > pageSize && (
            <Pagination
              defaultCurrent={1}
              pageSize={pageSize}
              total={floors?.length}
              current={Number(searchParams.get("page")) || 1}
              showSizeChanger={false}
              size={window?.width <= 576 && "small"}
              onChange={(page) => {
                setSearchParams({ page });
              }}
            />
          )}
      </Card>
    </div>
  );
}

export default FloorList;
