import PropTypes from "prop-types";

function AlarmIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      className={className}
      width="50"
      height="50"
    >
      <g id="Group_58" data-name="Group 58">
        <g id="Group_65" data-name="Group 65">
          <g id="Rectangle_3_copy_10" data-name="Rectangle 3 copy 10">
            <path
              strokeWidth="0px"
              fill={className === "active" ? "#06a5d3" : "#303136"}
              d="M143.08,48.66H57.03c-28.45,0-51.52,23.07-51.52,51.52s23.07,51.52,51.52,51.52h86.05c28.46,0,51.52-23.07,51.52-51.52s-23.07-51.52-51.52-51.52Z"
            />
          </g>
          <g id="Shape_1038" data-name="Shape 1038">
            <path
              strokeWidth="0px"
              fill="#fff"
              d="M70.54,80.91c1.09,0,2.12-.53,2.81-1.44,6.71-8.88,16.48-13.97,26.82-13.97s19.91,4.99,26.61,13.69c.69.9,1.71,1.42,2.79,1.42.89,0,1.75-.36,2.42-1,1.48-1.44,1.65-3.86.37-5.53-8.08-10.5-19.82-16.53-32.19-16.53s-24.35,6.15-32.45,16.86c-1.26,1.67-1.08,4.1.42,5.52.66.63,1.51.98,2.39.98ZM135.93,130.11c-1.08-2.68-6.98-5.95-7.04-8.43h0v-20.8c0-16.41-12.73-30.15-28.82-30.46h-.02c-16.09.31-28.82,14.05-28.82,30.46v20.8h0c-.06,2.49-5.95,5.76-7.04,8.43-.93,2.3.73,4.83,3.17,4.83h23.73c.47,0,.87.33.97.8.78,3.86,4.01,6.8,7.95,7.08.02-.02.04-.04.06-.06.02.02.04.03.06.06,3.94-.28,7.17-3.23,7.95-7.08.1-.47.5-.8.97-.8h23.73c2.44,0,4.1-2.53,3.17-4.83ZM119.6,121.12c0,.5-.39.9-1.81.9h-36.4c-.49,0-.88-.4-.88-.9v-20.67c0-11.1,8.72-20.15,19.54-20.42,10.82.27,19.54,9.32,19.54,20.42v20.67Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

AlarmIcon.propTypes = {
  className: PropTypes.string,
};

AlarmIcon.defaultProps = {
  className: "",
};

export default AlarmIcon;
