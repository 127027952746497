function ReportsIcon() {
  return (
    <svg
      width="417"
      height="425"
      viewBox="125 150 160 168"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        stroke="#757782"
        strokeWidth="6px"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.767,213.651v-16.39H172.534v74.378H243V213.651H225.767Z"
      />
      <path
        fill="#757782"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.411,232.719h27.1a4,4,0,1,0,0-8h-27.1A4,4,0,1,0,187.411,232.719Zm35.506-4a4.112,4.112,0,1,0,4.111-4A4.058,4.058,0,0,0,222.917,228.718Z"
      />
      <path
        fill="#757782"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.411,217.719h20.1a4,4,0,1,0,0-8h-20.1A4,4,0,1,0,187.411,217.719Z"
      />
      <path
        fill="#757782"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.339,238.529H221.2a1.516,1.516,0,0,0-1.084,2.592l3.054,3.025-8.991,9.107a4.277,4.277,0,0,0-2.937-.045l-3.843-3.924a4.563,4.563,0,1,0-9.055-.84c0,0.108.025,0.209,0.032,0.315l-9,7.154a4.514,4.514,0,1,0,2.517,3.122l8.551-6.794a4.511,4.511,0,0,0,4.48.251l3.427,3.5a4.455,4.455,0,0,0-.278,1.5,4.57,4.57,0,0,0,9.139,0,4.444,4.444,0,0,0-.233-1.359l9.048-9.165,3.23,3.2a1.535,1.535,0,0,0,2.617-1.073v-9.05A1.526,1.526,0,0,0,230.339,238.529Z"
      />
    </svg>
  );
}

export default ReportsIcon;
