import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useUsersUpdateImage() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate({
        method: "POST",
        url: "/Users/UpdateImage",
        data,
      });
    },
  });
}

export default useUsersUpdateImage;
