import PropTypes from "prop-types";

function DeviceInAlarmIcon({ className = "" }) {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1043_102735)">
        <path
          d="M12.6372 12.5V12.5104"
          stroke="white"
          strokeOpacity="0.85"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.583 9.55371C16.3641 10.3351 16.803 11.3947 16.803 12.4995C16.803 13.6044 16.3641 14.664 15.583 15.4454"
          stroke="white"
          strokeOpacity="0.85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5298 6.60742C19.3037 7.38125 19.9175 8.29993 20.3363 9.311C20.7552 10.3221 20.9707 11.4057 20.9707 12.5001C20.9707 13.5945 20.7552 14.6782 20.3363 15.6893C19.9175 16.7003 19.3037 17.619 18.5298 18.3928"
          stroke="white"
          strokeOpacity="0.85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.68723 15.4454C8.9061 14.664 8.46729 13.6044 8.46729 12.4995C8.46729 11.3947 8.9061 10.3351 9.68723 9.55371"
          stroke="white"
          strokeOpacity="0.85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.73829 18.3928C5.96442 17.619 5.35056 16.7003 4.93174 15.6893C4.51293 14.6782 4.29736 13.5945 4.29736 12.5001C4.29736 11.4057 4.51293 10.3221 4.93174 9.311C5.35056 8.29993 5.96442 7.38125 6.73829 6.60742"
          stroke="white"
          strokeOpacity="0.85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1043_102735">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0.137207)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

DeviceInAlarmIcon.propTypes = {
  className: PropTypes.string,
};

DeviceInAlarmIcon.defaultProps = {
  className: "",
};

export default DeviceInAlarmIcon;
