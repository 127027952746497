import { useEffect, useState, useRef } from "react";

import {
  UserOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Card, Form, Input, Button, Checkbox, Modal, Popover } from "antd";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { authenticateUserV5 } from "../../api/requests/auth";
import logoTriplePlus from "../../assets/img/wasens-logo-tm-2.svg";
import useAuth from "../../hooks/useAuth";
import CmsFooter from "../Footer/CmsFooter";
import GoogleRecaptcha from "../GoogleRecaptcha/GoogleRecaptcha";
import TermsAndConditions from "./TermsAndConditions";
import "./LoginPage.scss";

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loginPageForm] = Form.useForm();

  const usernameInputRef = useRef();
  const gRecaptchaRef = useRef();

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    usernameInputRef.current.focus();
    const isAccepted = Cookies.get("t&c") === "true";
    if (isAccepted) {
      setTermsAccepted(true);
    } else {
      setTermsAccepted(false);
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const popoverContent = () => {
    return (
      <div className="sm:w-[20rem] w-[15rem]">
        <div className="text-center">
          <CloseCircleFilled style={{ color: "#ed1c24" }} />
          <h3 className="tracking-widest uppercase text-triple-red">
            Authentication Failed.
          </h3>
          <span className="tracking-widest text-triple-secondary-blue">
            {errorMessage}
          </span>
        </div>
      </div>
    );
  };

  const handleSubmit = async () => {
    if (gRecaptchaRef.current) {
      setIsLoading(true);
      const username = loginPageForm.getFieldValue("username");
      const password = loginPageForm.getFieldValue("password");
      const agreed = loginPageForm.getFieldValue("agreed");
      const gRecaptchaToken = await gRecaptchaRef.current.execute();
      try {
        const response = await authenticateUserV5({
          username,
          password,
          gRecaptchaToken,
        });
        const accessToken = response?.access_token;
        const refreshToken = response?.refresh_token;
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 2);
        Cookies.set("refreshToken", refreshToken, { expires: expiration });
        sessionStorage.setItem("previouslyVisited", "true");
        if (agreed) {
          expiration.setDate(expiration.getDate() + 7);
          Cookies.set("t&c", true, {
            expires: expiration,
          });
        }
        setAuth({ username, accessToken, isAuthenticated: true });
        navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
          setErrorMessage(
            "The server failed to produce a response. Please try again later.",
          );
        } else if (!err.response?.data?.Message) {
          setErrorMessage("Something went wrong.");
        } else {
          setErrorMessage("Please check Username and Password and retry!");
        }
        setIsPopoverOpen(true);
        setTimeout(() => {
          setIsPopoverOpen(false);
        }, 5000);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const togglePasswordVisibility = (visible) => {
    return visible ? (
      <EyeOutlined style={{ fontSize: 20, color: "#393d50", padding: 4 }} />
    ) : (
      <EyeInvisibleOutlined
        style={{ fontSize: 20, color: "#393d50", padding: 4 }}
      />
    );
  };

  return (
    <>
      <GoogleRecaptcha
        siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        action="cmsloginaction"
        ref={gRecaptchaRef}
      />
      <div className="login-page">
        <Popover placement="top" content={popoverContent} open={isPopoverOpen}>
          <Card className="login-card rounded-3xl">
            <div className="login-content">
              <div className="flex justify-center items-center">
                <img
                  src={logoTriplePlus}
                  alt="Triple+ logo"
                  className="w-28 sm:w-48 h-full object-contain"
                />
              </div>
              <div className="flex justify-center items-center">
                <h4 className="login-title !mb-1">
                  {t("login_page.login_title")}
                </h4>
              </div>
              <Form
                name="login-form"
                layout="vertical"
                requiredMark={false}
                form={loginPageForm}
                onFinish={handleSubmit}
              >
                <Form.Item
                  className="mb-4"
                  label={
                    <span className="text-triple-secondary-blue font-normal">
                      {t("login_page.username")}
                    </span>
                  }
                  labelCol={{ style: { paddingBottom: 0 } }}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "login_page.form_items_error_messages.username",
                      ),
                    },
                  ]}
                  validateStatus={isPopoverOpen ? "error" : ""}
                >
                  <Input
                    prefix={
                      <div className="bg-triple-blue">
                        <UserOutlined
                          className="text-triple-white bg-triple-blue m-1"
                          style={{ fontSize: 20 }}
                        />
                      </div>
                    }
                    size="large"
                    className={`${
                      isPopoverOpen ? "error-input" : ""
                    } p-0 bg-triple-white border border-solid border-triple-blue`}
                    data-testid="username-input"
                    ref={usernameInputRef}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-triple-secondary-blue font-normal">
                      {t("login_page.password")}
                    </span>
                  }
                  labelCol={{ style: { paddingBottom: 0 } }}
                  name="password"
                  className="py-0"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "login_page.form_items_error_messages.password",
                      ),
                    },
                  ]}
                  validateStatus={isPopoverOpen ? "error" : ""}
                >
                  <Input.Password
                    prefix={
                      <div className="bg-triple-blue">
                        <LockOutlined
                          className="text-triple-white bg-triple-blue m-1"
                          style={{ fontSize: 20 }}
                        />
                      </div>
                    }
                    size="large"
                    className={`${
                      isPopoverOpen ? "error-input" : ""
                    } p-0 bg-triple-white border border-solid border-triple-blue`}
                    iconRender={(visible) => togglePasswordVisibility(visible)}
                    data-testid="password-input"
                  />
                </Form.Item>
                <div className="flex justify-center">
                  <Button
                    type="link"
                    onClick={() => {
                      navigate("/reset-password");
                    }}
                  >
                    <span className="text-triple-main-blue link-to-external">
                      Forgot Password?
                    </span>
                  </Button>
                </div>
                {!termsAccepted && (
                  <>
                    <Form.Item
                      name="agreed"
                      valuePropName="checked"
                      className="my-0 py-0"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  t(
                                    "login_page.form_items_error_messages.termsAndConditions",
                                  ),
                                ),
                        },
                      ]}
                    >
                      <Checkbox
                        className="text-triple-secondary-blue"
                        data-testid="terms_and_conditions"
                      >
                        I accept the
                        <Button type="link" onClick={showModal}>
                          <span className="text-triple-blue t-and-c">
                            Terms of Use & Privacy Policy
                          </span>
                        </Button>
                      </Checkbox>
                    </Form.Item>
                    <Modal
                      title={
                        <div className="text-center">
                          Terms of Use & Privacy Policy
                        </div>
                      }
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      cancelButtonProps={{ size: "small" }}
                      okButtonProps={{ size: "small" }}
                      maskClosable={false}
                      keyboard={false}
                    >
                      <div className="terms-and-conditions-content h-96 overflow-y-auto">
                        <TermsAndConditions />
                      </div>
                    </Modal>
                  </>
                )}
                <Form.Item className="mb-0 flex items-center justify-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="sm"
                    className="login-button"
                    loading={isLoading}
                    data-testid="login-btn"
                  >
                    <span className="font-medium">
                      {t("login_page.btn_login_text")}
                    </span>
                  </Button>
                </Form.Item>
              </Form>
              <div className="flex justify-center items-center mt-4 flex-col">
                <Button
                  type="link"
                  href="https://wasens.net/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <span className="text-triple-main-blue link-to-external">
                    WWW.WASENS.NET
                  </span>
                </Button>
                <div className="text-triple-main-blue text-center mt-2">
                  This site is protected by reCAPTCHA and the Google
                  <Button
                    type="link"
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    className="!px-1"
                  >
                    <span className="text-triple-main-blue link-to-external">
                      Privacy Policy
                    </span>
                  </Button>
                  and
                  <Button
                    type="link"
                    href="https://policies.google.com/terms?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    className="!px-1"
                  >
                    <span className="text-triple-main-blue link-to-external">
                      Terms of Service
                    </span>
                  </Button>
                  apply.
                </div>
              </div>
            </div>
          </Card>
        </Popover>
      </div>
      <CmsFooter className="login-page-footer" />
    </>
  );
}

export default LoginPage;
