import { useState, useEffect, useRef } from "react";

import {
  SlidersOutlined,
  DownloadOutlined,
  FrownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Card, notification, Space } from "antd";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";

import useEvents from "../../api/hooks/useEvents";
import useTotalEventsCount from "../../api/hooks/useTotalEventsCount";
import formatEventsForExport from "../../helpers/formatEventsForExport";
import useWindowSize from "../../hooks/useWindowSize";
import ConfigureExportModal from "../ConfigureExportModal/ConfigureExportModal";
import ExportCSV from "../ExportCSV/ExportCSV";
import Loader from "../Loader/Loader";
import EventsLogTable from "./EventsLogTable/EventsLogTable";

import "./Events.scss";

function Events() {
  const [dataToExport, setDataToExport] = useState([]);
  const [enableGetAllEventsQry, setEnableGetAllEventsQry] = useState(false);
  const [getAllEventsQryKey, setGetAllEventsQryKey] = useState(
    new Date().toLocaleTimeString(),
  );
  const [isConfigurationModalVisible, setIsConfigurationModalVisible] =
    useState(false);

  const excelRef = useRef();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const window = useWindowSize();

  const pageSize = 10;

  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page, ...paramsToSendWithRequest } = params;

  // query to get Events for the listing table
  const { data: events, status } = useEvents(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      ...paramsToSendWithRequest,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
    "getEventsOnEventPage",
  );

  // query to get total count of Events based on search params
  const { data: totalEventsCount } = useTotalEventsCount(
    paramsToSendWithRequest,
  );

  // export range
  const [range, setRange] = useState({
    min: 0,
    max: 0,
  });
  const totalEventsCountToExport = range.max - range.min;

  // set export range when totalEventsCount avaliable
  // make sure min is never > than max
  useEffect(() => {
    if (totalEventsCount) {
      setRange({
        min:
          range.min < (totalEventsCount > 250 ? 250 : totalEventsCount)
            ? range.min
            : 0,
        max: totalEventsCount > 250 ? 250 : totalEventsCount,
      });
    }
  }, [totalEventsCount]);

  // query to get all Events based on export range
  const {
    data: allEvents,
    isInitialLoading: getAllEventsLoading,
    isError: getAllEventsFailed,
  } = useEvents(
    {
      pageSize: totalEventsCountToExport,
      startIndex: range.min,
      ...paramsToSendWithRequest,
    },
    {
      enabled: enableGetAllEventsQry,
      cacheTime: 0,
      keepPreviousData: false,
      retry: 0,
      refetchOnWindowFocus: false,
    },
    getAllEventsQryKey,
  );

  // when the query for fetching all the events returns data
  // filter and store it inside dataToExport
  useEffect(() => {
    if (allEvents) {
      setEnableGetAllEventsQry(false);
      // setDataToExport(allEvents.map((event) => event.LogsInfo));
      setDataToExport(allEvents);
    }
  }, [allEvents]);

  // when the query response is stored inside dataToExport
  // programatically click the real export button
  useEffect(() => {
    if (dataToExport?.length > 0) {
      excelRef.current.link.click();
    }
  }, [dataToExport]);

  useEffect(() => {
    if (getAllEventsFailed) {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Something went wrong
          </span>
        ),
        description: (
          <span>
            If nothing has happened for some time after pressing EXPORT, the
            request has probably timed out. This may be because you tried to
            export too many records. You can try to configure the export by
            clicking the <SettingOutlined /> button
          </span>
        ),
        duration: 0,
        placement: "bottomLeft",
        icon: <FrownOutlined className="text-triple-red" />,
      });
    }
  }, [getAllEventsFailed]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  return (
    <div className="events">
      <div className="events-header">
        {window?.width > 992 && <h1 className="page-title mb-0">Events Log</h1>}
        <Space>
          {Object.keys(paramsToSendWithRequest).length > 0 && (
            <Button
              type="text"
              className="px-2 hidden sm:block"
              disabled={!events}
              onClick={() => navigate("/events")}
            >
              Clear filters
            </Button>
          )}
          <Button
            size="large"
            className="events-header-button"
            disabled={!events}
            onClick={() =>
              navigate({
                pathname: "/events/filters",
                search: `?${createSearchParams({
                  ...params,
                })}`,
              })
            }
          >
            <SlidersOutlined />
            Filters
          </Button>

          <ConfigureExportModal
            max={totalEventsCount}
            open={isConfigurationModalVisible}
            initialRange={range}
            onSubmit={setRange}
            onClose={() => setIsConfigurationModalVisible(false)}
          />

          {/* TODO: try to export in separate component for readability */}
          <div className="flex w-44">
            {/* fake export button to trigger the getAllEvents query
             (visible on page if there are more than one page of results) */}
            {totalEventsCount > pageSize && (
              <Button
                className="events-header-button--combined-main"
                disabled={!events || getAllEventsLoading}
                loading={getAllEventsLoading}
                size="large"
                type="primary"
                onClick={() => {
                  setGetAllEventsQryKey(new Date().toLocaleTimeString());
                  setEnableGetAllEventsQry(true);
                }}
              >
                {!getAllEventsLoading && <DownloadOutlined />} Export
              </Button>
            )}

            {/* real export button to forward the ref used to programatically trigger the export 
              once the data is returned from the API and formated 
             (visible on the page if there is only one page of results
             to avoid making additional request) */}
            <ExportCSV
              btnClassName="events-header-button--combined-main"
              className={`${totalEventsCount > pageSize ? "hidden" : ""}`}
              data={
                totalEventsCount > pageSize
                  ? formatEventsForExport(dataToExport)
                  : formatEventsForExport(events)
              }
              disabled={!events || getAllEventsLoading}
              filename={`Events Log (${range.min} - ${range.max}) `}
              ref={excelRef}
              size="large"
            />
            <Button
              className="events-header-button--combined-secondary"
              disabled={!events || getAllEventsLoading}
              type="primary"
              onClick={() => setIsConfigurationModalVisible(true)}
            >
              <SettingOutlined />
            </Button>
          </div>
        </Space>
      </div>

      {!pageSize && <Loader className="absolute-center" />}

      <Card className="triple-plus-content rounded-2xl">
        <EventsLogTable
          data={events}
          status={status}
          totalCount={totalEventsCount}
          withSidebar
          onChange={onPageChange}
        />
      </Card>
    </div>
  );
}

export default Events;
