function SystemPreferencesIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.01 7.806l-.596-1.035a1.833 1.833 0 0 0-2.5-.676 1.825 1.825 0 0 1-2.5-.649 1.754 1.754 0 0 1-.245-.877 1.833 1.833 0 0 0-1.834-1.886h-1.202A1.825 1.825 0 0 0 9.31 4.516a1.833 1.833 0 0 1-1.833 1.807 1.754 1.754 0 0 1-.878-.245 1.833 1.833 0 0 0-2.5.675l-.64 1.053a1.833 1.833 0 0 0 .667 2.5 1.833 1.833 0 0 1 0 3.175 1.825 1.825 0 0 0-.667 2.492l.605 1.043a1.833 1.833 0 0 0 2.5.711 1.816 1.816 0 0 1 2.492.667c.157.265.242.568.245.877 0 1.012.82 1.833 1.833 1.833h1.202c1.01 0 1.829-.815 1.833-1.824a1.825 1.825 0 0 1 1.834-1.834c.308.008.61.093.877.246a1.833 1.833 0 0 0 2.5-.667l.632-1.052a1.825 1.825 0 0 0-.667-2.5 1.824 1.824 0 0 1-.667-2.5c.16-.278.39-.508.667-.667a1.833 1.833 0 0 0 .667-2.491v-.01z"
        stroke="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="blue-fill-color purple-stroke-color"
      />
      <circle
        cx="11.739"
        cy="11.893"
        r="2.526"
        stroke="#E1E0E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="purple-both-colors"
      />
    </svg>
  );
}

export default SystemPreferencesIcon;
