import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function WaterShutoffIcon({ className }) {
  return (
    <svg
      width="1.75em"
      height="1.75em"
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        d="M542.5,328.8c-25.3-27.8-57.7-47.2-93.5-56.1c-3.4-0.8-5.8-3.8-5.8-7.2v-19.2c0-4.1,3.3-7.4,7.4-7.4h183.6     c20.2,0,36.5-16.4,36.5-36.5v-2c0-15.9-12.8-28.8-28.5-29.1l-293.1-15l-0.9,0c-16,0-29.1,13.1-29.1,29.1v17     c0,20.2,16.4,36.5,36.5,36.5h0c4.1,0,7.4,3.3,7.4,7.4v19.2c0,3.5-2.4,6.4-5.8,7.2c-35.8,8.9-68.3,28.2-93.5,56.1     c-1.4,1.6-3.4,2.5-5.5,2.5H100.2c-4.1,0-7.4,3.3-7.4,7.4v233.4c0,4.1,3.3,7.4,7.4,7.4h158.1c2.1,0,4.1,0.9,5.5,2.5     c35.7,39.4,85.9,61.7,139.4,61.7c53.5,0,103.6-22.3,139.4-61.7c1.4-1.6,3.4-2.5,5.5-2.5h158.1c4.1,0,7.4-3.3,7.4-7.4V338.8     c0-4.1-3.3-7.4-7.4-7.4H548C545.9,331.3,543.9,330.4,542.5,328.8z M671.2,544.7H531.7c-2.3,0-4.4,1-5.8,2.8l-3,3.8     c-29.3,36.6-73,57.6-119.7,57.6c-46.8,0-90.4-21-119.7-57.6l-3-3.8c-1.4-1.8-3.6-2.8-5.8-2.8H135.2c-4.1,0-7.4-3.3-7.4-7.4V373.8     c0-4.1,3.3-7.4,7.4-7.4h139.4c2.3,0,4.4-1,5.8-2.8l3-3.8c29.3-36.6,73-57.6,119.7-57.6s90.4,21,119.7,57.6l3,3.8     c1.4,1.8,3.5,2.8,5.8,2.8h139.4c4.1,0,7.4,3.3,7.4,7.4v163.4C678.6,541.3,675.3,544.7,671.2,544.7z"
      />
    </svg>
  );
}

WaterShutoffIcon.propTypes = {
  className: PropTypes.string,
};

WaterShutoffIcon.defaultProps = {
  className: "",
};
