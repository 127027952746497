function usefnCreatePropertyFloorDtos(formValues, propertyId) {
  const createPropertyFloorDtos = {
    Floors: [],
  };
  if (formValues.AddPropertyFloors?.length > 0) {
    formValues.AddPropertyFloors.forEach((addPropertyFloor) => {
      createPropertyFloorDtos.Floors.push({
        Name: addPropertyFloor.Name,
        PropertyId: propertyId,
      });
    });
  }
  return createPropertyFloorDtos;
}

function usefnUpdatePropertyFloorDtos(initialFormValues, formValues) {
  const updatePropertyFloorDtos = {
    Floors: [],
  };
  for (
    let index = 0;
    index < initialFormValues.current.Floors.length;
    index += 1
  ) {
    const elementInitial = initialFormValues.current.Floors[index];
    const elementFormValues = formValues.Floors[index];
    if (elementInitial?.Name !== elementFormValues?.Name) {
      updatePropertyFloorDtos.Floors.push({
        Name: elementFormValues?.Name,
        Id: elementFormValues?.Id,
      });
    }
  }
  return updatePropertyFloorDtos;
}

export { usefnCreatePropertyFloorDtos, usefnUpdatePropertyFloorDtos };
