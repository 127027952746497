import { useEffect, useRef, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";
import PropTypes from "prop-types";

function CreateNewPropertyFloors({ createNewPropertyFormRef }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  const [totalFloorsCount, setTotalValue] = useState();
  const [lastFieldIndex, setLastFieldIndex] = useState(null);
  const inputRefs = useRef([]);
  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  useEffect(() => {
    const start = Number(fromValue);
    const end = Number(toValue);
    if (end < start) {
      setTotalValue("");
    } else {
      const total = Math.abs(end - start) + 1;
      setTotalValue(total);
    }
  }, [fromValue, toValue]);

  useEffect(() => {
    if (fromValue && toValue) {
      const start = Number(fromValue);
      const end = Number(toValue);
      const result = [];
      for (let index = start; index <= end; index += 1) {
        result.push(`${index}`);
      }

      createNewPropertyFormRef.setFieldsValue({
        AddPropertyFloors: result.map((value) => ({ Name: `Floor ${value}` })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFloorsCount]);

  useEffect(() => {
    if (lastFieldIndex !== null && inputRefs.current[lastFieldIndex]) {
      const el = document.getElementById(
        `add-property-floor-${lastFieldIndex}`,
      );
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [lastFieldIndex]);

  const setInputRef = (index) => (el) => {
    inputRefs.current[index] = el;
  };

  return (
    <>
      <FormItemLabel label="Floors" />
      <Divider className="mt-1" />
      <div className="inline-flex mt-1">
        <FormItemLabel label="From" />
        <Input
          type="number"
          className="mx-1"
          disabled={!isEditMode}
          size="small"
          value={fromValue}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => setFromValue(e.target.value)}
        />
        <FormItemLabel label="To" />
        <Input
          type="number"
          className="mx-1"
          disabled={!isEditMode}
          size="small"
          value={toValue}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => setToValue(e.target.value)}
        />
        <FormItemLabel label="Total" />
        <Input
          type="number"
          className="mx-1"
          readOnly
          disabled
          size="small"
          value={totalFloorsCount}
        />
        <Button
          type="primary"
          size="small"
          icon={<EditOutlined />}
          onClick={handleEditClick}
        >
          Edit
        </Button>
      </div>
      <Divider className="my-5" />
      <Form.List name="AddPropertyFloors" className="mt-2">
        {(fields) => (
          <>
            {fields.map(({ key, name }, index) => {
              setLastFieldIndex(fields.length - 1);
              return (
                <Form.Item
                  key={key}
                  name={[name, "Name"]}
                  rules={[
                    {
                      required: true,
                      message: "Floor Name field is required.",
                    },
                  ]}
                >
                  <Input
                    ref={setInputRef(index)}
                    id={`add-property-floor-${name}`}
                    required
                  />
                </Form.Item>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
}
CreateNewPropertyFloors.defaultProps = {
  createNewPropertyFormRef: {},
};
CreateNewPropertyFloors.propTypes = {
  createNewPropertyFormRef: PropTypes.object,
};
export default CreateNewPropertyFloors;
