import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useScheduleProperty(params, options, key = "getScheduleProperty") {
  const axiosPrivate = useAxiosPrivate();

  const getSchedules = async () => {
    const schedules = await axiosPrivate({
      method: "GET",
      url: "Schedule/Property",
      params,
    }).then((res) => res.data);
    return schedules;
  };

  return useQuery([key, params], () => getSchedules(params), {
    ...options,
  });
}

export default useScheduleProperty;
