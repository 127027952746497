import dayjs from "dayjs";

/* eslint-disable import/prefer-default-export */
export function ConvertDurationInMinutesToHours(durationInMinutes) {
  const hour = Math.floor(durationInMinutes / 60);
  const minute = durationInMinutes % 60;
  const formattedHours = dayjs()
    .set("hour", hour)
    .set("minute", minute)
    .format("HH:mm");
  return formattedHours;
}

export const ConvertTimeToDurationInMinutes = (time) => {
  const duration = time.hour() * 60 + time.minute();
  return duration;
};

export const isValidDate = (d) => {
  if (d === null) {
    return false;
  }

  return d instanceof Date || !Number.isNaN(d);
};

export function getMonthName(monthNumber) {
  const months = [
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // eslint-disable-next-line no-plusplus, no-param-reassign
  return months[++monthNumber];
}
