import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUsersManagementCreateUser = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (request) => {
      return axiosPrivate({
        method: "POST",
        url: "Users/Management/CreateUser",
        data: request,
      });
    },
  });
};

export default useUsersManagementCreateUser;
