import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useScheduleAmend() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: (data) => {
      const programId = data.ProgramId;

      return axiosPrivate({
        method: "PUT",
        url: `Schedule/Program/${programId}`,
        data,
      });
    },
  });
}

export default useScheduleAmend;
