import { Form, Select } from "antd";
import PropTypes from "prop-types";

function FormItemSelect({
  className,
  fieldLabel,
  fieldName,
  placeholder,
  size,
  options,
  onChange,
}) {
  return (
    <Form.Item className={className} label={fieldLabel} name={fieldName}>
      <Select
        placeholder={placeholder}
        size={size}
        options={options}
        onChange={onChange}
      />
    </Form.Item>
  );
}

FormItemSelect.propTypes = {
  className: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

FormItemSelect.defaultProps = {
  className: "",
  fieldLabel: "",
  fieldName: "",
  placeholder: "",
  size: "small",
  options: [],
  onChange: () => {},
};

export default FormItemSelect;
