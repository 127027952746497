import PropTypes from "prop-types";

import CustomTable from "../../CustomTable/CustomTable";
import EventIcon from "../../Events/EventIcon";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: null,
  currentPage: null,
  totalCount: null,
  onChange: () => {},
};

function PropertyAlertsTable({
  data,
  status,
  pageSize,
  currentPage,
  totalCount,
  onChange,
}) {
  const columns = [
    {
      title: "Device Type",
      key: "deviceType",
      render: (record) => (
        <>
          <EventIcon
            key={record?.Id}
            iconName={record?.DeviceTypeName}
            className={`${record?.Status?.toLowerCase()}-event mr-2`}
            defaultIcon={<span>*icon*</span>}
          />
          {record.DeviceTypeName || "-"}
        </>
      ),
      width: 175,
    },
    {
      title: "Serial Number",
      key: "DeviceSerialNumber",
      render: (record) => <span>{record?.DeviceSerialNumber || "-"}</span>,
      width: 175,
    },
    {
      title: "Status",
      key: "Status",
      render: (record) => <span>{record?.Status || "-"}</span>,
      width: 100,
    },
    {
      title: "Last Modified",
      key: "LastModified",
      render: (record) => (
        <span>{new Date(record?.LastModified).toLocaleString() || "-"}</span>
      ),
      width: 175,
    },
    {
      title: "Device Name",
      key: "DeviceName",
      render: (record) => <span>{record?.DeviceName || "-"}</span>,

      width: 125,
    },
    {
      title: "Location",
      key: "DeviceLocation",
      render: (record) => <span>{record?.DeviceLocation || "-"}</span>,

      width: 125,
    },
    {
      title: "Floor",
      key: "FloorName",
      render: (record) => <span>{record?.FloorName || "-"}</span>,

      width: 125,
    },
    {
      title: "System Name",
      key: "SystemName",
      render: (record) => <span>{record?.SystemName || "-"}</span>,
      width: 125,
    },
    {
      title: "System Unique Id",
      key: "SystemUniqueId",
      render: (record) => <span>{record?.SystemUniqueId || "-"}</span>,
      width: 175,
    },
  ];

  return (
    <CustomTable
      data={data.Result}
      status={status}
      noPagination={!(totalCount > pageSize) || status === "loading"}
      pageSize={pageSize}
      totalCount={totalCount}
      currentPage={currentPage}
      tableColumns={columns}
      onChange={onChange}
    />
  );
}

PropertyAlertsTable.propTypes = propTypes;
PropertyAlertsTable.defaultProps = defaultProps;

export default PropertyAlertsTable;
