import PropTypes from "prop-types";
import { useLocation, Navigate } from "react-router-dom";

import useGetAvailableFeatures from "../api/hooks/customHooks/useGetAvailableFeatures";
import { flattenGroupedArray, groupBy } from "../helpers/jsLinq";

const propTypes = {
  children: PropTypes.node,
  featureName: PropTypes.string,
};

const defaultProps = { children: null, featureName: null };

function RequireLicense({ children, featureName }) {
  const location = useLocation();
  const { data } = useGetAvailableFeatures();
  const userFeaturesGroupedData = groupBy(
    data?.AvailableFeatures,
    "FeatureArea",
  );

  const flattened = flattenGroupedArray(userFeaturesGroupedData).flatMap(
    (groupedDataItem) => groupedDataItem.FeatureName,
  );

  if (!userFeaturesGroupedData) {
    return null;
  }

  if (flattened?.includes(featureName)) {
    return children;
  }

  if (userFeaturesGroupedData && !flattened.includes(featureName)) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }
}

RequireLicense.propTypes = propTypes;
RequireLicense.defaultProps = defaultProps;

export default RequireLicense;
