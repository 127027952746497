/** Function to convert cubic meters (m3) to gallons and vice versa
 * @returns value as m3 or gal
 */
export function ConvertWater(unit, value) {
  // 1 cubic meter equals to 264.2 US gallons;
  const gallons = 264.2;
  const m3s = value / 1000;
  switch (unit) {
    case "Litres":
      return m3s;
    case "Gallons": {
      const converted = m3s * gallons;
      return converted;
    }
    default:
      return m3s;
  }
}

/** Function to convert water units based on the provided unit & value.
 *  Renders water value as string.
 *  @returns value as m3 or gal as string
 */
export function RenderWater(unit, value, displayUnitOfMeasure = true) {
  // 1 litre equals to 0.264172 US gallons;
  const gallon = 0.264172;
  switch (unit) {
    case "Litres":
      return displayUnitOfMeasure
        ? `${value?.toFixed(2)} L`
        : `${value?.toFixed(2)}`;
    case "Gallons": {
      const converted = value * gallon;
      return displayUnitOfMeasure
        ? `${converted.toFixed(2)} gal`
        : `${converted.toFixed(2)}`;
    }
    default:
      return value;
  }
}
