import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export function SystemIcon({ className }) {
  return (
    <svg
      id="system-icon"
      data-name="system-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.16 9.97"
      className={`w-4 ${className}`}
    >
      <path
        className="cls-1"
        fill="white"
        fillOpacity="0.85"
        d="m10.02,4.48V.28h-4.2v1.75h-1.83c-.13-.92-.91-1.63-1.86-1.63C1.08.4.24,1.25.24,2.29s.85,1.89,1.89,1.89c.89,0,1.63-.62,1.83-1.45h1.86v1.24l-2.55,2.55c-.32-.24-.71-.4-1.14-.4-1.04,0-1.89.85-1.89,1.89s.85,1.89,1.89,1.89,1.89-.85,1.89-1.89c0-.36-.1-.68-.28-.97l2.56-2.56h1.27v1.68c-.87.16-1.54.93-1.54,1.85,0,1.04.85,1.89,1.89,1.89s1.89-.85,1.89-1.89c0-.92-.66-1.69-1.54-1.85v-1.68h1.75Zm-7.9-1.04c-.64,0-1.16-.52-1.16-1.16s.52-1.16,1.16-1.16,1.16.52,1.16,1.16-.52,1.16-1.16,1.16Zm6.95,4.57c0,.64-.52,1.16-1.16,1.16s-1.16-.52-1.16-1.16.52-1.16,1.16-1.16,1.16.52,1.16,1.16ZM6.55,1.01h2.74v2.74h-2.74V1.01ZM2.12,9.17c-.64,0-1.16-.52-1.16-1.16s.52-1.16,1.16-1.16,1.16.52,1.16,1.16-.52,1.16-1.16,1.16Z"
      />
    </svg>
  );
}

SystemIcon.propTypes = {
  className: PropTypes.string,
};

SystemIcon.defaultProps = {
  className: "",
};
