import { useQuery } from "@tanstack/react-query";

import useLicenseFeaturesForUser from "../useLicenseFeaturesForUser";

const useGetAvailableFeatures = () => {
  const { refetch: getAvailableFeaturesForUser } = useLicenseFeaturesForUser();
  const { data: response, isLoading } = useQuery({
    queryKey: ["getAvailableFeaturesForUser"],
    queryFn: getAvailableFeaturesForUser,
    staleTime: 1000 * 60 * 10, // data is fresh for 10 mins
    cacheTime: 1000 * 60 * 10, // remove from cache after 10 mins
  });
  const data = response?.data || response;
  return { data, isLoading };
};

export default useGetAvailableFeatures;
