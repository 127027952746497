import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useCreateSchedule() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: (data, scheduleType = "Default") => {
      return axiosPrivate({
        method: "POST",
        url: `Device/AdvSchedule/${scheduleType}/Program/AddForMultipleDevices`,
        data,
      });
    },
  });
}

export default useCreateSchedule;
