import { useMutation } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useUserDelete() {
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationFn: (userName) => {
      return axiosPrivate({
        method: "DELETE",
        url: "User",
        params: { userName },
      });
    },
  });
}

export default useUserDelete;
