import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import PropTypes from "prop-types";

import { HubIcon } from "../../../../../assets/icons/systems";
import Loader from "../../../../Loader/Loader";

const propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  overviewStatus: PropTypes.object,
  overviewStatusesIsLoading: PropTypes.bool,
};

const defaultProps = {
  className: "",
  overviewStatus: {},
  overviewStatusesIsLoading: false,
};

function FloorManagementPropertyDetails({
  className,
  property,
  overviewStatus,
  overviewStatusesIsLoading,
}) {
  return (
    <div className={`property-details ${className}`}>
      <h3 className="property-details-title">{property?.Name}</h3>
      <div className="property-details-address text-15 text-secondary-white">
        {property?.Address}
      </div>
      <div className="property-details-stats">
        <div className="property-details-floors">
          <span className="font-light">
            <span className="inline-block text-lg xl:text-2xl mx-1 xl:mx-2">
              {property?.FloorsCount}
            </span>
            <span className="hidden xxl:inline-block">Floors</span>
          </span>
        </div>

        <Divider type="vertical" className="xl:mx-4" />

        <div className="property-details-systems">
          <span className="font-light">
            <div className="inline-flex">
              <HubIcon className="align-sub" />
              <span className="text-lg xl:text-2xl mx-1 xl:mx-2">
                {property?.SystemsCount}
              </span>
            </div>
          </span>
        </div>

        <Divider type="vertical" className="xl:mx-4" />

        <div className="property-details-alerts">
          {overviewStatusesIsLoading && <Loader className="justify-center" />}
          {overviewStatus && overviewStatus.SystemStatuses && (
            <>
              <ExclamationCircleFilled className="text-triple-red text-md xl:text-xl" />
              <span className="font-light">
                <span className="inline-block text-lg xl:text-2xl mx-1 xl:mx-2">
                  {overviewStatus?.SystemStatuses?.length}
                </span>
                <span className="hidden xxl:inline-block">Alerts</span>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

FloorManagementPropertyDetails.propTypes = propTypes;
FloorManagementPropertyDetails.defaultProps = defaultProps;

export default FloorManagementPropertyDetails;
