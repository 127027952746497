import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useUserForManagement = (
  userId,
  options,
  key = "getUserForManagement",
) => {
  const axiosPrivate = useAxiosPrivate();
  const getUserForManagement = async () => {
    const user = await axiosPrivate({
      method: "GET",
      url: `Users/Management/GetUser/${userId}`,
    }).then((response) => response.data);
    return user;
  };

  return useQuery([key], () => getUserForManagement(userId), {
    ...options,
  });
};

export default useUserForManagement;
