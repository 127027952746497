import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useRecentStatuses(types, options) {
  const axiosPrivate = useAxiosPrivate();

  const getRecentStatuses = async () => {
    const recentStatuses = await axiosPrivate({
      method: "GET",
      url: types
        ? `/Status/SystemsRecentStatus2${types}`
        : "/Status/SystemsRecentStatus2",
    }).then((res) => res.data);
    return recentStatuses;
  };

  return useQuery(
    ["getRecentStatuses", types],
    () => getRecentStatuses(types),
    {
      ...options,
    },
  );
}

export default useRecentStatuses;
