import { useState } from "react";

import { FrownOutlined, PlusOutlined } from "@ant-design/icons";
import { Skeleton, Pagination, Button, Modal, Card } from "antd";
import { useSearchParams } from "react-router-dom";

import usePropertiesCount from "../../../../api/hooks/usePropertiesCount";
import usePropertyBasics from "../../../../api/hooks/usePropertyBasics";
import useWindowSize from "../../../../hooks/useWindowSize";
import BackButton from "../../../Shared/BackButton";
import UnsavedChangesWarning from "../../../Shared/UnsavedChangesWarning";
import CreateNewProperty from "./CreateNewProperty";
import PropertyManagementCard from "./PropertyManagementCard";
import "./scss/PropertyManagement.scss";

function PropertyManagement() {
  const window = useWindowSize();
  const pageSize = 20;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateNewPropertyOpen, setIsCreateNewPropertyOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [createFormRef, setCreateFormRef] = useState();

  // get search params from URl and format them as expected from the API
  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;
  const { data: totalPropertiesCount } = usePropertiesCount();

  const {
    data: properties,
    status: loadingStatus,
    refetch: refetchPropertyBasics,
  } = usePropertyBasics({
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
  });

  const refetch = () => {
    refetchPropertyBasics({
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    });
  };

  const onClickCreateNewProperty = () => {
    setIsCreateNewPropertyOpen(true);
  };

  const onCancelButtonClick = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsCreateNewPropertyOpen(false);
      createFormRef.resetFields();
      refetch();
    }
  };

  return (
    <div className="property-list content-wrapper overflow-hidden">
      <BackButton
        redirect={{ to: "/preferences/property-management" }}
        confirmation={false}
        className="self-start uppercase tracking-widest mr-4"
      />
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0 !text-triple-blue">property</h1>
          )}
        </div>
        <Button
          className="create-new-property"
          type=""
          onClick={onClickCreateNewProperty}
        >
          <span className="tracking-widest uppercase">New Property</span>
          <PlusOutlined style={{ color: "white" }} />
        </Button>
      </div>
      <Modal
        className="create-new-property-modal"
        maskClosable={false}
        footer={null}
        open={isCreateNewPropertyOpen}
        onCancel={onCancelButtonClick}
        keyboard={false}
        destroyOnClose
        width={650}
        bodyStyle={{ height: 650, overflowY: "auto", marginTop: "1.5rem" }}
      >
        <CreateNewProperty
          setCreateFormRef={setCreateFormRef}
          setIsFormDirty={setIsFormDirty}
          setIsCreateNewPropertyOpen={setIsCreateNewPropertyOpen}
          refetchPropertyBasics={refetch}
        />
      </Modal>
      <UnsavedChangesWarning
        onSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          createFormRef.submit();
        }}
        onDontSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          setIsCreateNewPropertyOpen(false);
          setIsFormDirty(false);
          createFormRef.resetFields();
          refetchPropertyBasics();
        }}
        onCancelClick={() => {
          setAlertUnsavedChangesOpen(false);
        }}
        isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
      />
      <Card className="triple-plus-content rounded-2xl p-0">
        {loadingStatus === "error" && (
          <div className="p-6 text-white text-opacity-80 text-center">
            <FrownOutlined className="text-xl mr-2" />
            Something went wrong
          </div>
        )}

        {loadingStatus === "loading" &&
          Array.from({ length: pageSize }, (value, index) => index).map(
            (el) => (
              <Skeleton
                active
                paragraph={{
                  rows: 3,
                }}
                key={el}
              />
            ),
          )}

        {loadingStatus === "success" && properties?.length === 0 && (
          <div className="p-6 text-white text-opacity-80">
            <FrownOutlined className="text-xl mr-2" />
            We couldn`t find any matches
          </div>
        )}

        {loadingStatus === "success" && properties?.length > 0 && (
          <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
            {properties
              ?.sort(
                (firstElement, secondElement) =>
                  firstElement.Id - secondElement.Id,
              )
              .filter((p) => p.Name !== "Default Property")
              .map((property) => (
                <div className="sm:my-2 my-4" key={property.Id}>
                  <PropertyManagementCard
                    key={`${property.Id}_${property.Name}`}
                    property={property}
                    refetchPropertyBasics={refetch}
                  />
                </div>
              ))}
          </div>
        )}

        {loadingStatus === "success" &&
          properties?.length > 0 &&
          totalPropertiesCount > pageSize && (
            <Pagination
              defaultCurrent={1}
              pageSize={pageSize}
              total={totalPropertiesCount}
              current={Number(searchParams.get("page")) || 1}
              showSizeChanger={false}
              size={window?.width <= 576 && "small"}
              onChange={(page) => {
                setSearchParams({ page });
              }}
            />
          )}
      </Card>
    </div>
  );
}

export default PropertyManagement;
